import {
  Box,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table as RNTable,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Paginator, PageGroup } from "chakra-paginator";
import { Fragment, ReactNode } from "react";

import { PaginatedResponseMeta } from "../hooks/useInvoices";

import { Icon } from "./elements/Icon";

interface ITable<T> extends TableProps {
  borderRadius?: number;
  columns: {
    label?: string;
    key: string;
    icon?: IconProp;
    align?: "left" | "right" | "center";
    renderColumn: (data: T) => ReactNode;
  }[];
  data?: T[];
  meta?: PaginatedResponseMeta;
  keyExtractor: (row: T) => any;
  onPageChange: (page: number) => void;
}

export function Table<T>({
  meta,
  data,
  columns,
  keyExtractor,
  onPageChange,
  borderRadius = 8,
  ...props
}: ITable<T>) {
  return (
    <>
      <RNTable variant="simple" {...props}>
        <Thead>
          <Tr>
            {columns.map(({ icon, key, align = "left", label }) => (
              <Th key={key} align={align} textAlign={align}>
                <>
                  {icon ? <Icon icon={icon} /> : null} {label ?? null}
                </>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data
            ? data.map((row) => (
                <Fragment key={keyExtractor(row)}>
                  <Tr>
                    {columns.map(({ align, renderColumn }, index) => (
                      <Td
                        py={3}
                        textAlign={align}
                        borderLeftRadius={index === 0 ? borderRadius : 0}
                        borderRightRadius={
                          index === columns.length - 1 ? borderRadius : 0
                        }
                        bg="white"
                      >
                        {renderColumn(row)}
                      </Td>
                    ))}
                  </Tr>
                  <Tr>
                    <Box pb={1} />
                  </Tr>
                </Fragment>
              ))
            : null}
        </Tbody>
      </RNTable>
      {meta ? (
        <Paginator
          pagesQuantity={meta?.totalPages}
          currentPage={meta?.currentPage}
          onPageChange={onPageChange}
          outerLimit={1}
          innerLimit={2}
          normalStyles={{
            bg: "gray.200",
            width: 10,
            borderRadius: 100,
          }}
          activeStyles={{
            colorScheme: "brand",
            width: 10,
            borderRadius: 100,
          }}
        >
          <PageGroup justifyContent="flex-end" mt={4} />
        </Paginator>
      ) : null}
    </>
  );
}
