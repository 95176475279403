import { useQuery } from "react-query";

import { ServiceType } from "../enums/service-type";
import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

type IOrganisationServices = Partial<Record<ServiceType, boolean>>;

interface IOrganisationServicesResponse {
  type: ServiceType;
}

export function useOrganisationServices() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<IOrganisationServices>(
    ["organisation", currentOrganisation, "services"],
    async () => {
      const response = await get<IOrganisationServicesResponse[]>(
        `services/${currentOrganisation?.id}/service`,
        {}
      );
      if (response.status !== 200)
        return Promise.reject("Could not fetch services");

      const services: IOrganisationServices = {};
      response.data.forEach((service) => {
        services[service.type] = true;
      });

      return services;
    },
    {
      enabled: !!currentOrganisation,
      keepPreviousData: true,
    }
  );
}
