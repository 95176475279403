import { useMutation } from "react-query";

import { post } from "../services/api";

type IResendEmailRequest = { email: string };

export function useResendEmail() {
  return useMutation<unknown, unknown, IResendEmailRequest>(
    "auth/resend-email",
    async (data: IResendEmailRequest) => {
      const response = await post<unknown>("users/resend-email", data);
      if (response.status !== 201) {
        return Promise.reject("Could not resend email");
      }

      return response?.data;
    }
  );
}
