import { FormControl, TagLabel, InputGroup, Tag } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { IElementProps } from "react-templator";

import { Input } from "../elements/Input";

export function FileUploadElement({
  value,
  name,
  error,
  icon,
  hasFile,
  label,
  helper,
  onChange,
  required,
}: IElementProps) {
  const inputRef: any = useRef();

  const [fileLabel, setFileLabel] = useState<string>("");

  function handleFileSelect(event: ChangeEvent<HTMLInputElement>) {
    if (!event?.target?.files) return;
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      const fileName = event.target?.value?.split("\\").pop();
      if (fileName) setFileLabel(fileName);
      onChange(fileReader.result);
    });
    if (!event.target.files[0]) return;
    fileReader.readAsDataURL(event.target.files[0]);
  }

  return (
    <FormControl isInvalid={!!error} isRequired={true}>
      <InputGroup>
        <input
          type="file"
          accept={"image/*"}
          name={name}
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(value) => {
            handleFileSelect(value);
          }}
        />
        <Input
          name={name}
          label={label}
          error={error}
          cursor="pointer"
          helperText={helper}
          onKeyDown={(e: any) => e && e.preventDefault()}
          onFocus={(e: any) => e && e.preventDefault()}
          icon={icon}
          required={required}
          onClick={() => inputRef.current.click()}
          value={fileLabel}
          inputRightElement={
            <Tag
              size="sm"
              variant="subtle"
              colorScheme={hasFile || value ? "green" : "gray"}
            >
              <TagLabel>
                {hasFile || value ? "File uploaded" : "Upload an image..."}
              </TagLabel>
            </Tag>
          }
        />
      </InputGroup>
    </FormControl>
  );
}
