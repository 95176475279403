import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  Box,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { Form } from "react-templator";

import { ORGANISATION_INVITE_FORM } from "../../forms/auth/organisation-invite-form";
import { useJoinOrganisation } from "../../hooks/useJoinOrganisation";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";
import { Icon } from "../elements/Icon";
import { Text } from "../elements/Text";

interface JoinOrganisationModalProps extends ModalProps {
  defaultCode?: string;
  canGoBack?: boolean;
}
export function JoinOrganisationModal({
  isOpen,
  onClose,
  defaultCode,
  canGoBack = true,
}: JoinOrganisationModalProps) {
  const { mutate: joinOrganisation, isLoading } = useJoinOrganisation();

  async function handleOnSubmit(data: Record<string, string>, setErrors: any) {
    joinOrganisation(data, {
      onSuccess: (result: string | null) => {
        if (result) return setErrors({ code: result });
        onClose();
      },
      onError: (result: string) => {
        setErrors({ code: result });
      },
    });
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogContent>
          <HStack p={6} pb={3}>
            {canGoBack && (
              <IconButton
                size="lg"
                color="black"
                onClick={() => onClose()}
                p={2}
                bg="transparent"
                icon={<Icon icon="arrow-left" />}
                aria-label="Go Back"
              />
            )}
            <CardHeader title="Join an organisation" />
          </HStack>
          <Box mb={5} px={6}>
            <Text>
              Please input your organisation access token. Alternatively, click
              the link in the email.
            </Text>
          </Box>

          <AlertDialogBody pb={6}>
            <Form
              schema={ORGANISATION_INVITE_FORM}
              dynamicProps={{
                submit: {
                  isLoading,
                },
              }}
              defaultValues={{
                code: defaultCode,
              }}
              onSubmit={(data, setErrors) => {
                handleOnSubmit(data, setErrors);
              }}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
