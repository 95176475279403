import { useEffect, useMemo, useState } from "react";

export const useImagePreload = (
  images?: (string | undefined)[] | undefined
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const key = images?.join();
  useEffect(() => {
    (async () => {
      if (images) {
        setIsLoading(true);
        await Promise.all(
          images
            .filter((image) => image)
            .map(
              (rnImage: string | undefined) =>
                new Promise<void>((res, rej) => {
                  if (!rnImage) return res();

                  const image = new Image();
                  image.onload = () => res();
                  image.onerror = () => rej();

                  image.src = rnImage;
                })
            )
        ).catch(() => setIsError(true));

        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return useMemo(
    () => ({
      isError,
      isLoading,
    }),
    [isLoading, isError]
  );
};
