import { Text as RNText, TextProps } from "@chakra-ui/react";

type TextType = "header" | "body" | "error" | "cardHeader";
const typeProps: Record<TextType, TextProps> = {
  header: {
    fontSize: "2xl",
    fontWeight: 800,
  },
  cardHeader: {
    fontSize: 16,
  },
  body: {
    fontSize: 15,
  },
  error: {
    fontSize: "sm",
  },
};

export interface ITextProps extends TextProps {
  type?: TextType;
}

export function Text({ type = "body", ...props }: ITextProps) {
  return <RNText {...(typeProps[type] ?? {})} {...props} />;
}
