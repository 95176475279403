import { Box, Center, Container, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { RegisterForm } from "../auth/RegisterForm";
import { Logo } from "../Logo";

export function RegisterPage() {
  return (
    <>
      <Helmet>
        <title>Sign up - Notiversal</title>
      </Helmet>
      <Box
        backgroundImage="img/rose.svg"
        backgroundSize="cover"
        backgroundAttachment="fixed"
        minH="100vh"
        minW="100vw"
      >
        <Container maxW="container.sm">
          <Center minH="100vh" pt="2rem" pb="2rem ">
            <VStack w="100%">
              <Logo width={250} mb={12} />
              <RegisterForm />
            </VStack>
          </Center>
        </Container>
      </Box>
    </>
  );
}
