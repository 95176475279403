import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

import { useActiveSubscription } from "./useActiveSubscription";
import { useCurrentOrganisation } from "./useCurrentOrganisation";

type ICheckoutRequest = Record<string, string>;

export function useReactivateSubscription() {
  const queryClient = useQueryClient();
  const { data: activeSubscription } = useActiveSubscription();
  const { currentOrganisation } = useCurrentOrganisation();
  return useMutation<boolean, null, ICheckoutRequest>(
    "checkout",
    async () => {
      const response = await post<null>(
        `subscriptions/${currentOrganisation?.id}/reactivate`,
        {}
      );
      return response.status === 200;
    },
    {
      onSuccess: () => {
        queryClient.setQueryData([currentOrganisation?.id, "subscriptions"], {
          ...activeSubscription,
          cancel_at_period_end: false,
        });
      },
    }
  );
}
