import { useDeleteEventNotificationPreference } from "../../hooks/useDeleteEventNotificationPreference";
import { useNotificationPreferences } from "../../hooks/useNotificationPreferences";
import { useSetEventNotificationPreference } from "../../hooks/useSetEventNotificationPreference.ts";
import { useSetProductNotificationPreference } from "../../hooks/useSetProductNotificationPreference";
import { INotifyType } from "../../interfaces/NotifyType";

import { ServicePreferenceCard } from "./ServicePreferenceCard";

interface NewsProps {
  service: INotifyType;
  eventId?: string;
  productId?: string;
  isLoading?: boolean;
}

export function NotifyPreferenceServiceCard({
  service,
  productId,
  isLoading,
  eventId,
}: NewsProps) {
  const { type, label, image } = service;

  const { mutate: setProductNotificationPreference } =
    useSetProductNotificationPreference(productId);

  const { mutate: setEventNotificationPreference } =
    useSetEventNotificationPreference(productId, eventId);

  const { mutate: deleteEventNotificationPreference } =
    useDeleteEventNotificationPreference(productId, eventId);

  const { data: notificationPreferences, isLoading: isPreferencesLoading } =
    useNotificationPreferences({ productId, eventId });

  const isActive: boolean | null =
    notificationPreferences?.find(
      (preference) => preference.preference === type
    )?.isEnabled ?? null;

  function handleToggle() {
    const data = { preference: type, value: !isActive };

    if (eventId) {
      if (isActive === false && data.value) {
        deleteEventNotificationPreference({ preference: type });
        return;
      }

      setEventNotificationPreference(data);
    } else {
      setProductNotificationPreference(data);
    }
  }

  return (
    <ServicePreferenceCard
      isLoading={isLoading}
      isPreferenceLoading={isPreferencesLoading}
      isEvent={!!eventId}
      isActive={isActive}
      label={label}
      image={image}
      handleToggle={handleToggle}
    />
  );
}
