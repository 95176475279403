import { createContext } from "react";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";
import { UserEntity } from "service/src/users/entities/user.entity";

export interface MeContext {
  me?: UserEntity;
  isAuthenticated: boolean | null;
  authorize: () => Promise<void>;
  updatePushNotifications: () => Promise<void>;
  logout: () => void;
}
export const meContext = createContext<MeContext>({
  me: undefined,
  isAuthenticated: false,
  logout: () => {},
  updatePushNotifications: async () => {},
  authorize: async () => {},
});

export interface IAlertData {
  label?: string;
  text?: string;
  confirmText?: string;
  onConfirm?: () => void;
}

export interface AlertContext {
  alert: (data: IAlertData) => void;
  closeAlert: () => void;
  currentData: IAlertData;
  setIsLoading: (loading: boolean) => void;
}

export const alertContext = createContext<AlertContext>({
  alert: () => {},
  closeAlert: () => {},
  currentData: {},
  setIsLoading: () => {},
});

export interface OrganisationContext {
  currentOrganisation: OrganisationEntity | null;
  setCurrentOrganisation: (organisation: OrganisationEntity | null) => void;
}

export const organisationContext = createContext<OrganisationContext>({
  currentOrganisation: null,
  setCurrentOrganisation: () => {},
});
