import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

import { useActiveSubscription } from "./useActiveSubscription";
import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface ICancelSubscriptionRequest {
  instant?: boolean;
}

export function useCancelSubscription() {
  const queryClient = useQueryClient();
  const { data: activeSubscription } = useActiveSubscription();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<boolean, null, ICancelSubscriptionRequest>(
    ["cancel", currentOrganisation?.id],
    async ({ instant }: ICancelSubscriptionRequest) => {
      const response = await post<null>(
        `subscriptions/${currentOrganisation?.id}/cancel`,
        { instant }
      );

      return response.status === 200;
    },
    {
      onSuccess: (_, { instant }) => {
        queryClient.setQueryData(
          [currentOrganisation?.id, "subscriptions"],
          instant
            ? null
            : {
                ...activeSubscription,

                cancel_at_period_end: instant
                  ? true
                  : !activeSubscription?.cancel_at_period_end,
              }
        );

        queryClient.invalidateQueries([
          currentOrganisation?.id,
          "subscriptions",
        ]);
      },
    }
  );
}
