import { HStack, Link, useDisclosure, useToast } from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { ISubscription } from "service/src/subscription/interface/ISubscription";
import { ProductInterface } from "service/src/subscription/interface/ProductInterface";

import { useActiveSubscription } from "../../hooks/useActiveSubscription";
import { useCheckout } from "../../hooks/useCheckout";
import { useReactivateSubscription } from "../../hooks/useReactivateSubscription";
import { useSubscriptionBlocked } from "../../hooks/useSubscriptionBlocked";
import { useSubscriptionTrials } from "../../hooks/useSubscriptionTrials";
import { Button } from "../elements/Button";
import { SubscriptionCancelModal } from "../modals/subscriptions/SubscriptionCancelModal";
import { SubscriptionUpgradeModal } from "../modals/subscriptions/SubscriptionUpgradeModal";

export function SubscriptionButton({
  product,
  activeSubscription,
}: {
  product?: ProductInterface;
  activeSubscription?: ISubscription;
}) {
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onUpgradeModalOpen,
    onClose: onUpgradeModalClose,
  } = useDisclosure();

  const {
    isOpen: isCancelModalOpen,
    onOpen: onCancelModalOpen,
    onClose: onCancelModalClose,
  } = useDisclosure();

  const toast = useToast();
  const stripe = useStripe();
  const { data: isBillingBlocked } = useSubscriptionBlocked();
  const { isLoading: isActiveLoading } = useActiveSubscription();
  const { mutate: checkout, isLoading: isCheckoutLoading } = useCheckout();
  const { data: subscriptionTrials, isLoading: isTrialLoading } =
    useSubscriptionTrials();
  const { mutate: reactivate, isLoading: isReactivateLoading } =
    useReactivateSubscription();

  function getButtonText(): string {
    if (isActive && cancelAtPeriodEnd) return "Reactivate";
    if (isActive) return "Active now";
    if (trialAvailable) return "Start free trial";
    if (!isActive && activeSubscription && !activeSubscription.isFree)
      return "Upgrade";
    return "Start plan";
  }

  function handleCheckout() {
    if (!product) return;
    if (isActive && cancelAtPeriodEnd) return reactivate({});
    if (isActive && !cancelAtPeriodEnd) return;

    checkout(
      { product: product.name },
      {
        onSuccess: (data) => {
          stripe?.redirectToCheckout({
            sessionId: data.session,
          });
        },
        onError: () => {
          toast({
            title: "Could not create checkout session",
            status: "error",
            isClosable: true,
          });
        },
      }
    );
  }

  async function handleUpgrade() {
    onUpgradeModalOpen();
  }

  async function handleCancel() {
    onCancelModalOpen();
  }

  const isActive = activeSubscription?.product?.name === product?.name;
  const cancelAtPeriodEnd = activeSubscription?.cancel_at_period_end;
  const trialAvailable =
    product && subscriptionTrials && subscriptionTrials[product?.name];

  const isLoading =
    isCheckoutLoading ||
    isTrialLoading ||
    isReactivateLoading ||
    isActiveLoading;

  if (!product) return null;

  return (
    <>
      <SubscriptionUpgradeModal
        product={product}
        isOpen={isUpgradeModalOpen}
        onOpen={onUpgradeModalOpen}
        onClose={onUpgradeModalClose}
      />
      <SubscriptionCancelModal
        cancelAtPeriodEnd={cancelAtPeriodEnd}
        isOpen={isCancelModalOpen}
        onOpen={onCancelModalOpen}
        onClose={onCancelModalClose}
      />
      {(!isBillingBlocked && !isActive) ||
      cancelAtPeriodEnd ||
      (!isBillingBlocked && !isLoading && product.price && isActive) ? (
        <HStack spacing={5}>
          {(!isBillingBlocked && !isActive) || cancelAtPeriodEnd ? (
            <Button
              isLoading={isLoading}
              colorScheme={trialAvailable ? "green" : undefined}
              onClick={() =>
                !isActive && activeSubscription && !activeSubscription.isFree
                  ? handleUpgrade()
                  : handleCheckout()
              }
              icon="cart-plus"
            >
              {getButtonText()}
            </Button>
          ) : null}
          {!isBillingBlocked && !isLoading && product.price && isActive ? (
            <Link onClick={handleCancel} color="red.400" fontSize="sm">
              {cancelAtPeriodEnd ? "Delete" : "Cancel subscription"}
            </Link>
          ) : null}
        </HStack>
      ) : null}
    </>
  );
}
