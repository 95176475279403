export const TWILIO_FORM = [
  {
    type: "text-input",
    name: "accountSid",
    label: "Account SID",
    minLength: 4,
    icon: "user",
    required: true,
    maxLength: 80,
  },
  {
    type: "text-input",
    name: "authToken",
    label: "Auth token",
    minLength: 4,
    required: true,
    icon: "lock",
    maxLength: 80,
  },

  {
    type: "text-input",
    name: "phoneNumber",
    label: "Twilio phone number",
    minLength: 4,
    required: true,
    icon: "phone",
    maxLength: 16,
  },
  {
    type: "h-stack",
    mt: 5,
    mb: -5,
    children: [
      {
        type: "checkbox",
        name: "enableSms",
        label: "Enable SMS",
        inputType: "text",
      },
      {
        type: "checkbox",
        name: "enableVoice",
        label: "Enable voice",
        inputType: "text",
      },
    ],
  },
  {
    type: "submit",
    label: "Submit",
    name: "submit",
  },
];
