import { useMutation, useQueryClient } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { remove } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IOrganisationUserRemove {
  id: string;
}

export function useOrganisationRemoveInvite() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<null, null, IOrganisationUserRemove>(
    "organisation/user/remove/pending",
    async (data) => {
      const response = await remove<OrganisationEntity>(
        `organisations/${currentOrganisation?.id}/invite/${data.id}`
      );

      if (response.status !== 200)
        return Promise.reject("Could not update user");

      return null;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          currentOrganisation?.id,
          "subscriptions",
        ]);
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation?.id,
          "users",
        ]);
      },
    }
  );
}
