import {
  Box,
  Text,
  BoxProps,
  HStack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import ReactMarkdown from "react-markdown";

import { Avatar } from "../elements/Avatar";
import { Card } from "../elements/card/Card";
import { Link } from "../elements/Link";
import { LoadingBackground } from "../elements/LoadingBackground";
import { IOrganisationEvent } from "../pages/useOrganisationEvents";

interface INotificationCardProps extends BoxProps {
  isLoading?: boolean;
  notification?: IOrganisationEvent;
}

export function NotificationCard({
  notification,
  isLoading: isNotificationLoading,
  ...props
}: INotificationCardProps) {
  const isLoading = isNotificationLoading;

  const avatarId = notification?.product
    ? notification?.product?.id
    : notification?.event?.organisation?.id ?? "";

  const isInternal = !(notification?.product || notification?.organisation);

  return (
    <VStack spacing={0} w="100%">
      <Card
        w="100%"
        p={0}
        transition="200ms all"
        borderRadius={5}
        isLoading={isLoading}
        {...props}
      >
        <HStack spacing={6} justifyContent="space-between" p={5}>
          <HStack spacing={2} w="100%">
            <Avatar
              id={avatarId}
              isLoading={isLoading}
              isInternal={isInternal}
              image={
                notification?.product?.image ??
                notification?.organisation?.avatar
              }
              variant="marble"
              size={[10, 20]}
              w={20}
            />
            <VStack
              textAlign="left"
              pl={4}
              display="flex"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <HStack spacing={3} w="100%">
                <LoadingBackground isLoading={isLoading}>
                  <Text
                    color={useColorModeValue("brand.200", "brand.100")}
                    fontSize="md"
                    fontWeight={600}
                  >
                    {notification?.event?.title}
                  </Text>
                </LoadingBackground>
                {!isLoading && (
                  <Text color="gray.500" fontSize={12} fontWeight={400}>
                    {dayjs(notification?.createdAt).format("DD/MM/YYYY HH:MM")}
                  </Text>
                )}
              </HStack>
              <LoadingBackground isLoading={isLoading}>
                <Text mt="0 !important" color="gray.500" fontSize={15}>
                  {notification?.event.subtitle}
                </Text>
              </LoadingBackground>
            </VStack>
          </HStack>
        </HStack>
        {!isEmpty(notification?.event.actions) && (
          <Box
            w="100%"
            p={2}
            bg="gray.50"
            boxShadow="sm"
            borderTop="1px solid rgb(0 0 0 / 0.08)"
          >
            <HStack alignItems="flex-end" spacing={2} w="100%">
              {notification?.event.actions.map(({ label, link }) => (
                <Box p={1.5} borderRadius={2}>
                  <Link
                    fontSize={14}
                    href={!isInternal ? link : undefined}
                    to={isInternal ? link : undefined}
                    p={1}
                    target={!isInternal ? "_blank" : undefined}
                    rel={!isInternal ? "noopener" : undefined}
                    color="brand.200"
                    fontWeight={500}
                  >
                    {label}
                  </Link>
                </Box>
              ))}
            </HStack>
          </Box>
        )}

        {notification?.event.body ? (
          <Box p={5} borderTop="1px solid rgb(0 0 0 / 0.08)">
            <Text mt="0 !important" color="gray.500" fontSize={15}>
              <ReactMarkdown>
                {notification?.event.body.replace(/\n/gi, "&nbsp;&nbsp;\n\n")}
              </ReactMarkdown>
            </Text>
          </Box>
        ) : null}
      </Card>
    </VStack>
  );
}
