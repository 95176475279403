import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  Box,
  VStack,
  Wrap,
  WrapItem,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";
import { ProductEvent } from "service/src/notifications/interface/ProductEvent";
import { ProductEntity } from "service/src/products/entities/product.entity";
import { Role } from "service/src/users/role.enum";

import { NOTIFY_TYPES } from "../../constants/notify-types";
import { useAlert } from "../../hooks/useAlert";
import { useEventRemove } from "../../hooks/useEventRemove";
import { ModalProps } from "../../interfaces/ModalProps";
import { AuthGuard } from "../AuthGuard";
import { CardHeader } from "../elements/card/CardHeader";
import { Link } from "../elements/Link";
import { Text } from "../elements/Text";
import { NotifyPreferenceServiceCard } from "../services/NotifyPreferenceServiceCard";

interface ProductSetupModalProps extends ModalProps {
  event?: Partial<ProductEvent>;
  product?: ProductEntity;
}

export function EventPreferencesModal({
  isOpen,
  event,
  product,
  onClose,
}: ProductSetupModalProps) {
  const { setIsLoading, alert, closeAlert } = useAlert();
  const { mutate: removeEvent } = useEventRemove(product?.id);

  function handleRemove() {
    alert({
      label: "Confirm removal",
      text: `Are you sure you wish to delete the ${event?.title} event from this product??`,
      confirmText: "Delete",
      onConfirm: () => {
        setIsLoading(true);
        removeEvent(
          { identifier: event?.event },
          {
            onSuccess: () => {
              closeAlert();
              onClose();
            },
            onSettled: () => setIsLoading(false),
          }
        );
      },
    });
  }
  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Box p={6}>
            <VStack spacing={5}>
              <VStack spacing={2}>
                <CardHeader title={`Event preferences for "${event?.title}"`} />
                <Box>
                  <Text>
                    Modify the way you get notified for this event. If the event
                    does not have a state, it will get your preference from the
                    default notification settings for the product.
                  </Text>
                </Box>
              </VStack>
              <VStack spacing={5} pt={2} w="100%" alignItems="flex-start">
                <VStack spacing={2} alignItems="flex-start" w="100%">
                  <Wrap spacing="4">
                    <SimpleGrid columns={[1, 2]} spacing="5px">
                      {NOTIFY_TYPES.map((type) => (
                        <WrapItem>
                          <NotifyPreferenceServiceCard
                            service={type}
                            eventId={event?.id}
                            productId={product?.id}
                          />
                        </WrapItem>
                      ))}
                    </SimpleGrid>
                  </Wrap>
                </VStack>
                <AuthGuard role={Role.Admin}>
                  <HStack alignItems="center" justifyContent="center" w="100%">
                    <Link
                      color="red.400"
                      fontSize={14}
                      onClick={() => handleRemove()}
                    >
                      Remove event
                    </Link>
                  </HStack>
                </AuthGuard>
              </VStack>
            </VStack>
          </Box>
          <AlertDialogCloseButton />
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
