import axios from "axios";

export const BASE_URL = process ? process.env.REACT_APP_API_URL : "";

export function get<T>(api: string, data: { [x: string]: any }) {
  return axios.get<T>(`${BASE_URL}${api}`, {
    params: data,
    withCredentials: true,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
}

export function put<T>(
  api: string,
  data: Record<string, any>,
  headers?: Record<string, string>
) {
  return axios.put<T>(`${BASE_URL}${api}`, data, {
    withCredentials: true,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ...headers,
    },
  });
}

export function remove<T>(api: string, headers?: Record<string, string>) {
  return axios.delete<T>(`${BASE_URL}${api}`, {
    withCredentials: true,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ...headers,
    },
  });
}

export function post<T>(
  api: string,
  data: Record<string, any>,
  headers?: Record<string, string>
) {
  return axios.post<T>(`${BASE_URL}${api}`, data, {
    withCredentials: true,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ...headers,
    },
  });
}
