import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import { ISidebarSection } from "../../interfaces/ISidebarSection";

import { SidebarContent } from "./SidebarContent";

interface ISidebar {
  isOpen: boolean;
  onClose: () => void;
  header?: ReactNode;
  items: ISidebarSection[];
  variant: "drawer" | "sidebar";
}

export const SIDEBAR_WIDTH = "340px";
export function Sidebar({ isOpen, header, variant, items, onClose }: ISidebar) {
  return variant === "sidebar" ? (
    <Box
      top={0}
      left={0}
      h="100%"
      w={SIDEBAR_WIDTH}
      bg="white"
      mt="65px"
      position="fixed"
      overflowY="auto"
      maxHeight="100vh"
    >
      <SidebarContent header={header} items={items} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody p={0}>
            <SidebarContent header={header} items={items} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
