import { Box, Container, HStack } from "@chakra-ui/layout";

import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useCurrentOrganisation } from "../hooks/useCurrentOrganisation";
import { useSubscriptionBlocked } from "../hooks/useSubscriptionBlocked";

import { Button } from "./elements/Button";
import { Link } from "./elements/Link";

export function SubscriptionProcessingBanner() {
  const { data: isBillingBlocked } = useSubscriptionBlocked();

  if (!isBillingBlocked) return null;
  return (
    <Box w="100%" backgroundColor="green.500" p={3.5}>
      <Container maxW="container.xl">
        <HStack justifyContent="space-between" alignItems="center">
          <Box>
            Please allow a few seconds for your subscription to process...
          </Box>
          <Link
            isLink={false}
            to="/organisation#subscriptions"
            textDecoration="none !important"
          >
            <Button
              layerStyle="white"
              _hover={{ opacity: 0.8 }}
              _active={{ opacity: 0.8 }}
              colorScheme="white"
              // backgroundColor="red.500"
              // color="white"
            >
              View billing
            </Button>
          </Link>
        </HStack>
      </Container>
    </Box>
  );
}
