import { HStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Form } from "react-templator";

import { LOGIN_FORM } from "../../forms/auth/login-form";
import { useLogin } from "../../hooks/useLogin";
import { Card } from "../elements/card/Card";
import { CardHeader } from "../elements/card/CardHeader";
import { Link } from "../elements/Link";
import { Text } from "../elements/Text";

import { ResendEmail } from "./ResendEmail";

export function LoginForm() {
  const { push } = useHistory();
  const { mutate: login, isLoading } = useLogin();

  async function handleSubmit(data: Record<string, string>, setErrors: any) {
    login(
      { ...data },
      {
        onSuccess: () => {
          const inviteCode = localStorage.getItem("inviteCode");
          if (inviteCode) return push(`/join/${inviteCode}`);
          return push("/");
        },
        onError: (errors) => {
          if (errors.accountNotActive) {
            setErrors({
              password: (
                <HStack>
                  <Text>Please click the activation link in your email. </Text>
                  <ResendEmail email={data?.email} />
                </HStack>
              ),
            });
            return;
          }

          return setErrors(errors);
        },
      }
    );
  }

  return (
    <>
      <Card pb={80} p={{ base: 8, md: 12 }} py={{ base: 10, md: 12 }}>
        <CardHeader title="Login" />
        <Text type="body" mb={8} mt={2}>
          Universal notifications for internal teams.
        </Text>

        <Form
          schema={LOGIN_FORM}
          dynamicProps={{
            submit: {
              isLoading,
            },
          }}
          onSubmit={handleSubmit}
        />
      </Card>
      <HStack justifyContent="center">
        <Text color="white" mt={5}>
          Don't have an account?{" "}
          <Link color="white" fontWeight="bold" to="/register">
            Sign up
          </Link>
        </Text>
      </HStack>
    </>
  );
}
