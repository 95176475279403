import {
  BoxProps,
  HStack,
  IconButton,
  useColorModeValue,
  VStack,
  Box,
  Text,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";

import { AuthGuard } from "../AuthGuard";
import { Button } from "../elements/Button";
import { Icon } from "../elements/Icon";
import { Link } from "../elements/Link";
import { Role } from "../users/UserCard";

interface IPageHeaderButton {
  label: string;
  icon: IconProp;
  level?: Role;
  tooltip?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

interface IPageHeaderProps extends BoxProps {
  label?: string;
  isSubheader?: boolean;
  backUrl?: string;
  isLoading?: boolean;
  subtitle?: string;
  buttons?: IPageHeaderButton[];
}

export function PageHeader({
  backUrl,
  isLoading,
  subtitle,
  isSubheader,
  label,
  buttons,
  ...props
}: IPageHeaderProps) {
  const pageButtons = (
    <>
      <HStack spacing={2}>
        {!isLoading &&
          !isEmpty(buttons) &&
          buttons?.map(
            ({ label, level, icon, onClick, tooltip, isDisabled }) => (
              <AuthGuard role={level} key={label}>
                <Button
                  key={label}
                  icon={icon}
                  tooltip={tooltip}
                  onClick={onClick}
                  disabled={isDisabled}
                >
                  {label}
                </Button>
              </AuthGuard>
            )
          )}
      </HStack>
    </>
  );
  return (
    <>
      <Helmet>
        <title>{label ? `${label} - ` : ""}Notiversal</title>
      </Helmet>
      <VStack my={isSubheader ? 8 : 12} w="100%" {...props}>
        <HStack w="100%" justifyContent="space-between">
          <HStack alignItems="flex-start">
            {backUrl ? (
              <Link to={backUrl}>
                <IconButton
                  size="md"
                  borderRadius="full"
                  ml={3}
                  bg="transparent"
                  mr={5}
                  color="gray.700"
                  icon={<Icon icon="arrow-left" />}
                  aria-label="Open Menu"
                />
              </Link>
            ) : null}
            <VStack spacing={1} w="100%" alignItems="flex-start">
              <Text
                fontWeight={600}
                fontSize={isSubheader ? 22 : 26}
                color={useColorModeValue("gray.700", "gray.200")}
              >
                {label}
              </Text>
              <Text fontSize={isSubheader ? 18 : 20} opacity={0.8}>
                {subtitle}
              </Text>
            </VStack>
          </HStack>
          <Box display={["none", "block"]}>{pageButtons}</Box>
        </HStack>
        <Box display={["block", "none "]}>{pageButtons}</Box>
      </VStack>
    </>
  );
}
