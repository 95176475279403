import {
  Box,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { OrganisationUserResponse } from "service/src/organisations/response/organisation-user.response";

import { useAlert } from "../../hooks/useAlert";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useLimits } from "../../hooks/useLimits";
import { useMe } from "../../hooks/useMe";
import { useOrganisationLeave } from "../../hooks/useOrganisationLeave";
import { AuthGuard } from "../AuthGuard";
import { Avatar } from "../elements/Avatar";
import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Tag, Tags } from "../elements/Tags";
import { Text } from "../elements/Text";
import { InviteUserModal } from "../modals/InviteUserModal";
import { ModifyUserModal } from "../modals/ModifyUserModal";

export enum Role {
  User = "User",
  Developer = "Developer",
  Admin = "Admin",
}

interface IUserCardProps {
  item?: Partial<OrganisationUserResponse>;
  isLoading?: boolean;
}

export function AddUserCard({ isLoading }: IUserCardProps) {
  const { canAddTeamMember } = useLimits();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <InviteUserModal
        isOpen={isModalOpen}
        onOpen={() => {}}
        onClose={() => setIsModalOpen(false)}
      />
      <Card display={isLoading ? "none" : undefined} w="100%">
        <VStack
          justifyContent="center"
          alignItems="center"
          h="100%"
          w="100%"
          spacing={10}
        >
          <Icon
            icon={["far", "user"]}
            fontSize={120}
            color={useColorModeValue("gray.200", "gray.700")}
          />
          <VStack spacing={2}>
            <Text fontSize={18} fontWeight={500}>
              Add a team member
            </Text>
            <Text
              fontSize={15}
              textAlign="center"
              color={useColorModeValue("gray.500", "gray.400")}
            >
              Work colaborately with your team members by inviting them to your
              organisation
            </Text>
          </VStack>
          <Button
            icon="plus"
            tooltip={
              !canAddTeamMember
                ? "Your current plan does not allow for more team members to be added"
                : undefined
            }
            onClick={() => setIsModalOpen(true)}
            disabled={!canAddTeamMember}
          >
            Invite user
          </Button>
        </VStack>
      </Card>
    </>
  );
}
