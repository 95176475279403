import { useMutation } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { get } from "../services/api";

export function useOrganisationValidInvite() {
  return useMutation<
    { valid: boolean; organisation?: OrganisationEntity },
    null,
    string
  >("organisations/valid-invite", async (code) => {
    const response = await get<{
      valid: boolean;
      organisation?: OrganisationEntity;
    }>(`organisations/valid-invite/${code}`, {});
    return {
      valid: !!response.data.valid,
      organisation: response.data.organisation,
    };
  });
}
