import { Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";

import { useResendEmail } from "../../hooks/useResendEmail";
import { Link } from "../elements/Link";

interface IResendEmailProps {
  email: string;
}

export const ResendEmail = ({ email }: IResendEmailProps) => {
  const { mutate: resendEmail, isError, isLoading } = useResendEmail();
  const [isResent, setIsResent] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    setIsResent(true);
    await resendEmail({ email });
  }, [resendEmail, email]);

  if (!isResent) {
    return (
      <Link
        fontSize={15}
        onClick={() => handleClick()}
        textDecoration="underline"
      >
        Resend email
      </Link>
    );
  }

  if (isLoading)
    return (
      <Text fontSize={15} color="gray.700">
        Loading...
      </Text>
    );

  return (
    <Text fontSize={15} color={isError ? "red.500" : "green.500"}>
      {isError ? "Error!" : "Resent!"}
    </Text>
  );
};
