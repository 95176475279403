import { VStack, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { NOTIFY_TYPES } from "../../../constants/notify-types";
import { useOrganisationProduct } from "../../../hooks/useOrganisationProduct";
import { useOrganisationProductEvents } from "../../../hooks/useOrganisationProductEvents";
import { Grid } from "../../elements/Grid";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder";
import { SectionLabel } from "../../elements/SectionLabel";
import { ProductSetupModal } from "../../modals/ProductSetupModal";
import { EventAddCard } from "../../product/EventAddCard";
import { EventCard } from "../../product/EventCard";
import { NotifyPreferenceServiceCard } from "../../services/NotifyPreferenceServiceCard";

export function EventsPage() {
  const { productId } = useParams<{ productId: string }>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: product, isLoading: isProductLoading } =
    useOrganisationProduct(productId);
  const { data: events, isLoading: isEventsLoading } =
    useOrganisationProductEvents(productId);

  const isLoading = isProductLoading && isEventsLoading;

  return (
    <>
      <ProductSetupModal
        product={product}
        isOpen={isModalOpen}
        onOpen={() => {}}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
      <VStack spacing={10} w="100%">
        <VStack spacing={4} w="100%">
          <SectionLabel>Default event preferences</SectionLabel>
          <SimpleGrid columns={[1, 2, 4]} spacing="10px" w="100%">
            {NOTIFY_TYPES.map((type) => (
              <NotifyPreferenceServiceCard
                service={type}
                isLoading={isLoading}
                productId={productId}
              />
            ))}
          </SimpleGrid>
        </VStack>
        <VStack spacing={4} alignitems="flex-start" w="100%">
          <SectionLabel>Events</SectionLabel>

          <Grid columns={[1, 2, 2]} spacing="20px" w="100%">
            <LoadingPlaceholder length={2} isLoading={isEventsLoading}>
              <EventCard isLoading={true} />
            </LoadingPlaceholder>

            {events
              ? events?.map((event) => (
                  <EventCard product={product} item={event} />
                ))
              : null}

            {!isEventsLoading && (
              <EventAddCard onClick={() => setIsModalOpen(true)} />
            )}
          </Grid>
        </VStack>
      </VStack>
    </>
  );
}
