import { INotifyType } from "../interfaces/NotifyType";

export const NOTIFY_TYPES: INotifyType[] = [
  {
    type: "sms",
    label: "SMS",
    shortLabel: "SMS",
    image: "/img/notification-types/sms.svg",
  },
  {
    type: "voice",
    shortLabel: "Call",
    label: "Voice call",
    image: "/img/notification-types/phone.svg",
  },
  {
    type: "email",
    label: "Email",
    shortLabel: "Email",
    image: "/img/notification-types/email.svg",
  },
  {
    type: "push",
    shortLabel: "Push",
    label: "Push notification",
    image: "/img/notification-types/push.svg",
  },
];
