import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  Center,
  Container,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { useConfirmEmail } from "../../hooks/useConfirmEmail";
import { LoadingCover } from "../elements/LoadingCover";

export function ConfirmEmailPage() {
  const { push } = useHistory();
  const { code } = useParams<{ code: string }>();

  const { mutate: confirmEmail } = useConfirmEmail();

  useEffect(() => {
    confirmEmail(
      { code },
      {
        onSuccess: async () => {
          await Swal.fire({
            icon: "success",
            title: "Email confirmed",
            text: "Your email was confirmed. You may now login",
          });
        },
        onError: async () => {
          await Swal.fire({
            icon: "error",
            title: "Failed to confirm email",
            text: "Your email could not be confirmed. Please check the invite has not expired and try again.",
          });
        },
        onSettled: () => {
          push("/login");
        },
      }
    );
  }, [code, push, confirmEmail]);

  return <LoadingCover isLoading={true} />;
}
