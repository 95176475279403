import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  Box,
  SimpleGrid,
  HStack,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState } from "react";

import { useOrganisations } from "../../hooks/useOrganisations";
import { useProductUpdate } from "../../hooks/useProductUpdate";
import { ModalProps } from "../../interfaces/ModalProps";
import { Button } from "../elements/Button";
import { CardHeader } from "../elements/card/CardHeader";
import { Text } from "../elements/Text";
import { OrganisationSelectCard } from "../organisation/OrganisationSelectCard";

import { CreateOrganisationModal } from "./CreateOrganisationModal";
import { JoinOrganisationModal } from "./JoinOrganisationModal";

export interface ServiceConfigurationProps {
  onClose: () => void;
  data?: Record<string, any>;
}

export function SelectOrganisationModal({ isOpen, onClose }: ModalProps) {
  const { isLoading } = useProductUpdate();
  const [isCreatingOrganisation, setIsCreatingOrganisation] =
    useState<boolean>(false);

  const [isJoiningOrganisation, setIsJoiningOrganisation] =
    useState<boolean>(false);

  const { data: organisations, isLoading: isLoadingOrganisations } =
    useOrganisations();

  if (isLoading) return null;

  return (
    <>
      {isOpen || isJoiningOrganisation || isCreatingOrganisation ? (
        <Box
          minH="100vh"
          minW="100vw"
          position="fixed"
          backgroundImage="img/rose-2.svg"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundColor="brand.200"
        />
      ) : null}
      <CreateOrganisationModal
        isOpen={isOpen && !isJoiningOrganisation && isCreatingOrganisation}
        onOpen={() => {}}
        onClose={() => setIsCreatingOrganisation(false)}
      />
      <JoinOrganisationModal
        isOpen={isOpen && !isCreatingOrganisation && isJoiningOrganisation}
        onOpen={() => {}}
        onClose={() => setIsJoiningOrganisation(false)}
      />
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={!isCreatingOrganisation && !isJoiningOrganisation && isOpen}
        size="xl"
        isCentered={true}
      >
        {/* <AlertDialogOverlay
          backgroundImage="img/rose-2.svg"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundColor="brand.200"
        /> */}
        <AlertDialogContent>
          <Box p={6} pb={0} justifyContent="center" textAlign="center">
            <CardHeader title="Welcome to Notiversal!" />
            <Text mb={isEmpty(organisations) ? 8 : 0} mt={2}>
              Universal notifications for internal teams.
            </Text>
            {/* <CardHeader
              title={
                isEmpty(organisations) ? (
                  <>
                  
                  </>
                ) : (
                  `Select your organiastion`
                )
              }
            ></CardHeader> */}
          </Box>
          <AlertDialogBody pb={6}>
            {isLoadingOrganisations && <Spinner size="md" />}
            {!isEmpty(organisations) ? (
              <>
                {organisations?.length === 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={5}
                  >
                    <Box w={["100%", "50%"]}>
                      {organisations?.map((organisation) => (
                        <OrganisationSelectCard organisation={organisation} />
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <SimpleGrid columns={[1, 2]} spacing="20px" p={5}>
                    {organisations?.map((organisation) => (
                      <OrganisationSelectCard
                        key={organisation.id}
                        organisation={organisation}
                      />
                    ))}
                  </SimpleGrid>
                )}
              </>
            ) : null}
            <VStack spacing={2}>
              <HStack
                mt={!isEmpty(organisations) ? 4 : 0}
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  icon="sign-in-alt"
                  onClick={() => {
                    setIsJoiningOrganisation(true);
                  }}
                >
                  Join an organisation
                </Button>
                <Button
                  icon="plus"
                  onClick={() => {
                    setIsCreatingOrganisation(true);
                  }}
                >
                  Create an organisation
                </Button>
              </HStack>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
