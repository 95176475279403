import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const timeAgo = (timestamp: string | Date) => {
  const time = dayjs(timestamp);
  if (time.isBefore(dayjs().subtract(7, "day"))) {
    return time.format("DD/MM/YY");
  }

  return `${time.fromNow()}`;
};
