import { useQuery } from "react-query";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export interface ServiceStatus {
  enabled: boolean;
  organisationDisabled: boolean;
  data: any;
}

export interface ServiceStatusResponse {
  [type: string]: ServiceStatus;
}

export function useServiceStatuses() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<ServiceStatusResponse>(
    ["service-status", currentOrganisation],
    async () => {
      const response = await get<ServiceStatusResponse>(
        `services/${currentOrganisation?.id}/service/status`,
        {}
      );
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
}
