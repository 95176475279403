import { ReactNode } from "react";
import ScrollAnimation, { ScrollAnimationProps } from "react-animate-on-scroll";

interface IAnimationProps extends ScrollAnimationProps {
  index?: number;
  children: ReactNode;
}

export function Animation({ children }: IAnimationProps) {
  return <>{children}</>;
  // return (
  // <ScrollAnimation
  //   delay={index ?? 0 * 0}
  //   animateIn="fadeIn"
  //   duration={0}
  //   animateOnce={false}
  //   {...props}
  // />
  // );
}
