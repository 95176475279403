import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useProductNotify(productId?: string) {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<boolean, null, Record<string, string>>(
    "product/notify",
    async (data) => {
      const response = await post<null>(`products/${productId}/notify`, data);

      if (response.status !== 201)
        return Promise.reject("Could not send notification");
      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "products/organisation",
          currentOrganisation?.id,
          productId,
        ]);
      },
    }
  );
}
