import {
  Box,
  IconButton,
  Flex,
  HStack,
  Menu,
  Text,
  MenuButton,
  MenuList,
  MenuItem as RNMenuItem,
  Drawer,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useAlert } from "../../hooks/useAlert";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useLogout } from "../../hooks/useLogout";
import { useMe } from "../../hooks/useMe";
import { useTheme } from "../../hooks/useTheme";
import { MenuItem } from "../../interfaces/MenuItem";
import { AuthGuard } from "../AuthGuard";
import { Avatar } from "../elements/Avatar";
import { Icon } from "../elements/Icon";
import { Link } from "../elements/Link";
import { NavbarButton } from "../elements/NavbarButton";
import { Logo } from "../Logo";
import { EditAccountModal } from "../modals/EditAccountModal";
import { SubscriptionExpiredBanner } from "../SubscriptionExpiredBanner";
import { SubscriptionProcessingBanner } from "../SubscriptionProcessingBanner";
import { Role } from "../users/UserCard";

import { NavigationDrawer } from "./NavigationDrawer";

interface NavItem {
  label: string;
  level?: Role;
  subLabel?: string;
  icon?: IconProp;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Dashboard",
    href: "/",
    icon: "home",
  },
  {
    label: "Products",
    href: "/products",
    icon: "box-open",
  },

  {
    label: "Users",
    href: "/users",
    icon: "users",
  },

  {
    label: "Services",
    href: "/services",
    icon: "concierge-bell",
  },

  {
    label: "Organisation",
    href: "/organisation",
    icon: "building",
    level: Role.Admin,
  },
];

export function Header() {
  const { mutate: logout } = useLogout();
  const { push } = useHistory();
  const { isDarkMode } = useTheme();
  const { currentOrganisation, setCurrentOrganisation } =
    useCurrentOrganisation();
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const { setIsLoading, alert, closeAlert } = useAlert();

  const { data: me } = useMe();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  function handleLogout() {
    alert({
      label: "Logout",
      text: "Are you sure you wish to logout?",
      confirmText: "Logout",
      onConfirm: () => {
        setIsLoading(true);
        logout(undefined);
        setTimeout(() => closeAlert(), 250);
        setIsLoading(false);
      },
    });
  }

  const MENU_ITEMS: MenuItem[] = [
    {
      icon: "exchange-alt",
      label: "Switch organisation",
      to: "/products",
      onClick: () => {
        setCurrentOrganisation(null);
      },
    },
    {
      icon: "user",
      label: "Account",
      onClick: () => setAccountModalOpen(true),
    },
    {
      icon: "sign-out-alt",
      label: "Logout",
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <NavigationDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
      <EditAccountModal
        onOpen={() => {}}
        onClose={() => setAccountModalOpen(false)}
        isOpen={accountModalOpen}
      />
      <Box
        boxShadow="rgb(0 0 0 / 11%) 0 1px"
        position="relative"
        zIndex={3}
        layerStyle="primary"
        bg={isDarkMode ? "dark.300" : "brand.400"}
      >
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <IconButton
            size="md"
            bg="transparent"
            icon={<Icon icon="bars" />}
            onClick={() => setIsDrawerOpen(true)}
            aria-label="Open Menu"
            ml={2}
            _hover={{ backgroundColor: "rgba(0,0,0,.1)" }}
            _active={{ backgroundColor: "rgba(0,0,0,.1)" }}
            display={{ md: "none" }}
          />
          <HStack height="100%" spacing={8} alignItems="center">
            <Link to="/">
              <Logo h={6} pl={4} />
            </Link>
            <HStack
              as="nav"
              height="100%"
              spacing={0}
              display={{ base: "none", md: "flex" }}
            >
              {NAV_ITEMS.map((navItem) => (
                <AuthGuard role={navItem.level} key={navItem.label}>
                  <NavbarButton {...navItem}>{navItem.label}</NavbarButton>
                </AuthGuard>
              ))}
            </HStack>
          </HStack>
          <Menu>
            <MenuButton
              as={Box}
              transition="100ms all"
              px={4}
              h="100%"
              justifyContent="center"
              cursor="pointer"
              fontSize={22}
              display="flex"
              alignItems="center"
              flexDirection="column"
              maxHeight="100%"
              aria-label="Options"
            >
              <Box
                flexDirection="row"
                display="flex"
                height="100%"
                alignItems="center"
              >
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar image={me?.avatar} variant="beam" id={me?.id ?? ""} />
                </Box>
                <Box flexDirection="row" alignItems="center">
                  <Box
                    display={["none", "flex"]}
                    ml={3}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box mr={5}>
                      <Text lineHeight={1.1} fontSize={14} fontWeight="600">
                        {me?.firstName} {me?.lastName}
                      </Text>
                      {currentOrganisation ? (
                        <Text fontSize={12}>{currentOrganisation?.name}</Text>
                      ) : null}
                    </Box>
                    <Icon icon="exchange-alt" />
                  </Box>
                </Box>
              </Box>
            </MenuButton>
            <MenuList py={0}>
              {MENU_ITEMS.map(({ to, onClick, icon, label }) => (
                <RNMenuItem
                  color="gray.800"
                  p={3}
                  px={5}
                  key={label}
                  onClick={() => {
                    if (to) push(to);
                    if (onClick) onClick();
                  }}
                >
                  <Link
                    to={to}
                    onClick={onClick}
                    fontSize={16}
                    color="gray.700"
                    _hover={{ textDecoration: "none" }}
                  >
                    <HStack spacing={3}>
                      <Icon icon={icon} size={14} color="brand.600" />
                      <Text fontSize="sm" color="text.200">
                        {label}
                      </Text>
                    </HStack>
                  </Link>
                </RNMenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <SubscriptionExpiredBanner />
        <SubscriptionProcessingBanner />
      </Box>
    </>
  );
}
