import { BoxProps, Box, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ISidebarButton extends BoxProps {
  children?: ReactNode;
  label: string;
}

export function SidebarSection({ label, children, ...props }: ISidebarButton) {
  return (
    <Box w="100%" {...props}>
      <Text
        width="100%"
        textAlign={["left", "left"]}
        mb={4}
        fontWeight="bold"
        color="brand.200"
        fontSize="sm"
      >
        {label}
      </Text>
      {children}
    </Box>
  );
}
