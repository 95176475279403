import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Image,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { BiMailSend } from "react-icons/bi";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => (
  <chakra.button
    bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
    rounded="full"
    w={8}
    h={8}
    cursor="pointer"
    as="a"
    href={href}
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    transition="background 0.3s ease"
    _hover={{
      bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
    }}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
    {children}
  </chakra.button>
);

const ListHeader = ({ children }: { children: ReactNode }) => (
  <Text fontWeight="500" fontSize="lg" mb={2}>
    {children}
  </Text>
);

export default function LargeWithNewsletter() {
  return (
    <>
      <Box bg="gray.200" p={5} mt={10} position="relative">
        <Container maxW="container.xl">
          <HStack
            w="100%"
            justifyContent="space-between"
            fontSize={13}
            color="gray.500"
          >
            <Box>Copyright {dayjs().format("YYYY")} - Orange Byte Limited</Box>
            <Box>Orange Byte Limited is registered in the UK. No. 12549619</Box>
          </HStack>
        </Container>
      </Box>
    </>
  );
}
