import { useMutation, useQueryClient } from "react-query";
import { UserEntity } from "service/src/users/entities/user.entity";

import { put } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useMe } from "./useMe";

type ICheckoutRequest = Record<string, string>;

export function useAccountUpdate() {
  const { data: me } = useMe();
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<UserEntity, null, ICheckoutRequest>(
    "account",
    async (data) => {
      const response = await put<UserEntity>("users/account", {
        ...data,
        mobile: data?.mobile ? data?.mobile : undefined,
      });
      if (response.status !== 200) {
        throw Promise.reject("Could not update account");
      }
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("user/account", { ...me, ...data });
        queryClient.invalidateQueries(["service-status"]);
        queryClient.invalidateQueries("user/account");
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation?.id,
          "users",
        ]);
      },
    }
  );
}
