import "@fontsource/poppins/latin.css";

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";

import App from "./App";
import theme from "./theme";

function Root() {
  return (
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  );
}

export default Root;
