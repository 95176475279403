import { Box, Stack } from "@chakra-ui/react";
import tinycolor from "tinycolor2";

const colors = [
  "#2b5b9d",
  "#6BD5FF",
  "#7C6BFF",
  "#FF6B7A",
  "#2B9E94",
  "#E66BFF",
  "#FF6BAB",
];

interface ColorInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function ColorInput({ onChange, value }: ColorInputProps) {
  return (
    <Stack
      direction={["column", "row"]}
      mt={1}
      spacing="8px"
      alignItems="center"
      justifyContent="center"
    >
      {colors.map((color) => (
        <Box
          w="50px"
          h="50px"
          bg={color}
          cursor="pointer"
          onClick={() => onChange(color)}
          key={color}
          borderRadius={3}
          borderWidth={value === color ? 4 : 0}
          borderColor={tinycolor(color).lighten(20).toString()}
        />
      ))}
    </Stack>
  );
}
