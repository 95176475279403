import {
  AlertDialogCloseButton,
  Box,
  VStack,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form } from "react-templator";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { NOTIFY_FORM } from "../../forms/auth/notify-form";
import { useProductNotify } from "../../hooks/useProductNotify";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";
import { Text } from "../elements/Text";

interface NotifyModalProps extends ModalProps {
  product?: ProductEntity;
}

export function NotifyModal({ isOpen, product, onClose }: NotifyModalProps) {
  const { mutateAsync: notify, isLoading } = useProductNotify(product?.id);

  const toast = useToast();
  async function handleSubmit(data: Record<string, string>, setErrors: any) {
    const result = await notify(data).catch((errors) => {
      setErrors(errors);
    });
    if (result) {
      toast({
        title: "Notification sent successfully!!",
        status: "success",
        isClosable: true,
      });

      onClose();
    }
  }
  if (!product) return null;

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="md"
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <Box p={6} pb={6}>
            <VStack spacing={5} w="100%">
              <VStack
                spacing={2}
                justifyContent="flex-start"
                alignItems="100%"
                width="100%"
              >
                <CardHeader title="Send a notification" />
                <Box>
                  <Text>
                    Manually send a notification to everyone subscribed to this
                    rpoduct.
                  </Text>
                </Box>
              </VStack>
              <Box w="100%">
                <Form
                  schema={NOTIFY_FORM}
                  dynamicProps={{
                    submit: {
                      isLoading,
                    },
                  }}
                  onSubmit={(data, setErrors) => {
                    handleSubmit(data, setErrors);
                    //   handleOnSubmit(data);
                  }}
                />
              </Box>
            </VStack>
          </Box>
          {!isLoading && <AlertDialogCloseButton />}
        </ModalContent>
      </Modal>
    </>
  );
}
