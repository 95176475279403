export const INVITE_USER_FORM = [
  {
    type: "text-input",
    name: "name",
    label: "Name",
    required: true,
    minLength: 2,
    icon: "user",
    maxLength: 64,
  },

  {
    type: "text-input",
    name: "email",
    label: "Email",
    required: true,
    minLength: 2,
    icon: "envelope",
    maxLength: 64,
  },

  {
    type: "select",
    options: [
      {
        value: "User",
        label: "User",
      },
      {
        value: "Developer",
        label: "Developer",
      },
      {
        value: "Admin",
        label: "Admin",
      },
    ],
    name: "inviteAs",
    label: "Invite as",
    required: true,
    minLength: 2,
    icon: "envelope",
    maxLength: 64,
  },

  {
    type: "submit",
    label: "Invite",
    icon: "envelope",
    name: "submit",
  },
];
