export const ORGANISATION_INVITE_FORM = [
  {
    type: "text-input",
    name: "code",
    label: "Code",
    required: true,
    minLength: 36,
    icon: "code",
    maxLength: 36,
  },
  {
    type: "submit",
    label: "Join",
    name: "submit",
  },
];
