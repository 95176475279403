import { Text } from "./Text";

export function Money({ children }: { children: number }) {
  return (
    <Text
      children={children.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      })}
    />
  );
}
