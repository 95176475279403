import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

import { useNotificationPreferences } from "./useNotificationPreferences";

interface SetProductNotificationPreferenceRequest {
  preference: string;
  value: boolean;
}

export function useSetEventNotificationPreference(
  productId?: string,
  eventId?: string
) {
  const queryClient = useQueryClient();
  const { data: preferences } = useNotificationPreferences({
    productId,
    eventId,
  });

  return useMutation<boolean, null, SetProductNotificationPreferenceRequest>(
    ["notify-preferences", eventId],
    async (input: SetProductNotificationPreferenceRequest) => {
      const response = await post(
        `products/${productId}/event/${eventId}/notification-preferences`,
        { preference: input.preference, enabled: input.value }
      );
      return response.status === 200;
    },
    {
      onSuccess: (_data, input) => {
        const currentValue = preferences?.find(
          (preference) => preference.preference === input.preference
        );

        if (!currentValue) {
          queryClient.setQueryData(
            ["notify-preferences", eventId],
            [
              ...(preferences ?? []),
              { preference: input.preference, isEnabled: input.value },
            ]
          );
        } else {
          queryClient.setQueryData(
            ["notify-preferences", eventId],
            preferences?.map((preference) =>
              preference.preference === input.preference
                ? { ...preference, isEnabled: input.value }
                : preference
            )
          );
        }
      },
    }
  );
}
