import { HStack, Link, Stack, VStack, Box } from "@chakra-ui/react";
import { useState } from "react";

import { useAlert } from "../../hooks/useAlert";
import { useOrganisationService } from "../../hooks/useOrganisationService";
import { useOrganisationServiceDelete } from "../../hooks/useOrganisationServiceDelete";
import { ServiceStatus } from "../../hooks/useServiceStatuses";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { Text } from "../elements/Text";
import { IOrganisationService, IService } from "../pages/ServicesPage";

import { ServiceConfigurationModal } from "./ServiceConfigurationModal";

interface NewsProps {
  item: IOrganisationService | IService;
  isDisabled?: boolean | (() => boolean);
  isOrganisationDisabled?: boolean;
  serviceStatus?: ServiceStatus;
}

export function ServiceCard({
  item,
  serviceStatus,
  isDisabled: isServiceDisabled,
  isOrganisationDisabled,
}: NewsProps) {
  const { label, isDefault, component: Component, type, image } = item;
  const { data: service } = useOrganisationService({ type: item.type });

  if (typeof isServiceDisabled === "function") {
    isServiceDisabled = isServiceDisabled();
  }

  const isDisabled = isServiceDisabled || isOrganisationDisabled;

  const { setIsLoading, alert, closeAlert } = useAlert();

  const { mutate: deleteService } = useOrganisationServiceDelete();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function handleDelete() {
    alert({
      label: "Confirm deletion",
      text: `Are you sure you wish to disable ${item.label} for this organisation?`,
      confirmText: "Disable",
      onConfirm: () => {
        setIsLoading(true);
        deleteService(type, {
          onSuccess: () => closeAlert(),
          onSettled: () => setIsLoading(false),
        });
      },
    });
  }

  return (
    <>
      {/* <NewsModal
        isOpen={isModalOpen}
        news={item}
        onOpen={() => {}}
        onClose={() => setIsModalOpen(false)}
      /> */}
      {!isDefault ? (
        <ServiceConfigurationModal
          isOpen={isModalOpen}
          serviceType={type}
          serviceName={label}
          onOpen={() => {}}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}

      <Card
        maxW="100%"
        rounded="md"
        p={2}
        py={4}
        overflow="hidden"
        boxShadow="sm"
        transition="100ms all"
      >
        <Stack>
          <HStack
            spacing={2}
            inline={true}
            p={4}
            py={2}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <HStack spacing={6} alignItems="center">
              <HStack>
                <Box
                  width={20}
                  height={20}
                  filter={isDisabled ? "grayscale(1)" : undefined}
                  position="relative"
                  backgroundSize="cover"
                  backgroundPosition="center"
                  backgroundImage={image}
                />
              </HStack>
              <VStack
                textAlign="left"
                justifyContent="center"
                spacing={4}
                alignItems="flex-start"
              >
                <HStack>
                  <Text color="brand.400" fontSize="md" fontWeight={600}>
                    {label}
                  </Text>
                  <Box ml={14}>
                    <Icon
                      color={isDisabled ? "red.400" : "green.400"}
                      icon={isDisabled ? "times" : "check"}
                      size={18}
                    />
                  </Box>
                </HStack>
                {Component ? (
                  isOrganisationDisabled ? (
                    <Text color="gray.500" mt="0 !important">
                      Disabled by organisation
                    </Text>
                  ) : (
                    <Component serviceStatus={serviceStatus} />
                  )
                ) : (
                  <>
                    <Text color="gray.500" mt="0 !important">
                      {isDefault && "Enabled by default"}
                      {service && "Configured"}
                      {!service && "Not setup"}
                    </Text>
                    {!isDefault ? (
                      <HStack spacing={4} mt={0}>
                        {!isDisabled ? (
                          <>
                            <Link
                              color="brand.400"
                              fontSize={14}
                              onClick={() => setIsModalOpen(true)}
                            >
                              Edit configuration
                            </Link>
                            <Link
                              color="red.400"
                              fontSize={14}
                              onClick={() => handleDelete()}
                            >
                              Disable
                            </Link>
                          </>
                        ) : (
                          <Link
                            color="brand.200"
                            onClick={() => setIsModalOpen(true)}
                            fontSize={14}
                          >
                            Setup {label}{" "}
                            <Icon ml={1} size={12} icon="chevron-right" />
                          </Link>
                        )}
                      </HStack>
                    ) : null}
                  </>
                )}
              </VStack>
            </HStack>
          </HStack>

          {/* <Heading fontSize={'2xl'} fontFamily={'body'}>
            Boost your conversion rate
          </Heading> */}
        </Stack>
      </Card>
      {/* <Card label={label} p={6} pt={6} pb={6} minW={450}>
        <VStack spacing={5} align="left">
          <Text color="white.200">{description}</Text>

          <Stack spacing={2} direction={['column', 'row']} mt={5}>
            {[...Array(requiredStamps)].map((_, index) => (
              <Box
                key={index}
                width={5}
                height={5}
                background="gray.200"
                borderRadius={100}
              />
            ))}
          </Stack>
          
        </VStack>
      </Card> */}
    </>
  );
}
