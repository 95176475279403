import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

export function useJoinOrganisation() {
  const queryClient = useQueryClient();
  return useMutation<string | null, string, Record<string, string>>(
    "organisation/join",
    async (data: Record<string, string>) => {
      const response = await post<{ status: number; message: string }>(
        "organisations/join",
        data
      );

      if (+response?.data?.status === 400) {
        throw response?.data.message;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organisations");
      },
    }
  );
}
