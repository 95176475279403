import { Box, HStack, Wrap } from "@chakra-ui/react";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductEvent } from "service/src/notifications/interface/ProductEvent";
import { NotifyPreferenceEntity } from "service/src/products/entities/notify-preference.entity";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { NOTIFY_TYPES } from "../../constants/notify-types";
import { useDeleteEventNotificationPreference } from "../../hooks/useDeleteEventNotificationPreference";
import { useNotificationPreferences } from "../../hooks/useNotificationPreferences";
import { useSetEventNotificationPreference } from "../../hooks/useSetEventNotificationPreference.ts";
import { Icon } from "../elements/Icon";
import { LoadingBackground } from "../elements/LoadingBackground";

interface IEventNotificationPreferencesProps {
  event: Partial<ProductEvent>;
  isLoading?: boolean;
  product?: Partial<ProductEntity>;
}
export function EventNotificationPreferences({
  isLoading,
  event: { id: eventId },
  product: { id: productId } = {},
}: IEventNotificationPreferencesProps) {
  const { data: preferenceData } = useNotificationPreferences({
    eventId,
    productId,
  });

  const { mutate: setEventNotificationPreference, isLoading: isSetLoading } =
    useSetEventNotificationPreference(productId, eventId);

  const {
    mutate: deleteEventNotificationPreference,
    isLoading: isDeleteLoading,
  } = useDeleteEventNotificationPreference(productId, eventId);

  const isEventLoading = isSetLoading || isDeleteLoading;

  function handleToggle(preference: string, isActive: boolean | undefined) {
    const data = { preference, value: !isActive };

    if (eventId) {
      if (isActive === false && data.value) {
        deleteEventNotificationPreference({ preference });
        return;
      }

      setEventNotificationPreference(data);
    }
  }

  function getIcon(
    data: NotifyPreferenceEntity | undefined
  ): IconProp | [IconPrefix, IconName] {
    if (data?.isEnabled) return "check";
    if (data?.isEnabled === false) return "times";
    return ["far", "circle"];
  }

  function getIconColor(data: NotifyPreferenceEntity | undefined) {
    if (data?.isEnabled) return "green.400";
    if (data?.isEnabled === false) return "red.400";
    return "gray.300";
  }

  return (
    <Wrap
      spacing={2}
      wrap="wrap"
      pointerEvents={isLoading || isEventLoading ? "none" : "auto"}
    >
      {NOTIFY_TYPES.map((preference) => {
        const data =
          preferenceData &&
          preferenceData?.find((data) => data.preference === preference.type);

        return (
          <Box
            key={preference.type}
            cursor="pointer"
            onClick={
              !isLoading
                ? () => handleToggle(preference.type, data?.isEnabled)
                : undefined
            }
          >
            <LoadingBackground isLoading={isLoading} borderRadius={100}>
              <Box bg="gray.200" borderRadius={100} p={1} px={3} fontSize={14}>
                <HStack>
                  {/* {data?.isEnabled !== undefined && ( */}
                  <Box
                    w={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon
                      color={getIconColor(data)}
                      icon={getIcon(data)}
                      size={18}
                    />
                  </Box>
                  {/* )} */}
                  <Box>{preference.shortLabel}</Box>
                </HStack>
              </Box>
            </LoadingBackground>
          </Box>
        );
      })}
    </Wrap>
  );
}
