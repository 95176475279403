import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ILoadingPlaceholderProps {
  isLoading?: boolean;
  children: ReactNode;
  length: number;
}
export function LoadingPlaceholder({
  isLoading,
  length = 3,
  children,
}: ILoadingPlaceholderProps) {
  if (!isLoading) return null;

  return (
    <>
      {Array.from(Array(length)).map((_value, index) => (
        <Box w="100%" key={index}>
          {children}
        </Box>
      ))}
    </>
  );
}
