import { BoxProps, Icon as RNIcon, IconProps } from "@chakra-ui/react";
import {
  IconName,
  IconPrefix,
  IconProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IIconProps extends IconProps {
  icon: IconProp | [IconPrefix, IconName];
  size?: BoxProps["width"];
}

export function Icon({ icon, size = 14, ...props }: IIconProps) {
  return <RNIcon icon={icon} as={FontAwesomeIcon} fontSize={size} {...props} />;
}
