import { useQuery } from "react-query";
import { EventEntity } from "service/src/notifications/entities/event.entity";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";
import { ProductEntity } from "service/src/products/entities/product.entity";
import { WorkerUserPreferences } from "worker/src/interface/WorkerUsers";

import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { PaginatedResponse } from "../../hooks/useInvoices";
import { get } from "../../services/api";

export interface IOrganisationEvent {
  id: string;
  createdAt: string;
  notifiers: WorkerUserPreferences;
  product: ProductEntity;
  organisation: OrganisationEntity;
  event: EventEntity;
}

export type IOrganisationEventsResponse = PaginatedResponse<IOrganisationEvent>;

export function useOrganisationEvents(page: number) {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<IOrganisationEventsResponse>(
    ["organisation-events", currentOrganisation?.id, page],
    async () => {
      const response = await get<IOrganisationEventsResponse>(
        `organisations/${currentOrganisation?.id}/events`,
        { page }
      );

      if (response.status === 200) return response.data;
      return Promise.reject(new Error("Could not fetch events"));
    },
    {
      enabled: !!currentOrganisation,
      keepPreviousData: true,
    }
  );
}
