import { useMutation } from "react-query";
import { UserEntity } from "service/src/users/entities/user.entity";

import { IApiResponse } from "../interfaces/IApiResponse";
import { post } from "../services/api";

export interface InvoiceResponse {
  access_token: string;
  message: string;
}

type ILoginRequest = Record<string, string>;

export function useRegister() {
  return useMutation<IApiResponse<UserEntity>, string, ILoginRequest>(
    "auth",
    async ({ ...data }: ILoginRequest) => {
      const response = await post<IApiResponse<UserEntity>>(
        "users/register",
        data
      );

      if (response.status !== 201) {
        throw response.data.errors;
      }

      return response.data;
    }
  );
}
