import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  AlertDialogHeader,
  HStack,
} from "@chakra-ui/react";
import { ProductInterface } from "service/src/subscription/interface/ProductInterface";

import { useSubscriptionUpgrade } from "../../../hooks/useSubscriptionUpgrade";
import { ModalProps } from "../../../interfaces/ModalProps";
import { Button } from "../../elements/Button";
import { Card } from "../../elements/card/Card";

interface SubscriptionUpgradeModalProps extends ModalProps {
  product: ProductInterface;
}
export function SubscriptionUpgradeModal({
  product,
  isOpen,
  onClose,
}: SubscriptionUpgradeModalProps) {
  const { mutate: subscriptionCancel, isLoading } = useSubscriptionUpgrade();

  function handleCancel() {
    subscriptionCancel(
      {
        product: product.name,
      },
      {
        onSuccess: () => onClose(),
      }
    );
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Card p={2} pt={4} pb={4} minW={450}>
            <AlertDialogHeader>Upgrade subscription</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Do you wish to upgrade to {product.label}? You will automatically
              be charged the price difference between your current subscription
              and the new one
            </AlertDialogBody>
            <AlertDialogFooter mt={3}>
              <HStack spacing={2}>
                <Button
                  colorScheme="gray"
                  color="gray.800"
                  disabled={isLoading}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="brand"
                  isLoading={isLoading}
                  onClick={handleCancel}
                >
                  Upgrade Subscription
                </Button>
              </HStack>
            </AlertDialogFooter>
          </Card>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
