import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  Text,
} from "@chakra-ui/react";

import { SIDEBAR_ITEMS } from "../../constants/sidebar-items";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useMe } from "../../hooks/useMe";
import { ISidebarSection } from "../../interfaces/ISidebarSection";
import { Avatar } from "../elements/Avatar";
import { Icon } from "../elements/Icon";

import { Sidebar } from "./Sidebar";
import { SidebarContent } from "./SidebarContent";

export const SIDEBAR_WIDTH = "340px";
interface NavigationDrawerProps {
  isOpen?: boolean;
  onClose: () => void;
}

export function NavigationDrawer({ isOpen, onClose }: NavigationDrawerProps) {
  const { currentOrganisation } = useCurrentOrganisation();
  const { data: me } = useMe();

  return (
    <Sidebar
      isOpen={!!isOpen}
      onClose={onClose}
      header={
        <>
          <Box p={4} bg="brand.200" w="100%" color="white">
            <Box
              flexDirection="row"
              display="flex"
              height="100%"
              alignItems="center"
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar image={me?.avatar} variant="beam" id={me?.id ?? ""} />
              </Box>
              <Box flexDirection="row" alignItems="center">
                <Box mr={5} ml={3}>
                  <Text lineHeight={1.1} fontSize={14} fontWeight="600">
                    {me?.firstName} {me?.lastName}
                  </Text>
                  {currentOrganisation ? (
                    <Text fontSize={12}>{currentOrganisation?.name}</Text>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      }
      items={SIDEBAR_ITEMS}
      variant="drawer"
    />
  );
}
