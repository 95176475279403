export const PRODUCT_UPDATE_FORM = [
  {
    type: "text-input",
    name: "label",
    label: "Title",
    required: true,
    minLength: 2,
    icon: "user",
    helper: "The name of your product",
    maxLength: 64,
  },

  {
    type: "file",
    required: true,
    name: "newImage",
    label: "Image",
    helper: "Your product logo (square)",
    minLength: 4,
    icon: "images",
  },

  {
    type: "submit",
    label: "Save",
    name: "submit",
  },
];
