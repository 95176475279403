import {
  Box,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { OrganisationInviteEntity } from "service/src/organisations/entities/organisation-invite.entity";
import { OrganisationUserResponse } from "service/src/organisations/response/organisation-user.response";

import { useAlert } from "../../hooks/useAlert";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useLimits } from "../../hooks/useLimits";
import { useMe } from "../../hooks/useMe";
import { useOrganisationLeave } from "../../hooks/useOrganisationLeave";
import { useOrganisationRemoveInvite } from "../../hooks/useOrganisationRemoveInvite";
import { getRoleColor } from "../../utils/role";
import { AuthGuard } from "../AuthGuard";
import { Avatar } from "../elements/Avatar";
import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Tag, Tags } from "../elements/Tags";
import { Text } from "../elements/Text";
import { InviteUserModal } from "../modals/InviteUserModal";
import { ModifyUserModal } from "../modals/ModifyUserModal";

export enum Role {
  User = "User",
  Developer = "Developer",
  Admin = "Admin",
}

interface IUserCardProps {
  item?: Partial<OrganisationInviteEntity>;
  isLoading?: boolean;
}

export function PendingUserCard({ item, isLoading }: IUserCardProps) {
  const { setIsLoading, alert, closeAlert } = useAlert();
  const { mutate: removeInvite } = useOrganisationRemoveInvite();

  const userTags: Tag[] = [
    {
      label: item?.inviteAs,
      colorScheme: getRoleColor(item?.inviteAs),
      icon: "user-tag",
      show: true,
    },
    {
      label: "Pending",
      colorScheme: "orange",
      icon: "clock",
      show: true,
    },
  ];

  const handleRemoveInvite = useCallback(() => {
    alert({
      label: "Confirm removal",
      text: `Are you sure you wish to remove the invite for ${item?.name} from your organisation?`,
      confirmText: "Delete",
      onConfirm: () => {
        setIsLoading(true);
        removeInvite(
          { id: item?.id ?? "" },
          {
            onSuccess: () => {
              closeAlert();
            },
            onSettled: () => setIsLoading(false),
          }
        );
      },
    });
  }, [alert, closeAlert, removeInvite, item, setIsLoading]);

  return (
    <>
      <Card display={isLoading ? "none" : undefined} w="100%">
        <VStack
          justifyContent="center"
          alignItems="center"
          h="100%"
          w="100%"
          spacing={10}
        >
          <Icon
            icon={["fas", "user-clock"]}
            fontSize={105}
            color={useColorModeValue("gray.200", "gray.700")}
          />
          <VStack spacing={4}>
            <VStack spacing={1} pt={5} w="100%">
              <Heading
                fontWeight={600}
                fontSize="20"
                fontFamily="body"
                color={useColorModeValue("gray.700", "gray.200")}
              >
                {item?.name}
              </Heading>
              <Text
                fontSize={15}
                color={useColorModeValue("gray.500", "gray.400")}
              >
                {item?.email}
              </Text>
            </VStack>
            <Tags
              tags={userTags}
              alignItems="center"
              alignContent="center"
              justify="center"
              justifyContent="center"
              display="flex"
              textAlign="center"
            />
          </VStack>
          <AuthGuard role={Role.Admin}>
            <Link
              color="red.400"
              fontSize={14}
              onClick={() => handleRemoveInvite()}
            >
              Remove invite
            </Link>
          </AuthGuard>
        </VStack>
      </Card>
    </>
  );
}
