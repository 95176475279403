import { useQuery, useQueryClient } from "react-query";
import { ISubscription } from "service/src/subscription/interface/ISubscription";
import { ProductInterface } from "service/src/subscription/interface/ProductInterface";
import { ProductData } from "service/src/util/subscription";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useActiveSubscription() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<ISubscription | undefined>(
    [currentOrganisation?.id, "subscriptions"],
    async () => {
      const response = await get<ISubscription>(
        `subscriptions/${currentOrganisation?.id}/active`,
        {}
      );

      if (response?.status !== 200) return undefined;

      return response.data;
    },
    {
      refetchInterval: 30000,
      keepPreviousData: true,
    }
  );
}
