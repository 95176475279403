import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogCloseButton,
  useToast,
  Box,
} from "@chakra-ui/react";
import { Form } from "react-templator";

import { INVITE_USER_FORM } from "../../forms/auth/invite-user-form";
import { useInviteUser } from "../../hooks/useInviteUser";
import { News } from "../../hooks/useNews";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";
import { Text } from "../elements/Text";

interface NewsModalProps extends ModalProps {
  news?: News;
}

export function InviteUserModal({ isOpen, news, onClose }: NewsModalProps) {
  const { mutate: inviteUser, isLoading } = useInviteUser();
  const toast = useToast();

  function handleOnSubmit(data: Record<string, string>) {
    inviteUser(data, {
      onError: (e) => {
        console.log(e);
      },
      onSuccess: () => {
        toast({
          title: `${data.name} has been invited!`,
          status: "success",
          isClosable: true,
        });

        onClose();
      },
    });
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Box p={6} pb={3}>
            <CardHeader title="Invite a user" />
            <Box mt={2}>
              <Text>
                Enter the name and email of the person you would like to invite
                to your organisation
              </Text>
            </Box>
          </Box>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody pb={6}>
            <Form
              schema={INVITE_USER_FORM}
              defaultValues={news}
              dynamicProps={{
                newHeaderImage: {
                  hasFile: !!news?.headerImage,
                },
                submit: {
                  isLoading,
                },
              }}
              onSubmit={(data) => {
                handleOnSubmit(data);
              }}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
