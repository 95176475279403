import { HStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { ILayoutProps } from "react-templator";

import { RecaptchaInput } from "../components/elements/RecaptchaInput";
import { Text } from "../components/elements/Text";
import { CheckboxElement } from "../components/templates/CheckboxElement";
import { ColorSelectElement } from "../components/templates/ColorSelectElement";
import { FileUploadElement } from "../components/templates/FileUploadElement";
import { InputElement } from "../components/templates/InputElement";
import { SelectElement } from "../components/templates/SelectElement";
import { SubmitElement } from "../components/templates/SubmitElement";

export const FORM_ELEMENTS = {
  "text-input": InputElement,
  select: SelectElement,
  checkbox: CheckboxElement,
  file: FileUploadElement,
  recaptcha: RecaptchaInput,
  "color-select": ColorSelectElement,
  submit: SubmitElement,
};

export const FORM_LAYOUT_ELEMENTS = {
  "h-stack": ({ children, ...props }: { children: ReactNode }) => (
    <HStack children={children} align="flex-start" spacing={4} {...props} />
  ),
  header: ({ label }: ILayoutProps) => (
    <Text
      type="error"
      // color={error ? 'red.500' : 'gray.400'}
      pt={2}
      pb={2}
      fontWeight="bold"
      fontSize={14}
    >
      {label}
    </Text>
  ),
};
