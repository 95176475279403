import { VStack, Container } from "@chakra-ui/react";
import { PageGroup, Paginator } from "chakra-paginator";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Role } from "service/src/users/role.enum";

import { NotificationCard } from "../dashboard/NotificationCard";
import { LoadingPlaceholder } from "../elements/LoadingPlaceholder";
import { NonIdealState } from "../elements/NonIdealState";
import { PageHeader } from "../layout/PageHeader";

import { useOrganisationEvents } from "./useOrganisationEvents";

// const isLoading = true;
export function DashboardPage() {
  const [page, setPage] = useState<number>(1);
  const { data: organisationEvents, isLoading } = useOrganisationEvents(page);

  return (
    <Container maxW="container.md">
      {(isLoading || !isEmpty(organisationEvents?.items)) && (
        <PageHeader label="Dashboard" subtitle="View all your notifications" />
      )}
      <VStack w="100%" alignItems="flex-end" spacing={5}>
        <VStack spacing={4} w="100%">
          {!isLoading &&
            organisationEvents?.items?.map((event) => (
              <NotificationCard
                key={event.id}
                notification={event}
                isLoading={isLoading}
              />
            ))}
          <LoadingPlaceholder length={4} isLoading={isLoading}>
            <NotificationCard isLoading={true} />
          </LoadingPlaceholder>
        </VStack>

        {!isLoading && isEmpty(organisationEvents?.items) && (
          <NonIdealState
            pt={20}
            buttonIcon="cog"
            buttonLink="/products"
            buttonLevel={Role.Admin}
            buttonText="Setup your products"
            label={"You haven't received any notifications yet"}
          />
        )}
        {!isLoading &&
          !isEmpty(organisationEvents?.items) &&
          organisationEvents?.meta?.totalPages &&
          organisationEvents?.meta?.totalPages > 1 && (
            <Paginator
              pagesQuantity={organisationEvents?.meta.totalPages}
              currentPage={page}
              onPageChange={setPage}
              outerLimit={1}
              innerLimit={2}
              normalStyles={{
                bg: "gray.200",
                width: 10,
                borderRadius: 100,
              }}
              activeStyles={{
                colorScheme: "brand",
                width: 10,
                borderRadius: 100,
              }}
            >
              <PageGroup justifyContent="flex-end" mt={4} />
            </Paginator>
          )}
      </VStack>
    </Container>
  );
}
