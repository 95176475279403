import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  Box,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactNode } from "react";

import { ModalProps } from "../../interfaces/ModalProps";
import { Button } from "../elements/Button";
import { CardHeader } from "../elements/card/CardHeader";
import { Text } from "../elements/Text";

interface AlertModalProps extends ModalProps {
  buttonText: string;
  icon?: IconProp;
  title: ReactNode;
  body: ReactNode;
  isLoading: boolean;
  buttonColorScheme?: string;

  onConfirm: () => void;
}

export function AlertModal({
  onClose,
  isOpen,
  buttonText = "Confirm",
  body,
  onConfirm,
  buttonColorScheme = "red",
  title,
  icon,
  isLoading,
}: AlertModalProps) {
  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Box p={6} pb={3}>
            <CardHeader title={title} />
          </Box>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody>
            <Text type="body" mb={5}>
              {body}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter mt={3}>
            <Button
              color="gray.600"
              borderColor="gray.400"
              // colorScheme="gray"
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              icon={icon}
              ml={3}
              borderColor={`${buttonColorScheme}.500`}
              color={`${buttonColorScheme}.500`}
              isLoading={isLoading}
              onClick={onConfirm}
            >
              {buttonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
