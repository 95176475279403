import { useQuery } from "react-query";
import { OrganisationUserEntity } from "service/src/organisations/entities/organisation-user.entity";

import { get } from "../services/api";

import { useAuth } from "./useAuth";

export function useOrganisationUser(organisation?: string) {
  const { isAuthenticated } = useAuth();
  return useQuery<OrganisationUserEntity>(
    "organisations/user",
    async () => {
      const response = await get<OrganisationUserEntity>(
        `organisations/${organisation}/user`,
        {}
      );
      if (response.status !== 200) {
        return Promise.reject("Could not fetch organisation user");
      }

      return response.data;
    },
    {
      enabled: !!isAuthenticated && !!organisation,
    }
  );
}
