import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogCloseButton,
  Box,
} from "@chakra-ui/react";

import { ServiceType } from "../../enums/service-type";
import { UserServiceType } from "../../enums/user-service-type";
import { useOrganisationService } from "../../hooks/useOrganisationService";
import { useProductUpdate } from "../../hooks/useProductUpdate";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";

import { TwilioConfiguration } from "./configuration/TwilioConfiguration";

interface NewsModalProps extends ModalProps {
  serviceType: ServiceType | UserServiceType;
  serviceName: string;
}

export interface ServiceConfigurationProps {
  onClose: () => void;
  data?: Record<string, any>;
}

const SERVICE_CONFIGURATIONS: Record<
  ServiceType | UserServiceType,
  null | ((props: ServiceConfigurationProps) => JSX.Element)
> = {
  [ServiceType.twilio]: TwilioConfiguration,
  [ServiceType.email]: null,
  [ServiceType.push]: null,
  [ServiceType.slack]: null,
  [UserServiceType.sms]: null,
  [UserServiceType.voice]: null,
};

export function ServiceConfigurationModal({
  isOpen,
  serviceName,
  serviceType,
  onClose,
}: NewsModalProps) {
  const { isLoading } = useProductUpdate();
  const { data: service } = useOrganisationService({ type: serviceType });

  const ConfigurationComponent = SERVICE_CONFIGURATIONS[serviceType];
  if (!ConfigurationComponent) return null;

  // @ts-ignore
  const serviceData = service?.data as Record<string, any>;

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Box p={6} pb={3}>
            <CardHeader title={`${serviceName} configuration`} />
          </Box>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody pb={6}>
            <ConfigurationComponent onClose={onClose} data={serviceData} />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
