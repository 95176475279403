import { useMutation } from "react-query";

import { IApiResponse } from "../interfaces/IApiResponse";
import { post } from "../services/api";

import { useAuth } from "./useAuth";

type ILoginRequest = Record<string, string>;

interface ILoginResponse {
  token: string;
}

export function useLogin() {
  const { authorize } = useAuth();

  return useMutation<
    IApiResponse<ILoginResponse>,
    IApiResponse<ILoginResponse>,
    ILoginRequest
  >("auth", async (data: ILoginRequest) => {
    const response = await post<IApiResponse<ILoginResponse>>(
      "auth/login",
      data
    );

    if (response.status !== 201) {
      throw response.data.errors;
    }
    localStorage.setItem("access_token", response.data.token);

    await authorize();

    return response.data;
  });
}
