import { Box, BoxProps } from "@chakra-ui/layout";
import { keyframes } from "@chakra-ui/system";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";

interface ILoadingBackgroundProps extends BoxProps {
  children?: ReactNode;
  isTransparent?: boolean;
  isLoading?: boolean;
}

const loaderAnimation = keyframes`
0%{
  background-position: 200% 0
}
100%{
  background-position: 0 0
}
`;

export function LoadingBackground({
  children,

  isLoading,
  isTransparent,
  ...props
}: ILoadingBackgroundProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current?.offsetWidth,
        height: targetRef.current?.offsetHeight,
      });
    }
  }, [targetRef, setDimensions]);

  return (
    <Box
      position="relative"
      minH={6}
      opacity={isTransparent ? 0 : 1}
      ref={targetRef}
      w={isLoading ? "100%" : undefined}
      {...props}
    >
      {isLoading && (
        <Box
          position="absolute"
          h="100%"
          zIndex={100}
          w="100%"
          {...props}
          backgroundSize={`${dimensions.width * 2}px`}
          animation={`${loaderAnimation} infinite 1000ms linear forwards`}
          bgGradient="linear(to-r, gray.200 10%, gray.50 40%, gray.50 40%, gray.200 70%)"
        />
      )}
      <Box opacity={!isLoading ? 1 : 0}>{children}</Box>
    </Box>
  );
}
