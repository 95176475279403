export const ACCOUNT_FORM = [
  {
    type: "h-stack",
    children: [
      {
        type: "text-input",
        name: "firstName",
        label: "First name",
        required: true,
        minLength: 1,
        maxLength: 12,
      },
      {
        type: "text-input",
        name: "lastName",
        label: "Last name",
        required: true,
        minLength: 1,
        maxLength: 12,
      },
    ],
  },

  {
    type: "file",
    name: "newImage",
    label: "Avatar",
    minLength: 4,
    icon: "images",
  },

  {
    type: "text-input",
    name: "business",
    label: "Business",
    minLength: 2,
    icon: "briefcase",
    maxLength: 80,
  },

  {
    type: "text-input",
    name: "mobile",
    label: "Mobile number",
    inputType: "tel",
    helper: "Used for SMS Notifications and voice calls",
    minLength: 2,
    icon: "phone",
    maxLength: 16,
  },

  {
    type: "submit",
    label: "Save",
    name: "submit",
  },
];
