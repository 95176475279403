import { Role } from "service/src/users/role.enum";

export function getRoleColor(role?: Role) {
  switch (role) {
    case Role.Developer:
      return "purple";

    case Role.Admin:
      return "red";

    case Role.User:
    default:
      return "blue";
  }
}
