import { useMutation, useQueryClient } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { remove } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useOrganisations } from "./useOrganisations";

export function useOrganisationLeave() {
  const queryClient = useQueryClient();
  const { data: organisations } = useOrganisations();
  const { currentOrganisation, setCurrentOrganisation } =
    useCurrentOrganisation();

  return useMutation<null, null, null>(
    `organisations/${currentOrganisation?.id}/leave`,
    async () => {
      const response = await remove<OrganisationEntity>(
        `organisations/${currentOrganisation?.id}/leave`
      );

      if (response.status !== 200)
        return Promise.reject("Could not leave organisation");

      return null;
    },

    {
      onSuccess: () => {
        if (organisations) {
          queryClient.setQueryData(
            "organisations",
            organisations.filter(
              (organisation) => organisation.id === currentOrganisation?.id
            )
          );
        }

        setTimeout(() => {
          localStorage.removeItem("lastOrganisation");
          setCurrentOrganisation(null);
          queryClient.invalidateQueries("organisations");
        }, 100);
      },
    }
  );
}
