import { useQuery } from "react-query";
import { ServiceEntity } from "service/src/services/entities/service.entity";

import { ServiceType } from "../enums/service-type";
import { UserServiceType } from "../enums/user-service-type";
import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IOrganisationServiceRequest {
  type?: ServiceType | UserServiceType;
}

export function useOrganisationService({ type }: IOrganisationServiceRequest) {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<ServiceEntity>(
    ["organisation", currentOrganisation, "service", type],
    async () => {
      const response = await get<ServiceEntity>(
        `services/${currentOrganisation?.id}/service/${type}`,
        {}
      );

      if (response.status === 200) return response.data;
      return Promise.reject("Could not fetch service");
    },
    {
      enabled: !!currentOrganisation && !!type,
      keepPreviousData: true,
    }
  );
}
