import {
  FormControl,
  InputGroup,
  Select as RNSelect,
  InputLeftElement,
  Text,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { IElementProps } from "react-templator";

export function SelectElement({
  readOnly,
  label,
  error,
  cursor,
  disabled,
  name,
  onChange,
  min,
  max,
  type,
  onClick,
  options = [],
  inputRef,
  onFocus,
  onKeyDown,
  helperText,
  inputRightElement,
  onBlur,
  icon,
  value,
}: IElementProps & {
  options?: { label: string; value: string }[];
}) {
  return (
    <FormControl onClick={onClick} w="100%">
      <VStack alignItems="flex-start" spacing={2} w="100%" p={0}>
        <InputGroup>
          {icon ? <InputLeftElement pointerEvents="none" p={6} pl={6} /> : null}
          <RNSelect
            type={type}
            name={name}
            cursor={cursor}
            min={min}
            max={max}
            disabled={disabled}
            fontSize={14}
            onFocusCapture={onFocus}
            onKeyDown={onKeyDown}
            size="lg"
            readOnly={readOnly}
            colorScheme="brand"
            color={value ? "" : "gray.400"}
            _placeholder={{ color: "gray.300" }}
            _focus={{
              borderColor: error ? "red.500" : "brand.200",
            }}
            borderRadius={8}
            onChange={(event: any) => {
              if (onChange) onChange(event?.target?.value);
            }}
            onBlur={onBlur}
            value={value}
            isInvalid={error}
            ref={inputRef}
          >
            <option value="" selected={true}>
              {label}...
            </option>

            {options.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </RNSelect>
          {inputRightElement ? (
            <InputRightElement
              width="auto"
              alignItems="right"
              pointerEvents="none"
              p={4}
            >
              {inputRightElement}
            </InputRightElement>
          ) : null}
        </InputGroup>
      </VStack>
      {helperText || error ? (
        <Text
          type="error"
          color={error ? "red.500" : "gray.400"}
          pt={2}
          pb={2}
          fontSize={14}
        >
          {error ?? helperText}
        </Text>
      ) : null}
    </FormControl>
  );
}
