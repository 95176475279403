import { Box } from "@chakra-ui/react";
import { IElementProps } from "react-templator";

import { Button } from "../elements/Button";

export function SubmitElement({ isLarge, label, ...props }: IElementProps) {
  if (isLarge) {
    return (
      <Button p={7} mt={2} w="100%" type="submit" fontSize="md" {...props}>
        {label}
      </Button>
    );
  }

  return (
    <Box mt={6} textAlign="right" w="100%">
      <Button type="submit" icon="save" {...props}>
        {label}
      </Button>
    </Box>
  );
}
