import { Box, Container, HStack } from "@chakra-ui/layout";

import { useActiveSubscription } from "../hooks/useActiveSubscription";
import { useCurrentOrganisation } from "../hooks/useCurrentOrganisation";
import { useSubscriptionBlocked } from "../hooks/useSubscriptionBlocked";

import { Button } from "./elements/Button";
import { Link } from "./elements/Link";

export function SubscriptionExpiredBanner() {
  const { currentOrganisation } = useCurrentOrganisation();
  const { data: activeSubscription, isLoading } = useActiveSubscription();
  const { data: isBillingBlocked } = useSubscriptionBlocked();

  if (activeSubscription || isLoading || isBillingBlocked !== false) {
    return null;
  }

  return (
    <Box w="100%" backgroundColor="red.500" p={3.5}>
      <Container maxW="container.xl">
        <HStack justifyContent="space-between" alignItems="center">
          <Box>
            <Box as="span" fontWeight={700}>
              {currentOrganisation?.name}
            </Box>{" "}
            does not have an active subscription.
          </Box>
          <Link
            isLink={false}
            to="/organisation#subscriptions"
            textDecoration="none !important"
          >
            <Button
              layerStyle="white"
              _hover={{ backgroundColor: "red.600" }}
              _active={{ backgroundColor: "red.600" }}
              colorScheme="white"
            >
              Go to billing
            </Button>
          </Link>
        </HStack>
      </Container>
    </Box>
  );
}
