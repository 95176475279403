import { Link as ChakraLink, LinkProps, TextProps } from "@chakra-ui/react";
import { Link as DomLink } from "react-router-dom";

import { Text } from "./Text";

export interface ILink extends TextProps {
  to?: string;
  href?: string;
  isDisabled?: boolean;
  rel?: string;
  isLink?: boolean;
  target?: string;
  onClick?: () => void;
}
export function Link({
  to,
  rel,
  href,
  target,
  isLink = true,
  isDisabled,
  onClick,
  ...props
}: ILink) {
  if (isDisabled || onClick) {
    return (
      <Text
        color={isLink ? "brand.700" : undefined}
        cursor="pointer"
        onClick={isDisabled ? undefined : onClick}
        {...props}
      />
    );
  }

  return (
    // @ts-ignore
    <ChakraLink
      to={to}
      target={target}
      rel={rel}
      href={href}
      as={DomLink}
      color={isLink ? "brand.200" : undefined}
      {...props}
    />
  );
}
