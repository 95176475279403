import { useMutation, useQueryClient } from "react-query";

import { Role } from "../components/users/UserCard";
import { put } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IOrganisationUserUpgrade {
  role: Role;
  userId: string;
}
export function useOrganisationUserUpdate() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<null, null, IOrganisationUserUpgrade>(
    "organisation/user/upgrade",
    async (data) => {
      const response = await put(
        `organisations/${currentOrganisation?.id}/user/upgrade`,
        {
          ...data,
        }
      );

      if (response.status !== 200)
        return Promise.reject("Could not update user");

      return null;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation?.id,
          "users",
        ]);
      },
    }
  );
}
