import {
  Button as RNButton,
  Spinner,
  ButtonProps as RNButtonProps,
  IconProps,
  Box,
  TextProps,
  Tooltip,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Icon } from "./Icon";
import { Text } from "./Text";

interface ButtonProps extends RNButtonProps {
  p?: number;
  color?: string;
  icon?: IconProp;
  iconSize?: number;
  isLoading?: boolean;
  colorScheme?: string;
  tooltip?: string;
  iconProps?: IconProps;
  onClick?: () => void;
  to?: string;
  labelProps?: TextProps;
}

export function Button({
  p = 5,
  icon,
  disabled,
  tooltip,
  children,
  colorScheme = "blue",
  isLoading,
  iconProps,
  className,
  labelProps,
  iconSize = 14,
  ...props
}: ButtonProps) {
  return (
    <Tooltip
      label={tooltip}
      isDisabled={false}
      shouldWrapChildren={true}
      px={3}
      py={1}
      backgroundColor={`${colorScheme}.600`}
    >
      <RNButton
        p={p}
        px={p}
        py={p / 2}
        radius={500}
        fontSize={14}
        borderRadius={50}
        height="auto"
        fontWeight={500}
        layerStyle="red"
        border="2px solid"
        textDecoration="none"
        color={`${colorScheme}.500`}
        background="transparent"
        borderColor={`${colorScheme}.500`}
        alignItems="center"
        justifyContent="center"
        className={className}
        disabled={disabled || isLoading}
        {...props}
      >
        <>
          <Text
            fontSize={14}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            opacity={isLoading ? 0 : 1}
            {...labelProps}
          >
            {icon ? (
              <Box display="inline-block">
                <Icon mr={4} icon={icon} size={iconSize} {...iconProps} />
              </Box>
            ) : null}
            {children}
          </Text>
          <Text position="absolute">
            {isLoading ? <Spinner size="sm" /> : null}
          </Text>
        </>
      </RNButton>
    </Tooltip>
  );
}
