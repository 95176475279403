import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogCloseButton,
  useToast,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { Form } from "react-templator";

import { ACCOUNT_FORM } from "../../forms/auth/account-form";
import { useAccountUpdate } from "../../hooks/useAccountUpdate";
import { useMe } from "../../hooks/useMe";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";
import { Icon } from "../elements/Icon";

interface CreateOrganisationModalProps extends ModalProps {
  hasBack?: boolean;
}
export function EditAccountModal({
  isOpen,
  hasBack,
  onClose,
}: CreateOrganisationModalProps) {
  const { mutate: updateAccount, isLoading } = useAccountUpdate();
  const { data: account } = useMe();
  const toast = useToast();

  function handleOnSubmit(data: Record<string, string>, setErrors: any) {
    updateAccount(data, {
      onSuccess: () => {
        toast({
          title: "Account updated",
          status: "success",
          isClosable: true,
        });

        onClose();
      },
      onError: (errors) => setErrors(errors),
    });
  }

  if (!account) return null;

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <HStack p={6} pb={3} justifyContent="space-between">
            <HStack>
              {hasBack ? (
                <IconButton
                  size="lg"
                  color="black"
                  onClick={() => !isLoading && onClose()}
                  p={2}
                  bg="transparent"
                  icon={<Icon icon="arrow-left" />}
                  aria-label="Open Menu"
                />
              ) : null}
              <CardHeader title="Account" />
            </HStack>
            {!isLoading && !hasBack ? <AlertDialogCloseButton /> : null}
          </HStack>

          <AlertDialogBody pb={6}>
            <Form
              schema={ACCOUNT_FORM}
              defaultValues={account}
              dynamicProps={{
                submit: {
                  isLoading,
                },
                newImage: {
                  hasFile: !!account?.avatar,
                },
              }}
              onSubmit={(data, setErrors) => {
                handleOnSubmit(data, setErrors);
                // handleSubmit(data, setErrors);
                // Called when the form has submitted and all the local validation has passed
                // alert('Form submitted! Data:', JSON.stringify(data));
              }}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
