import { Box, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

import { ISidebarSection } from "../../interfaces/ISidebarSection";
import { AuthGuard } from "../AuthGuard";

import { SidebarButton } from "./SidebarButton";
import { SidebarSection } from "./SidebarSection";

export function SidebarContent({
  items,
  header,
}: {
  items: ISidebarSection[];
  header?: ReactNode;
}) {
  return (
    <>
      {header}
      <VStack p={5}>
        {items.map(({ label, pages }) => (
          <SidebarSection label={label} key={label}>
            {pages.map(({ icon, href, role, label }) => (
              <AuthGuard role={role} key={href}>
                <SidebarButton icon={icon} href={href}>
                  {label}
                </SidebarButton>
              </AuthGuard>
            ))}
            <Box minHeight={5} />
          </SidebarSection>
        ))}
      </VStack>
    </>
  );
}
