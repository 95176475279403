export const ORGANISATION_FORM = [
  {
    type: "text-input",
    name: "name",
    label: "Name",
    required: true,
    minLength: 2,
    icon: "user",
    helper: "The name of your organisation",
    maxLength: 64,
  },

  {
    type: "file",
    name: "newImage",
    label: "Avatar",
    helper: "Your organisations avatar (square)",
    minLength: 4,
    icon: "images",
  },

  {
    type: "submit",
    label: "Save",
    name: "submit",
  },
];
