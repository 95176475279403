import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  Center,
  Container,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";
import Swal from "sweetalert2";

import { useAuth } from "../../hooks/useAuth";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useJoinOrganisation } from "../../hooks/useJoinOrganisation";
import { useOrganisationValidInvite } from "../../hooks/useOrganisationValidInvite";
import { useTheme } from "../../hooks/useTheme";
import { Button } from "../elements/Button";
import { CardHeader } from "../elements/card/CardHeader";
import { OrganisationSelectCard } from "../organisation/OrganisationSelectCard";

export function AcceptInvitePage() {
  const { isDarkMode } = useTheme();
  const { inviteCode } = useParams<{ inviteCode: string }>();

  const { isAuthenticated } = useAuth();
  const { push } = useHistory();
  const [organisation, setOrganisation] = useState<
    OrganisationEntity | undefined
  >();
  const { mutateAsync: checkInvite } = useOrganisationValidInvite();
  const { mutate: joinOrganisation, isLoading } = useJoinOrganisation();
  const { setCurrentOrganisation } = useCurrentOrganisation();

  async function handleSubmit() {
    joinOrganisation(
      { code: inviteCode },
      {
        onSuccess: () => {
          setCurrentOrganisation(null);
          push("/");
        },
        onError: async (error: string) => {
          await Swal.fire({
            icon: "error",
            title: "An error occurred",
            text: error,
          }).then(() => {
            push("/");
          });
        },
      }
    );
  }

  useEffect(() => {
    setCurrentOrganisation(null);
    if (isAuthenticated === null) return;

    (async () => {
      if (!inviteCode) return;

      const isValidInvite = await checkInvite(inviteCode);
      if (isValidInvite) setOrganisation(isValidInvite.organisation);
      if (!isValidInvite.valid) {
        await Swal.fire({
          icon: "error",
          title: "Invite code not found",
          text: "The invite code was not found or is invalid",
        }).then(() => {
          push("/");
        });

        return;
      }

      if (isAuthenticated === false) {
        localStorage.setItem("inviteCode", inviteCode);
        push("/register");
      }
    })();
  }, [isAuthenticated, setCurrentOrganisation, inviteCode, checkInvite, push]);

  function handleCancel() {
    push("/");
  }

  return (
    <Box
      position="absolute"
      w="100%"
      top={0}
      bg={isDarkMode ? "brand.900" : "brand.400"}
      bgGradient="linear(to-tr, brand.100, brand.600)"
    >
      <Container maxW="container.sm">
        <Center minH="100vh" pt="2rem" pb="2rem ">
          <Box zIndex={-1}>
            <AlertDialog
              leastDestructiveRef={undefined}
              motionPreset="slideInBottom"
              onClose={() => {}}
              closeOnEsc={false}
              isOpen={!!organisation}
              size="xl"
              isCentered={true}
            >
              <AlertDialogOverlay zIndex={1} />
              <AlertDialogContent zIndex={0}>
                <VStack p={6} pb={3}>
                  <CardHeader title={`Join ${organisation?.name}`} />
                </VStack>
                <Box mb={5} px={6} w="100%">
                  <Text textAlign="center">
                    You've been invited to join {organisation?.name} on
                    Notiversal!
                  </Text>
                </Box>

                {organisation && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    px={5}
                    pb={4}
                  >
                    <Box w={["100%", "50%"]}>
                      <OrganisationSelectCard
                        disabled={true}
                        organisation={organisation}
                      />
                    </Box>
                  </Box>
                )}

                <AlertDialogBody pb={6}>
                  <HStack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      colorScheme="red"
                      disabled={isLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="brand"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Join {organisation?.name}
                    </Button>
                  </HStack>
                </AlertDialogBody>
              </AlertDialogContent>
            </AlertDialog>
          </Box>
        </Center>
      </Container>
    </Box>
  );
}
