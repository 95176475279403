// export const BUSINESS_PRODUCT_PRICE = ""
// export const PLUS_PRODUCT_MONTHLY_PRICE = 'price_1J47utJq3cbVcQsGIYy9dOcp';
// export const PREMIUM_PRODUCT_MONTHLY_PRICE = 'price_1J47vGJq3cbVcQsGM9kGyP63';
import * as dotenv from "dotenv";

dotenv.config();

export enum SubscriptionStatus {
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  trialing = "trialing",
  active = "active",
  past_due = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
}

export enum Product {
  individual = "individual",
  team = "team",
  business = "business",
}

export interface ProductData {
  name: Product;
  id: string;
  price: string;
  limits: {
    maxTeamMembers: number;
  };
}

// TODO: move to const folder
export const PRODUCTS: Record<Product, ProductData> = {
  [Product.individual]: {
    name: Product.individual,
    id: process.env.STRIPE_NOTIVERSAL_PRODUCT ?? "",
    price: process.env.STRIPE_INDIVIDUAL_PRICE ?? "",
    limits: {
      maxTeamMembers: 1,
    },
  },
  [Product.team]: {
    name: Product.team,
    id: process.env.STRIPE_NOTIVERSAL_PRODUCT ?? "",
    price: process.env.STRIPE_TEAM_PRICE ?? "",
    limits: {
      maxTeamMembers: 5,
    },
  },
  [Product.business]: {
    name: Product.business,
    id: process.env.STRIPE_NOTIVERSAL_PRODUCT ?? "",
    price: process.env.STRIPE_BUSINESS_PRICE ?? "",
    limits: {
      maxTeamMembers: 15,
    },
  },
};

export interface OrganisationRestrictions {
  canAddTeamMember: boolean;
}

export function getProductFromStripeId(stripeId: string) {
  return Object.values(PRODUCTS).find(({ id }) => id === stripeId);
}

export function getProductFromStripePrice(priceId: string) {
  return Object.values(PRODUCTS).find(({ price }) => price === priceId);
}

export function getProductFromName(productName: string) {
  return Object.values(PRODUCTS).find(({ name }) => name === productName);
}
