import { Box, Container, SimpleGrid, VStack } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { ServiceType } from "../../enums/service-type";
import { UserServiceType } from "../../enums/user-service-type";
import { useOrganisationServices } from "../../hooks/useOrganisationServices";
import {
  ServiceStatus,
  useServiceStatuses,
} from "../../hooks/useServiceStatuses";
import { AuthGuard } from "../AuthGuard";
import { LoadingPlaceholder } from "../elements/LoadingPlaceholder";
import { PageHeader } from "../layout/PageHeader";
import { ProductCard } from "../news/ProductCard";
import { EmailCard } from "../services/content/EmailCard";
import { PushCard } from "../services/content/PushCard";
import { SmsCard } from "../services/content/SmsCard";
import { ServiceCard } from "../services/ServiceCard";
import { Role } from "../users/UserCard";

export interface IService {
  label: string;
  isDisabled?: boolean | (() => boolean);
  type: UserServiceType;
  image: string;
  component?: ({
    serviceStatus,
  }: {
    serviceStatus?: ServiceStatus;
  }) => JSX.Element;
  isDefault?: boolean;
}

export interface IOrganisationService {
  label: string;
  isDisabled: boolean;
  type: ServiceType;
  component?: ({
    serviceStatus,
  }: {
    serviceStatus?: ServiceStatus;
  }) => JSX.Element;
  image: string;
  isDefault?: boolean;
}

const organisationConfigurationServices: IOrganisationService[] = [
  {
    label: "Twilio",
    type: ServiceType.twilio,
    image: "/img/services/twilio.png",
    isDisabled: true,
  },
];

const SERVICES: IService[] = [
  {
    label: "Email notifications",
    type: UserServiceType.email,
    image: "/img/services/email.svg",
    component: EmailCard,
    isDefault: true,
  },
  {
    label: "Push notifications",
    type: UserServiceType.push,
    isDisabled: () => Notification.permission !== "granted",
    component: PushCard,

    image: "/img/services/push.svg",
    isDefault: true,
  },

  {
    label: "Voice call",
    type: UserServiceType.voice,

    component: SmsCard,
    image: "/img/services/phone.svg",
    isDefault: true,
  },
  {
    label: "SMS",
    type: UserServiceType.sms,

    component: SmsCard,
    image: "/img/services/sms.svg",
    isDefault: true,
  },
];

export function ServicesPage() {
  const { data: organisationServices } = useOrganisationServices();
  const { data: serviceStatuses } = useServiceStatuses();
  const [isPreloading, setIsPreloading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await Promise.all(
        SERVICES.map(({ image }) => image).map(
          (rnImage: string) =>
            new Promise<void>((res) => {
              const image = new Image();
              image.onload = () => res();
              image.onerror = () => res();

              image.src = rnImage;
            })
        )
      );

      setIsPreloading(false);
    })();
  }, []);

  // if (!organisationServices || !serviceStatuses) return null;

  const isLoading = !organisationServices || isPreloading || !serviceStatuses;
  return (
    <Container maxW="container.xl">
      <PageHeader
        label="Services"
        subtitle="Configure your notifications and call settings"
      />

      <Box>
        <VStack p={0} spacing={5} justifyContent="flex-start" w="100%">
          <VStack spacing={4} w="100%">
            <Box w="100%">
              <SimpleGrid columns={[1, 2, 3]} spacing="20px" mb={5} w="100%">
                <LoadingPlaceholder length={4} isLoading={isLoading}>
                  <ProductCard isLoading={true} />
                </LoadingPlaceholder>
                {!isLoading && serviceStatuses && SERVICES
                  ? SERVICES.map((service) => (
                      <ServiceCard
                        key={service.label}
                        item={service}
                        isDisabled={
                          !serviceStatuses[service.type]?.enabled ||
                          service.isDisabled
                        }
                        serviceStatus={serviceStatuses[service.type]}
                        isOrganisationDisabled={
                          serviceStatuses[service.type]?.organisationDisabled
                        }
                      />
                    ))
                  : null}
              </SimpleGrid>
            </Box>
          </VStack>
          {!isLoading && organisationServices && (
            <AuthGuard role={Role.Developer}>
              <Box w="100%">
                <PageHeader
                  label="Organisation settings"
                  isSubheader={true}
                  subtitle="Configure specific services for your organisation"
                />
                {!isEmpty(organisationConfigurationServices) ? (
                  <VStack spacing={4} w="100%">
                    <SimpleGrid columns={[1, 3, 3]} spacing="20px" w="100%">
                      {organisationConfigurationServices
                        ? organisationConfigurationServices.map((service) => (
                            <ServiceCard
                              key={service.label}
                              item={service}
                              isDisabled={!organisationServices[service.type]}
                            />
                          ))
                        : null}
                    </SimpleGrid>
                  </VStack>
                ) : null}
              </Box>
            </AuthGuard>
          )}
        </VStack>
      </Box>
    </Container>
  );
}
