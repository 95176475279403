import { useToast } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export interface InvoiceResponse {
  access_token: string;
  message: string;
}

export function useSubscriptionBlock() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  const toast = useToast();

  return useMutation<boolean, null>(
    "auth",
    async () => {
      localStorage.setItem(
        `block_billing_${currentOrganisation?.id}`,
        dayjs().add(20, "second").toISOString()
      );

      await new Promise((res) => setTimeout(res, 50));

      return true;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "billing/blocked",
          currentOrganisation?.id,
        ]);

        toast({
          title:
            "Please allow a few seconds for your subscription to update...",
          status: "success",
          isClosable: true,
        });
      },
    }
  );
}
