import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogCloseButton,
  Box,
  Select,
  HStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { OrganisationUserResponse } from "service/src/organisations/response/organisation-user.response";

import { useAlert } from "../../hooks/useAlert";
import { useOrganisationUserRemove } from "../../hooks/useOrganisationUserRemove";
import { useOrganisationUserUpdate } from "../../hooks/useOrganisationUserUpgrade";
import { useProductUpdate } from "../../hooks/useProductUpdate";
import { ModalProps } from "../../interfaces/ModalProps";
import { Button } from "../elements/Button";
import { CardHeader } from "../elements/card/CardHeader";
import { Role } from "../users/UserCard";

interface ModifyUserModalProps extends ModalProps {
  user: Partial<OrganisationUserResponse>;
}

export function ModifyUserModal({
  isOpen,
  user,
  onClose,
}: ModifyUserModalProps) {
  const { isLoading } = useProductUpdate();

  const { mutate: upgradeUser } = useOrganisationUserUpdate();
  const { mutate: removeUser } = useOrganisationUserRemove();
  const { setIsLoading, alert, closeAlert } = useAlert();

  const [userRank, setUserRank] = useState<Role>(user?.role || Role.User);

  function handleOnSubmit() {
    if (!userRank) return;

    upgradeUser(
      { userId: user.id ?? "", role: userRank },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  }

  function handleRemove() {
    alert({
      label: "Confirm removal",
      text: `Are you sure you wish to remove ${user.firstName} from your organisation?`,
      confirmText: "Delete",
      onConfirm: () => {
        setIsLoading(true);
        removeUser(
          { userId: user.id ?? "" },
          {
            onSuccess: () => {
              closeAlert();
              onClose();
            },
            onSettled: () => setIsLoading(false),
          }
        );
      },
    });
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Box p={6} pb={3}>
            <CardHeader title="Modify user" />
          </Box>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody pb={6}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleOnSubmit();
              }}
            >
              <Select
                placeholder="Select option"
                name="rank"
                value={userRank}
                onChange={(event) => {
                  const rank = event.target.value as Role;
                  setUserRank(rank);
                }}
              >
                <option value={Role.User}>User</option>
                <option value={Role.Developer}>Developer</option>
                <option value={Role.Admin}>Admin</option>
              </Select>
              <HStack
                justifyContent="space-between"
                pt={6}
                textAlign="right"
                w="100%"
              >
                <Button colorScheme="red" icon="times" onClick={handleRemove}>
                  Remove user
                </Button>
                <Button colorScheme="brand" type="submit" icon="save">
                  Save
                </Button>
              </HStack>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
