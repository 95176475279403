import { Box, Wrap, WrapItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { Form } from "react-templator";

import { ORGANISATION_FORM } from "../../../forms/auth/organisation-form";
import { useCurrentOrganisation } from "../../../hooks/useCurrentOrganisation";
import { useMe } from "../../../hooks/useMe";
import { useOrganisationUpdate } from "../../../hooks/useOrganisationUpdate";
import { Card } from "../../elements/card/Card";
import { CardHeader } from "../../elements/card/CardHeader";

export function OrganisationSettingsTab() {
  const { data: me } = useMe();
  const { currentOrganisation } = useCurrentOrganisation();
  const { mutate: updateOrganisation, isLoading } = useOrganisationUpdate();

  const toast = useToast();

  function handleSubmit(data: Record<string, string>) {
    updateOrganisation(data, {
      onSuccess: () => {
        toast({
          title: "Organisation settings saved",
          status: "success",
          isClosable: true,
        });
      },
    });
  }

  return (
    <Box flex={1} display="flex" p={0}>
      <Wrap minChildWidth="120px" spacing="15px">
        <WrapItem>
          <Card pb={6} maxW="100%" width="600px" boxShadow="sm">
            <CardHeader
              title="Organisation settings"
              label="Modify your organisation settings"
            />

            {me ? (
              <Form
                defaultValues={currentOrganisation || undefined}
                schema={ORGANISATION_FORM}
                dynamicProps={{
                  newImage: {
                    hasFile: !!currentOrganisation?.avatar,
                  },
                  submit: {
                    isLoading,
                  },
                }}
                onSubmit={!isLoading ? handleSubmit : () => {}}
                data={me}
              />
            ) : null}
          </Card>
        </WrapItem>
      </Wrap>

      {/* <SubscriptionPage /> */}
    </Box>
  );
}
