import { Box, BoxProps, Text } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Role } from "service/src/users/role.enum";

import { AuthGuard } from "../AuthGuard";

import { Button } from "./Button";
import { Link } from "./Link";

interface NonIdealStateProps extends BoxProps {
  label: string;
  buttonText?: string;
  buttonIcon?: IconProp;
  buttonLink?: string;
  buttonLevel?: Role;
  mt?: string | number;
  onClick?: () => void;
}

export function NonIdealState({
  label,
  buttonText,
  buttonLink,
  buttonIcon,
  buttonLevel,
  onClick,
  ...props
}: NonIdealStateProps) {
  return (
    <Box pt={6} textAlign="center" w="100%" {...props}>
      <Text fontSize={28} color="gray.500" fontWeight={400}>
        {label}
      </Text>
      <AuthGuard role={buttonLevel}>
        {buttonText ? (
          <Link isLink={false} to={buttonLink} textDecoration="none !important">
            <Button
              mt={8}
              icon={buttonIcon}
              onClick={onClick}
              textDecoration="none"
              to={buttonLink}
            >
              {buttonText}
            </Button>
          </Link>
        ) : null}
      </AuthGuard>
    </Box>
  );
}
