import { Checkbox } from "@chakra-ui/react";
import { IElementProps } from "react-templator";

import { Text } from "../elements/Text";

export function CheckboxElement({
  value,
  name,
  label,
  onChange,
}: IElementProps) {
  return (
    <Checkbox
      ml={3}
      mb={5}
      colorScheme="brand"
      size="lg"
      onChange={() => onChange(!value)}
      name={name}
      isChecked={!!value}
    >
      <Text ml={2} color="gray.600">
        {label}
      </Text>
    </Checkbox>
  );
}
