import { useQuery } from "react-query";
import { ProductsResponse } from "service/src/products/response/products.response";
import { ISubscription } from "service/src/subscription/interface/ISubscription";
import { ProductInterface } from "service/src/subscription/interface/ProductInterface";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useSubscriptionProducts() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<ProductInterface[]>(
    ["subscriptions", currentOrganisation?.id],
    async () => {
      const response = await get<ProductInterface[]>(
        `subscriptions/products/${currentOrganisation?.id}`,
        {}
      );

      if (response.status === 200) return response.data;
      return Promise.reject("Could not fetch products");
    },
    {
      enabled: !!currentOrganisation,
      keepPreviousData: true,
    }
  );
}
