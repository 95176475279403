import { Container, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSubscriptionBlock } from "../../hooks/useSubscriptionBlock";
import { Tabs } from "../elements/Tabs";
import { PageHeader } from "../layout/PageHeader";

import { InvoicesPage } from "./billing/InvoicesPage";
import { OrganisationSettingsTab } from "./billing/OrganisationSettingsTab";
import { SubscriptionPage } from "./billing/SubscriptionsTab";

export function OrganisationPage() {
  const { push } = useHistory();
  const { search } = useLocation();
  const { mutate: blockBilling } = useSubscriptionBlock();

  useEffect(() => {
    if (new URLSearchParams(search).get("success")) {
      blockBilling();
      push("/organisation#subscriptions");
    }
  }, [search, blockBilling, push]);

  return (
    <Container maxW="container.xl">
      <PageHeader
        label="Organisation"
        subtitle="Modify your organisation settings"
      />

      <VStack w="100%">
        <Tabs
          tabs={[
            {
              label: "Settings",
              icon: "cog",
              render: () => <OrganisationSettingsTab />,
            },
            {
              label: "Subscriptions",
              icon: "credit-card",
              render: () => <SubscriptionPage />,
            },

            {
              label: "Invoices",
              icon: "file-invoice",
              render: () => <InvoicesPage />,
            },
          ]}
        />
      </VStack>
    </Container>
  );
}
