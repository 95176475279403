export const LOGIN_FORM = [
  {
    type: "text-input",
    name: "email",
    label: "Email",
    inputType: "email",
    required: true,
    minLength: 4,
    icon: "user",
    maxLength: 80,
  },
  {
    type: "text-input",
    name: "password",
    inputType: "password",
    label: "Password",
    icon: "lock",
    required: true,
    minLength: 4,
    maxLength: 80,
  },
  {
    type: "submit",
    isLarge: true,
    label: "Login",
    name: "submit",
  },
];
