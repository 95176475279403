import { useMutation } from "react-query";

import { IApiResponse } from "../interfaces/IApiResponse";
import { post } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export interface ICheckoutResponse {
  session: string;
}

type ICheckoutRequest = Record<string, string>;

export function useCheckout() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<IApiResponse<ICheckoutResponse>, null, ICheckoutRequest>(
    ["checkout", currentOrganisation?.id],
    async (data) => {
      const response = await post<IApiResponse<ICheckoutResponse>>(
        `subscriptions/${currentOrganisation?.id}/checkout`,
        data
      );

      return response.data;
    }
  );
}
