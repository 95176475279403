import { useMutation, useQueryClient } from "react-query";
import { Product } from "service/src/util/subscription";

import { post } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useSubscriptionBlock } from "./useSubscriptionBlock";

interface ICheckoutRequest {
  product: Product;
}

export function useSubscriptionUpgrade() {
  const queryClient = useQueryClient();
  const { mutate: blockBilling } = useSubscriptionBlock();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<boolean, null, ICheckoutRequest>(
    "checkout",
    async (data: ICheckoutRequest) => {
      const response = await post<null>(
        `subscriptions/${currentOrganisation?.id}/upgrade`,
        data
      );
      return response.status === 200;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries("subscriptions/active");
        blockBilling();
      },
    }
  );
}
