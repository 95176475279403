import { UserServiceType } from "./enums/user-service-type";
import { post } from "./services/api";

const publicVapidKey =
  "BKAVY1Dfe_45a0dwQlZ4cmPjw7wSkBFJ04LHox2YvPA_GZFUakVidLrJ9ts8Caa279iEYvaXgr5UiOUqPilE5Qc";

// if ("serviceWorker" in navigator) {
//   send().catch((err) => console.error(err));
// }

function urlB64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

let isSubscribed = false;

function updateBtn() {
  if (Notification.permission === "denied") {
    console.log("Push Messaging Blocked.");
    updateSubscriptionOnServer(null);
  }
}

function updateSubscriptionOnServer(subscription: any) {
  // TODO: Send subscription to application server
  // post("services/user-service", {})

  post("services/user-service", {
    type: UserServiceType.push,
    data: subscription,
  });
  //   const subscriptionJson = document.querySelector(".js-subscription-json");
  //   const subscriptionDetails = document.querySelector(
  //     ".js-subscription-details"
  //   );

  //   if (subscription) {
  //     subscriptionJson.textContent = JSON.stringify(subscription);
  //     subscriptionDetails.classList.remove("is-invisible");
  //   } else {
  //     subscriptionDetails.classList.add("is-invisible");
  //   }
}

let swRegistration: any = null;

function subscribeUser() {
  console.log("NOTIF PERMISSION IS", Notification.permission);
  if (Notification.permission === "granted") {
    const applicationServerKey = urlB64ToUint8Array(publicVapidKey);
    swRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      })
      .then((subscription: any) => {
        console.log("User is subscribed.", subscription);
        updateSubscriptionOnServer(subscription);
        isSubscribed = true;
        updateBtn();
      })
      .catch((err: any) => {
        console.log("Failed to subscribe the user: ", err);
        updateBtn();
      });
  }
}

function initializeUI() {
  subscribeUser();
  //   pushButton.addEventListener("click", function () {
  //     pushButton.disabled = true;
  //     if (isSubscribed) {
  //       unsubscribeUser();
  //     } else {
  //       subscribeUser();
  //     }
  //   });

  // Set the initial subscription value
  swRegistration.pushManager.getSubscription().then((subscription: any) => {
    isSubscribed = !(subscription === null);

    updateSubscriptionOnServer(subscription);

    if (isSubscribed) {
      console.log("User IS subscribed.");
    } else {
      console.log("User is not subscribed.");
    }

    updateBtn();
  });
}

if ("serviceWorker" in navigator && "PushManager" in window) {
  console.log("Service Worker and Push is supported");

  navigator.serviceWorker
    .register("service-worker.js")
    .then((swReg) => {
      console.log("Service Worker is registered", swReg);

      swRegistration = swReg;
      initializeUI();
    })
    .catch((error) => {
      console.error("Service Worker Error", error);
    });
} else {
  console.warn("Push messaging is not supported");
}
