import { HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-templator";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";
import Swal from "sweetalert2";

import { REGISTER_FORM } from "../../forms/auth/register-form";
import { useOrganisationValidInvite } from "../../hooks/useOrganisationValidInvite";
import { useRegister } from "../../hooks/useRegister";
import { Card } from "../elements/card/Card";
import { CardHeader } from "../elements/card/CardHeader";
import { Link } from "../elements/Link";
import { Text } from "../elements/Text";

export function RegisterForm() {
  const { push } = useHistory();
  const { mutate: register, isLoading } = useRegister();
  const { mutateAsync: checkInvite } = useOrganisationValidInvite();

  const [hasInviteOrganisation, setHasInviteOrganisation] = useState<
    OrganisationEntity | undefined
  >();

  useEffect(() => {
    (async () => {
      const code = localStorage.getItem("inviteCode");
      if (code) {
        const isValidInvite = await checkInvite(code);
        if (!isValidInvite) localStorage.removeItem("inviteCode");

        setHasInviteOrganisation(isValidInvite.organisation);
      }
    })();
  }, [checkInvite]);

  async function handleSubmit(data: Record<string, string>, setErrors: any) {
    const inviteCode = localStorage.getItem("inviteCode");

    register(
      { ...data, ...(inviteCode ? { inviteCode } : {}) },
      {
        onSuccess: async () => {
          localStorage.removeItem("inviteCode");

          await Swal.fire({
            icon: "success",
            title: "Registered!",
            text: "Check your email for a confirmation email to get started!",
          });

          push("/login");
        },
        onError: (errors) => {
          setErrors(errors);
        },
      }
    );
  }

  return (
    <>
      <Card pb={80} p={{ base: 8, md: 12 }} py={{ base: 10, md: 12 }}>
        <CardHeader
          title={hasInviteOrganisation ? "Welcome to Notiversal!" : "Sign up"}
        />
        <Text type="body" mb={8} mt={2}>
          {hasInviteOrganisation
            ? `You've been invited to join ${hasInviteOrganisation.name} on Notiversal! Please create an account below, or login if you already have one.`
            : "Welcome to Notiversal! Universal notifications for internal teams."}
        </Text>

        <Form
          schema={REGISTER_FORM}
          dynamicProps={{
            submit: {
              isLoading,
            },
          }}
          onSubmit={handleSubmit}
        />
      </Card>
      <HStack justifyContent="center">
        <Text color="white" mt={5}>
          Already have an account?{" "}
          <Link color="white" fontWeight="bold" to="/login">
            Login
          </Link>
        </Text>
      </HStack>
    </>
  );
}
