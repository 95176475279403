import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Icon } from "./Icon";
import { Link } from "./Link";

interface NavbarButtonProps {
  href?: string;
  icon?: IconProp;
  children?: string;
}

export function NavbarButton({ children, href, icon }: NavbarButtonProps) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(
      `/${location.pathname.replace(/(\/[^/]*).*/, "$1").substr(1)}` === href
    );
  }, [location.pathname, href]);

  return (
    <Link
      px={5}
      h="100%"
      alignItems="center"
      display="flex"
      opacity={isActive ? 1 : 0.9}
      // color="white"
      textDecor="none"
      _hover={{ textDecor: "none" }}
      to={href}
      fontSize="sm"
      // color={"white"}
      // textDecoration="none"
      fontWeight={400}
    >
      <HStack
        spacing={2}
        // bg={isActive ? "brand.200" : undefined}
        px="8px"
        py="8px"
        borderRadius={400}
        color={useColorModeValue("white", isActive ? "white" : "gray.200")}
      >
        {icon ? <Icon icon={icon} /> : null}
        <Box key={href}>{children}</Box>
      </HStack>
    </Link>
  );
}
