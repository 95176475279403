import { Box, BoxProps } from "@chakra-ui/react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import "react-syntax-highlighter/dist/esm/languages/hljs/python";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";

interface CodeProps extends BoxProps {
  language: string;
}

export function Code({ children, language }: CodeProps) {
  return (
    <Box w="100%" borderRadius={10} overflow="hidden">
      <SyntaxHighlighter language={language} style={docco}>
        {children}
      </SyntaxHighlighter>
    </Box>
  );
}
