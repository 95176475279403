interface ICodeGenerator {
  apiKey: string;
}

export interface CodeExample {
  label: string;
  language: string;
  code: (data: ICodeGenerator) => string;
}

export function generateApiUrl(): string {
  return `${process.env.REACT_APP_API_URL}notify`;
}

export function generateTestCode() {
  return `{
    // Unique identifier of your notification 
    // string a-z, 0-9, -, _, and space only
    event: "your-event",
    
    // Title of your notification (string)
    title: "Example event",
    
    // Body of your notification (string)
    subtitle: "A new user has joined the platform"
    
    // actions (array of objects, maximum of 2)
    actions: [
      {
        label: "Your action",
        link: "https://yoursite.com"
      }
    ]
}`;
}

export function generateCurlCode({ apiKey }: ICodeGenerator) {
  return `curl -XPOST \\
-H 'api-key: ${apiKey}' \\
-H "Content-type: application/json" \\
-d '{"event": "new-user", "title": "Example event", "subtitle": "A new user joined the platform", "body": "This is some more elaborate text in markdown format.", "actions": [{"label": "View user", "link": "https://notiversal.com"}]}' \\
'${generateApiUrl()}'`;
}

export function generatePythonCode({ apiKey }: ICodeGenerator) {
  return `import requests

payload = {
    'event': 'new-user',
    'title': 'Example event',
    'subtitle': 'A new user joined the platform',
    'actions': [
      {
        label: 'View user',
        link: 'https://notiversal.com'
      }
    ]
}
headers = {
    'api-key': '${apiKey}'
}

request = requests.post("${generateApiUrl()}", headers=headers, json=payload)
`;
}

export function generateNodeCode({ apiKey }: ICodeGenerator) {
  return `import axios from "axios";
const response = await axios({
  method: "POST",
  url: "${generateApiUrl()}",
  headers: {
      "api-key": "${apiKey}"
  },
  data: {
    event: "new-user",
    title: "Example event",
    subtitle: "A new user joined the platform",
    actions: [
      {
        label: "View user",
        link: "https://notiversal.com"
      }
    ]
  }
});`;
}
