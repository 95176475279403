import { useMutation, useQueryClient } from "react-query";
import { UserEntity } from "service/src/users/entities/user.entity";

import { ServiceType } from "../enums/service-type";
import { post } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IServiceUpdateRequest {
  type: ServiceType;
  data: Record<string, string>;
}

export function useServiceUpdate() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();
  return useMutation<UserEntity, null, IServiceUpdateRequest>(
    ["organisation", currentOrganisation?.id, "service/update"],
    async (data) => {
      const response = await post<UserEntity>(
        `services/${currentOrganisation?.id}/service`,
        data
      );
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("service-status");
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation,
          "services",
        ]);
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation,
          "service",
          variables.type,
        ]);
      },
    }
  );
}
