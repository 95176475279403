import { HStack, Stack, VStack, Box } from "@chakra-ui/react";
import { useState } from "react";
import { ProductEvent } from "service/src/notifications/interface/ProductEvent";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Text } from "../elements/Text";
import { EventPreferencesModal } from "../modals/EventPreferencesModal";

import { EventNotificationPreferences } from "./EventNotificationPreferences";

interface EventCardProps {
  item?: Partial<ProductEvent>;
  isLoading?: boolean;
  product?: ProductEntity;
}

export function EventCard({ isLoading, item = {}, product }: EventCardProps) {
  const { title, event, count = 0 } = item;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      {item && (
        <EventPreferencesModal
          isOpen={isModalOpen}
          product={product}
          onOpen={() => {}}
          event={item}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Card
        w="100%"
        rounded="md"
        overflow="hidden"
        width={450}
        transition="100ms all"
      >
        <Stack>
          <HStack
            spacing={2}
            inline={true}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <HStack width="100%" justifyContent="space-between">
              <HStack w="100%">
                <VStack
                  textAlign="left"
                  display="flex"
                  spacing={4}
                  w="100%"
                  alignItems="flex-start"
                >
                  <HStack justifyContent="space-between" w="100%">
                    <LoadingBackground isLoading={isLoading}>
                      <HStack>
                        <Text color="brand.400" fontSize="md" fontWeight={600}>
                          {title}
                        </Text>
                        <Text color="gray.300">{event}</Text>
                      </HStack>
                    </LoadingBackground>
                    {!isLoading && (
                      <Box>
                        <Icon
                          onClick={() => setIsModalOpen(true)}
                          icon={["far", "edit"]}
                          size={18}
                          color="brand.400"
                          cursor="pointer"
                        />
                      </Box>
                    )}
                  </HStack>

                  <LoadingBackground isLoading={isLoading} pb={4}>
                    <Text color="gray.500">
                      Notified {count} time{+count === 1 ? "" : "s"}
                    </Text>
                  </LoadingBackground>
                  <EventNotificationPreferences
                    event={item}
                    product={product}
                    isLoading={isLoading}
                  />
                </VStack>
              </HStack>
            </HStack>
          </HStack>
        </Stack>
      </Card>
    </>
  );
}
