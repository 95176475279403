import { ReactNode } from "react";

import { Text } from "../Text";

interface CardHeaderProps {
  title: ReactNode;
  label?: string;
}

export function CardHeader({ label, title }: CardHeaderProps) {
  return (
    <>
      <Text color="brand.400" fontSize="lg" fontWeight={600} mb={label ? 1 : 0}>
        {title}
      </Text>
      {label ? (
        <Text type="body" color="gray.600" mb={6}>
          {label}
        </Text>
      ) : null}
    </>
  );
}
