import { useMutation, useQueryClient } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { post, remove } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IConfirmEmailRequest {
  code?: string;
}

export function useConfirmEmail() {
  return useMutation<null, null, IConfirmEmailRequest>(
    "user/confirm-email",
    async (data) => {
      console.log("post with data", data.code);
      const response = await post<OrganisationEntity>(
        "users/activate-account",
        { code: data.code }
      );

      if (response.status !== 201) {
        return Promise.reject("Failed to confirm email");
      }

      return null;
    }
  );
}
