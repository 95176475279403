import { Role } from "service/src/users/role.enum";

import { ISidebarSection } from "../interfaces/ISidebarSection";

export const SIDEBAR_ITEMS: ISidebarSection[] = [
  {
    label: "Notifications",
    pages: [
      {
        label: "Dashboard",
        href: "/",
        icon: "home",
      },
      {
        label: "Products",
        href: "/products",
        icon: "box-open",
      },

      {
        label: "Users",
        href: "/users",
        icon: "users",
      },

      {
        label: "Services",
        href: "/services",
        icon: "concierge-bell",
      },

      {
        label: "Organisation",
        href: "/organisation",
        icon: "building",
        role: Role.Admin,
      },
    ],
  },
];
