export const NOTIFY_FORM = [
  {
    type: "text-input",
    name: "title",
    label: "Title",
    required: true,
    minLength: 2,
    icon: "heading",
    maxLength: 64,
  },

  {
    type: "text-input",
    name: "event",
    label: "Identifier",
    required: true,
    minLength: 2,
    icon: "passport",
    helper: "Unique identifier of your notification (eg new-user)",
    maxLength: 64,
  },

  {
    type: "text-input",
    name: "subtitle",
    label: "Subtitle",
    required: true,
    minLength: 2,
    icon: "font",
    helper: "Subtitle of your notification",
    maxLength: 256,
  },

  {
    type: "text-input",
    name: "body",
    label: "Body",
    isTextarea: true,
    minLength: 2,
    icon: "align-left",
    helper: "Optional body of your notification",
    maxLength: 256,
  },

  {
    type: "submit",
    label: "Save",
    name: "submit",
  },
];
