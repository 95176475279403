import {
  Box,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { OrganisationUserResponse } from "service/src/organisations/response/organisation-user.response";

import { useAlert } from "../../hooks/useAlert";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useMe } from "../../hooks/useMe";
import { useOrganisationLeave } from "../../hooks/useOrganisationLeave";
import { getRoleColor } from "../../utils/role";
import { AuthGuard } from "../AuthGuard";
import { Avatar } from "../elements/Avatar";
import { Card } from "../elements/card/Card";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Tag, Tags } from "../elements/Tags";
import { Text } from "../elements/Text";
import { ModifyUserModal } from "../modals/ModifyUserModal";

export enum Role {
  User = "User",
  Developer = "Developer",
  Admin = "Admin",
}

interface IUserCardProps {
  item?: Partial<OrganisationUserResponse>;
  isLoading?: boolean;
}

export function UserCard({ item = {}, isLoading }: IUserCardProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { currentOrganisation } = useCurrentOrganisation();
  const { mutate: leaveOrganisation } = useOrganisationLeave();
  const { data: me } = useMe();
  const { setIsLoading, alert, closeAlert } = useAlert();

  const userTags: Tag[] = [
    {
      label: item.role,
      colorScheme: getRoleColor(item?.role),
      icon: "user-tag",
      show: true,
    },
  ];

  function handleLeaveOrganisation() {
    alert({
      label: "Leave organisation",
      text: `Are you sure you wish to leave ${currentOrganisation?.name}?`,
      confirmText: "Leave organisation",
      onConfirm: () => {
        setIsLoading(true);
        leaveOrganisation(null, {
          onSuccess: () => {
            closeAlert();
          },
          onSettled: () => {
            setIsLoading(false);
          },
        });
      },
    });
  }

  return (
    <>
      {!isLoading && item && (
        <ModifyUserModal
          isOpen={isModalOpen}
          user={item}
          onOpen={() => {}}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Card display="flex" flexDirection="column" alignItems="center">
        <VStack
          spacing={4}
          w="100%"
          justifyContent="space-between"
          height="100%"
        >
          <VStack spacing={4} w="100%">
            <Box justifyContent="center" alignItems="center" display="flex">
              <Avatar
                isLoading={isLoading}
                image={item.avatar}
                id={item?.id ?? ""}
                variant="beam"
                size={32}
                pos="relative"
              />
            </Box>
            <VStack spacing={1} pt={5} w="100%">
              <LoadingBackground isLoading={isLoading}>
                <Heading
                  fontWeight={600}
                  fontSize="20"
                  fontFamily="body"
                  color={useColorModeValue("gray.700", "gray.200")}
                >
                  {item.firstName} {item.lastName}
                </Heading>
              </LoadingBackground>
              <LoadingBackground isLoading={isLoading}>
                <Text
                  fontSize={15}
                  color={useColorModeValue("gray.500", "gray.400")}
                >
                  {item.email}
                </Text>
              </LoadingBackground>
            </VStack>

            {isLoading && (
              <SimpleGrid w="100%" columns={[3, 3, 3]} spacing={5}>
                {Array.from(Array(3)).map(() => (
                  <LoadingBackground
                    h={8}
                    borderRadius={100}
                    isLoading={isLoading}
                    width="100%"
                  />
                ))}
              </SimpleGrid>
            )}

            {!isLoading && (
              <Tags
                tags={userTags}
                alignItems="center"
                alignContent="center"
                justify="center"
                justifyContent="center"
                display="flex"
                textAlign="center"
              />
            )}
          </VStack>

          {!isLoading && (
            <>
              <HStack pt={5} spacing={5}>
                {me?.id !== item.id && (
                  <AuthGuard role={Role.Admin}>
                    <Link
                      color="brand.200"
                      fontSize={14}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Modify
                    </Link>
                  </AuthGuard>
                )}
                {me?.id === item.id && (
                  <Link
                    color="red.400"
                    fontSize={14}
                    onClick={() => handleLeaveOrganisation()}
                  >
                    Leave organisation
                  </Link>
                )}
              </HStack>
            </>
          )}
        </VStack>
      </Card>
    </>
  );
}
