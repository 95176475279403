import { Box, Text, VStack } from "@chakra-ui/react";
import Recaptcha from "react-google-recaptcha";
import { IElementProps } from "react-templator";

export function RecaptchaInput({ error, onChange, value }: IElementProps) {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      display="flex"
      marginY={5}
    >
      <Recaptcha
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC ?? ""}
        onChange={(key) => {
          onChange(key);
        }}
      />
      {error ? (
        <Text
          type="error"
          color={error ? "red.500" : "gray.400"}
          pt={2}
          pb={2}
          fontSize={14}
        >
          {error}
        </Text>
      ) : null}
    </VStack>
  );
}
