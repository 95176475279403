import { Heading, HStack, useColorModeValue, VStack } from "@chakra-ui/react";

import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Text } from "../elements/Text";

interface EventCardProps {
  onClick?: () => void;
}

export function EventAddCard({ onClick }: EventCardProps) {
  return (
    <Card>
      <HStack justifyContent="space-between" h="100%">
        <VStack justifyContent="center" h="100%" alignItems="flex-start">
          <Heading
            fontWeight={500}
            fontSize={18}
            fontFamily="body"
            color={useColorModeValue("gray.700", "gray.200")}
          >
            No events
          </Heading>

          <Text fontSize={15} color={useColorModeValue("gray.500", "gray.400")}>
            Send your first event with notiversal
          </Text>
        </VStack>

        <Button icon="plus" onClick={() => onClick && onClick()}>
          Send an event
        </Button>
      </HStack>
    </Card>
  );
}
