export function getInvoiceStatusColor(status: string) {
  switch (status) {
    case "open":
      return "orange";
    case "paid":
      return "green";
    case "unpaid":
      return "red";
    case "draft":
    default:
      return "gray";
  }
}
