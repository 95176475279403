import { ReactNode } from "react";

import { useCurrentOrganisation } from "../hooks/useCurrentOrganisation";
import { useOrganisationUser } from "../hooks/useOrganisationUser";

import { Role } from "./users/UserCard";

interface IAuthGuardProps {
  role?: Role;
  children: ReactNode;
}

const ORDERS = {
  [Role.Admin]: 100,
  [Role.Developer]: 50,
  [Role.User]: 0,
};
export function AuthGuard({ role, children }: IAuthGuardProps) {
  const { currentOrganisation } = useCurrentOrganisation();
  const { data: organisationUser } = useOrganisationUser(
    currentOrganisation?.id
  );

  const currentRole = organisationUser?.role;

  if (!role) return <>{children}</>;
  if (!currentRole) return null;
  if (ORDERS[currentRole] < ORDERS[role]) return null;

  return <>{children}</>;
}
