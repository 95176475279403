import { useMutation, useQueryClient } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { remove } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

interface IEventRemove {
  identifier?: string;
}

export function useEventRemove(productId?: string) {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<null, null, IEventRemove>(
    "organisation/user/remove",
    async (data) => {
      const response = await remove<OrganisationEntity>(
        `products/${productId}/organisation/${currentOrganisation?.id}/${data.identifier}`
      );

      if (response.status !== 200) {
        return Promise.reject("Failed to remove event");
      }

      return null;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "products/organisation",
          currentOrganisation?.id,
          productId,
        ]);
      },
    }
  );
}
