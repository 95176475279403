import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogCloseButton,
  useToast,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { Form } from "react-templator";

import { PRODUCT_FORM } from "../../forms/auth/product-form";
import { News } from "../../hooks/useNews";
import { useProductUpdate } from "../../hooks/useProductUpdate";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";

interface NewsModalProps extends ModalProps {
  news?: News;
}

export function ProductModal({ isOpen, news, onClose }: NewsModalProps) {
  const { mutate: updateProduct, isLoading } = useProductUpdate();
  const toast = useToast();

  function handleOnSubmit(data: Record<string, string>) {
    updateProduct(data, {
      onSuccess: () => {
        toast({
          title: `Article ${news ? "updated" : "posted"}`,
          status: "success",
          isClosable: true,
        });

        onClose();
      },
    });
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={!isLoading}
        isOpen={isOpen}
        closeOnOverlayClick={!isLoading}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent bg={useColorModeValue("white", "dark.300")}>
          <Box p={6} pb={3}>
            <CardHeader title="Add product" />
          </Box>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody pb={6}>
            <Form
              schema={PRODUCT_FORM}
              defaultValues={news}
              dynamicProps={{
                newHeaderImage: {
                  hasFile: !!news?.headerImage,
                },
                submit: {
                  isLoading,
                },
              }}
              onSubmit={(data) => {
                handleOnSubmit(data);
              }}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
