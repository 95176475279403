import { useEffect, useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { ServiceStatus } from "../../../hooks/useServiceStatuses";
import { Link } from "../../elements/Link";
import { Text } from "../../elements/Text";

export function PushCard({ serviceStatus }: { serviceStatus?: ServiceStatus }) {
  const { updatePushNotifications } = useAuth();
  const [notificationPermission, setNotificationPermission] = useState<
    "granted" | "denied" | "default"
  >("default");

  useEffect(() => {
    setNotificationPermission(Notification.permission);
    // if (Notification.permission === "granted") {
    //   setIsWebNotificationsGranted(true)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Notification.permission]);

  if (!serviceStatus) return <></>;

  function configurePushNotifications() {
    updatePushNotifications();
  }

  const canEnable =
    notificationPermission === "default" ||
    (notificationPermission === "granted" && !serviceStatus?.enabled);

  return (
    <>
      {canEnable ? (
        // Check if push notifications are enabled on the server
        <Link onClick={configurePushNotifications} mt="0 !important">
          <Text>Enable push notifications </Text>
        </Link>
      ) : null}
      {notificationPermission === "denied" ? (
        <Text mt="0 !important" color="red.600">
          Push notification permission denied. Check your browser settings
        </Text>
      ) : null}

      {notificationPermission === "granted" && serviceStatus?.enabled ? (
        <Text mt="0 !important">Push notifications enabled</Text>
      ) : null}
    </>
  );
}
