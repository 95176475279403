import { Tag as RNTag, TagLabel, Wrap, WrapProps } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Icon } from "./Icon";

export interface Tag {
  label?: string;
  colorScheme?: string;
  icon?: IconProp;
  show?: boolean;
}
export interface TagsProps extends WrapProps {
  tags: Tag[];
}

export function Tags({ tags, ...props }: TagsProps) {
  return (
    <>
      <Wrap spacing={2} {...props}>
        {tags
          .filter(({ show }) => show !== false)
          .map(({ label, colorScheme, icon }, index) => (
            <RNTag
              size="sm"
              variant="subtle"
              px={3}
              py={2}
              borderRadius={100}
              key={index}
              colorScheme={colorScheme}
            >
              {icon ? <Icon size={12} mr={2} icon={icon} /> : null}
              <TagLabel>{label}</TagLabel>
            </RNTag>
          ))}
      </Wrap>
    </>
  );
}
