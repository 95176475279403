import {
  Container,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";

import { useLimits } from "../../hooks/useLimits";
import { useOrganisationUsers } from "../../hooks/useOrgansiationUsers";
import { AuthGuard } from "../AuthGuard";
import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingPlaceholder } from "../elements/LoadingPlaceholder";
import { PageHeader } from "../layout/PageHeader";
import { InviteUserModal } from "../modals/InviteUserModal";
import { AddUserCard } from "../users/AddUserCard";
import { PendingUserCard } from "../users/PendingUserCard";
import { Role, UserCard } from "../users/UserCard";

export function UsersPage() {
  //   const { data: news } = useews();
  // const isLoading = true;

  const { data: organisationUsers, isLoading } = useOrganisationUsers();
  const { canAddTeamMember } = useLimits();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Container maxW="container.xl">
        <PageHeader
          label="Users"
          subtitle="View and modify the users in your organisation"
          buttons={[
            {
              label: "Invite user",
              level: Role.Admin,
              isDisabled: !canAddTeamMember,
              tooltip: canAddTeamMember
                ? ""
                : "Your current plan does not allow for more team members to be added",
              icon: "plus",
              onClick: () => setIsModalOpen(true),
            },
          ]}
        />

        <SimpleGrid columns={[1, 2, 3]} spacing="20px">
          <LoadingPlaceholder length={3} isLoading={isLoading}>
            <UserCard isLoading={true} />
          </LoadingPlaceholder>

          {!isLoading && organisationUsers
            ? organisationUsers.users?.map((user, index) => (
                <UserCard key={index} isLoading={isLoading} item={user} />
              ))
            : null}

          {!isLoading && organisationUsers
            ? organisationUsers.pending?.map((user, index) => (
                <PendingUserCard
                  key={index}
                  isLoading={isLoading}
                  item={user}
                />
              ))
            : null}
          <AuthGuard role={Role.Admin}>
            <AddUserCard isLoading={isLoading} />
          </AuthGuard>

          {/* {news ? news?.map((news) => <NewsCard item={news} />) : null} */}
        </SimpleGrid>
      </Container>
      <InviteUserModal
        isOpen={isModalOpen}
        onOpen={() => {}}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
