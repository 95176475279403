import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

import { meContext } from "../appContext";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useLogout() {
  const queryClient = useQueryClient();
  const { logout } = useContext(meContext);
  const { setCurrentOrganisation } = useCurrentOrganisation();

  return useMutation("logout", async () => {
    setCurrentOrganisation(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("lastOrganisation");
    logout();

    return true;
  });
}
