import {
  FormControl,
  InputGroup,
  Textarea,
  Input as RNInput,
  InputLeftElement,
  Center,
  InputRightElement,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactNode, RefObject } from "react";

import { Icon } from "./Icon";
import { Text } from "./Text";

interface IInput {
  name: string;
  label: string;
  autoComplete?: string;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  icon?: IconProp;
  inputRef?: RefObject<any>;
  onChange?: (value: string) => void;
  value?: string | number;
  onBlur?: () => void;
  inputRightElement?: ReactNode;
  required?: boolean;
  readOnly?: boolean;
  error?: boolean;
  onClick?: () => void;
  cursor?: string;
  isTextarea?: boolean;
  disabled?: boolean;
  onKeyDown?: (event: any) => void;
  onFocus?: (event: any) => void;
  helperText?: string;
  min?: number;
  max?: number;
}
export function Input({
  label,
  name,
  required,
  readOnly,
  error,
  cursor,
  disabled,
  onChange,
  min,
  max,
  type,
  onClick,
  autoComplete,
  inputRef,
  onFocus,
  onKeyDown,
  helperText,
  inputRightElement,
  onBlur,
  isTextarea,
  icon,
  value,
}: IInput) {
  const Element = !isTextarea ? RNInput : Textarea;
  return (
    <FormControl mb={error || helperText ? 1 : 3} onClick={onClick}>
      <InputGroup>
        <Center w="100%" justifyContent="center" alignItems="center">
          {icon ? (
            <InputLeftElement
              pointerEvents="none"
              p={6}
              pl={6}
              children={<Icon icon={icon} size={15} color="gray.300" />}
            />
          ) : null}
          <Element
            type={type}
            name={name}
            cursor={cursor}
            min={min}
            max={max}
            disabled={disabled}
            fontSize={14}
            autoComplete={autoComplete}
            onFocusCapture={onFocus}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            colorScheme="brand"
            _focus={{
              borderColor: error ? "red.500" : "brand.200",
            }}
            borderRadius={8}
            onChange={(event: any) => {
              if (onChange) onChange(event?.target?.value);
            }}
            onBlur={onBlur}
            value={value}
            placeholder={`${label}${required ? " *" : ""}`}
            isInvalid={error}
            ref={inputRef}
            p={6}
            pl={icon ? 12 : 4}
          />
          {inputRightElement ? (
            <InputRightElement
              width="auto"
              alignItems="right"
              pointerEvents="none"
              p={4}
              children={inputRightElement}
            />
          ) : null}
        </Center>
      </InputGroup>
      {helperText || error ? (
        <Text
          type="error"
          color={error ? "red.500" : "gray.400"}
          pt={2}
          pb={2}
          fontSize={14}
        >
          {error ?? helperText}
        </Text>
      ) : null}
    </FormControl>
  );
}
