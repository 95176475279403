import { useQuery } from "react-query";
import { UserEntity } from "service/src/users/entities/user.entity";

import { get } from "../services/api";

export function useMe() {
  return useQuery<UserEntity | undefined>("user/account", async () => {
    const response = await get<UserEntity>("users/account", {});
    return response.data;
  });
}
