import { useState } from "react";

import { useMe } from "../../../hooks/useMe";
import { Link } from "../../elements/Link";
import { Text } from "../../elements/Text";
import { EditAccountModal } from "../../modals/EditAccountModal";

export function SmsCard() {
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);

  const { data: me } = useMe();

  return (
    <>
      <EditAccountModal
        onOpen={() => {}}
        onClose={() => setAccountModalOpen(false)}
        isOpen={accountModalOpen}
      />
      {me?.mobile ? (
        <Text mt="0 !important">Sends to {me.mobile}</Text>
      ) : (
        <Link onClick={() => setAccountModalOpen(true)}>
          <Text>Add a mobile number</Text>
        </Link>
      )}
    </>
  );
}
