import { useQuery } from "react-query";
import { Product } from "service/src/util/subscription";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export type ITrialStatus = Record<Product, boolean>;

export function useSubscriptionTrials() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<ITrialStatus | undefined>(
    ["subscriptions/trials", currentOrganisation?.id],
    async () => {
      const response = await get<ITrialStatus>(
        `subscriptions/${currentOrganisation?.id}/trials`,
        {}
      );
      return response.data;
    },
    {
      enabled: !!currentOrganisation?.id,
    }
  );
}
