import dayjs from "dayjs";
import { useQuery } from "react-query";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export enum SubscriptionStatus {
  active = "active",
  "past_due" = "past_due",
  "unpaid" = "unpaid",
  "canceled" = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  "trialing" = "trialing",
  "all" = "all",
  "ended" = "ended",
}

export function useSubscriptionBlocked() {
  const { currentOrganisation } = useCurrentOrganisation();
  return useQuery<boolean>(
    ["block/billing", currentOrganisation?.id],
    async () => {
      const localStorageName = `block_billing_${currentOrganisation?.id}`;

      const blockBilling = localStorage.getItem(localStorageName);
      if (!blockBilling) return false;

      try {
        const isBlocked = dayjs(blockBilling).isAfter(dayjs());
        if (!isBlocked) {
          localStorage.removeItem(localStorageName);
        }
        return isBlocked;
      } catch (e) {
        console.error(e);
      }

      return false;
    },
    {
      refetchInterval: 1000,
    }
  );
}
