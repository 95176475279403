import { useMutation, useQueryClient } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { post, put } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useOrganisations } from "./useOrganisations";

export function useOrganisationUpdate() {
  const queryClient = useQueryClient();
  const { currentOrganisation, setCurrentOrganisation } =
    useCurrentOrganisation();
  const { data: organisations } = useOrganisations();

  return useMutation<OrganisationEntity, null, Record<string, string>>(
    "organisation/update",
    async (data) => {
      if (data.id) {
        const response = await put<OrganisationEntity>(
          `organisations/${data.id}`,
          {
            ...data,
          }
        );
        if (response.status !== 200)
          return Promise.reject("Could not update product");
        return response.data;
      }
      const response = await post<OrganisationEntity>("organisations", {
        ...data,
      });
      if (response.status !== 201)
        return Promise.reject("Could not create product");
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        if (!variables.id) {
          queryClient.setQueryData("organisations", [
            ...(organisations || []),
            data,
          ]);
        } else {
          setCurrentOrganisation({ ...currentOrganisation, ...data });
        }
        queryClient.invalidateQueries("organisations");
      },
    }
  );
}
