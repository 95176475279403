import {
  Box,
  Center,
  Container,
  Fade,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export function LoadingCover({ isLoading }: { isLoading: boolean }) {
  const [shouldFade, setShouldFade] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setShouldFade(isLoading);
    }, 500);
  }, [isLoading]);

  return (
    <Box
      bg="brand.900"
      zIndex={9999}
      position="absolute"
      backgroundSize="cover"
      backgroundColor="brand.200"
      backgroundImage="/img/loading.svg"
      backgroundAttachment="fixed"
      minHeight="100%"
      minWidth="100%"
      top={0}
      transition="400ms all"
      opacity={shouldFade ? 1 : 0}
      pointerEvents={!shouldFade ? "none" : "auto"}
      left={0}
    >
      <Container maxW="container.sm">
        <Center minH="100vh">
          <VStack w="100%" spacing={100}>
            <Fade in={shouldFade}>
              <Spinner size="xl" color="white" />
            </Fade>
          </VStack>
        </Center>
      </Container>
    </Box>
  );
}
