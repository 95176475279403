import { Box, Container } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useOrganisationProduct } from "../../../hooks/useOrganisationProduct";
import { Tabs } from "../../elements/Tabs";
import { PageHeader } from "../../layout/PageHeader";
import { NotifyModal } from "../../modals/NotifyModal";
import { ProductSetupModal } from "../../modals/ProductSetupModal";
import { Role } from "../../users/UserCard";

import { EventsPage } from "./EventsPage";
import { ProductSettingsPage } from "./ProductSettingsPage";

export function ProductPage() {
  const { productId } = useParams<{ productId: string }>();
  const { data: product, isLoading } = useOrganisationProduct(productId);

  const [isDocumentationOpen, setIsDocumentationOpen] =
    useState<boolean>(false);
  const [isNotifyOpen, setIsNotifyOpen] = useState<boolean>(false);

  return (
    <>
      <ProductSetupModal
        isOpen={isDocumentationOpen}
        product={product}
        onClose={() => setIsDocumentationOpen(false)}
        onOpen={() => {}}
      />
      <NotifyModal
        isOpen={isNotifyOpen}
        product={product}
        onClose={() => setIsNotifyOpen(false)}
        onOpen={() => {}}
      />
      <Container maxW="container.xl">
        <PageHeader
          subtitle="View and modify your product"
          label={`Product ${product ? ` - ${product?.label}` : ""}`}
          isLoading={isLoading}
          buttons={
            product
              ? [
                  {
                    label: "Documentation",
                    level: Role.Developer,
                    icon: "hands-helping",
                    onClick: () => setIsDocumentationOpen(true),
                  },
                  {
                    label: "Send notification",
                    level: Role.Developer,
                    icon: "bell",
                    onClick: () => setIsNotifyOpen(true),
                  },
                ]
              : undefined
          }
        />
        <Box>
          <Tabs
            isLoading={isLoading}
            tabs={[
              {
                label: "Events",
                icon: "calendar-alt",
                render: () => <EventsPage />,
              },
              {
                label: "Product settings",
                icon: "cog",
                level: Role.Admin,
                render: () => <ProductSettingsPage />,
              },
            ]}
          />
        </Box>
      </Container>
    </>
  );
}
