import { Container, HStack, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";

import { useOrganisationProducts } from "../../hooks/useOrganisationProducts";
import { AuthGuard } from "../AuthGuard";
import { Animation } from "../elements/Animation";
import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingPlaceholder } from "../elements/LoadingPlaceholder";
import { PageHeader } from "../layout/PageHeader";
import { ProductModal } from "../modals/ProductModal";
import { ProductCard } from "../news/ProductCard";
import { Role } from "../users/UserCard";

export function ProductsPage() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    data: products,

    isLoading,
    //
  } = useOrganisationProducts();

  return (
    <>
      <ProductModal
        isOpen={isModalOpen}
        onOpen={() => {}}
        onClose={() => setIsModalOpen(false)}
      />
      <Container maxW="container.xl">
        <PageHeader
          label="Products"
          subtitle="View all the products in your organisation"
          buttons={[
            {
              label: "Add product",
              icon: "plus",
              onClick: () => setIsModalOpen(true),
              level: Role.Admin,
            },
          ]}
        />

        <SimpleGrid columns={[1, 2, 3]} spacing="20px" w="100%">
          <LoadingPlaceholder length={6} isLoading={isLoading}>
            <ProductCard isLoading={isLoading} />
          </LoadingPlaceholder>

          {!isLoading && products
            ? products?.map((product, index) => (
                <Animation index={index} key={product.id}>
                  <ProductCard item={product} />
                </Animation>
              ))
            : null}
          {!isLoading && (
            <AuthGuard role={Role.Admin}>
              <Card
                // opacity={0.5}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <HStack w="100%" justifyContent="space-between">
                  <Icon icon="box-open" size={[40, 80]} color="gray.200" />
                  {/* <Box>Want a new product?</Box> */}
                  <Button icon="plus" onClick={() => setIsModalOpen(true)}>
                    Add product
                  </Button>
                </HStack>
                {/* <Icon icon={"plus"} mr={1}></Icon> */}
              </Card>
            </AuthGuard>
          )}
        </SimpleGrid>
      </Container>
    </>
  );
}
