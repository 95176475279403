import { ColorModeScript } from "@chakra-ui/react";
import { render } from "react-dom";

import Root from "./Root";
import "./serviceWorkerRegistration";
import theme from "./theme";

const rootElement = document.getElementById("root");
render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <Root />
  </>,
  rootElement
);
