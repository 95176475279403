import { useQuery } from "react-query";
import { UsersResponseDto } from "service/src/organisations/dto/users-response.dto";
import { OrganisationUserResponse } from "service/src/organisations/response/organisation-user.response";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useOrganisationUsers() {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<UsersResponseDto>(
    ["organisation", currentOrganisation?.id, "users"],
    async () => {
      const response = await get<UsersResponseDto>(
        `organisations/${currentOrganisation?.id}/users`,
        {}
      );

      if (response.status === 200) return response.data;
      return Promise.reject("Could not fetch products");
    },
    {
      enabled: !!currentOrganisation,
      keepPreviousData: true,
    }
  );
}
