import { useMutation, useQueryClient } from "react-query";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { post, put } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useOrganisationProducts } from "./useOrganisationProducts";

export function useProductUpdate() {
  const queryClient = useQueryClient();
  const { currentOrganisation } = useCurrentOrganisation();
  const { data: products } = useOrganisationProducts();

  return useMutation<ProductEntity, null, Record<string, string>>(
    "product/update",
    async (data) => {
      if (data.id) {
        const response = await put<ProductEntity>(`products/${data.id}`, {
          organisation: currentOrganisation?.id,
          ...data,
        });
        if (response.status !== 200)
          return Promise.reject("Could not update product");
        return response.data;
      }
      const response = await post<ProductEntity>("products", {
        organisation: currentOrganisation?.id,
        ...data,
      });
      if (response.status !== 201)
        return Promise.reject("Could not create product");
      return response.data;
    },
    {
      onSuccess: (data, input) => {
        if (input.id) {
          queryClient.setQueryData(["product", data?.id], data);
        } else
          queryClient.setQueryData(
            ["product/organisation", currentOrganisation?.id],
            [...(products || []), data]
          );

        queryClient.refetchQueries([
          "products/organisation",
          currentOrganisation?.id,
        ]);
      },
    }
  );
}
