import {
  Tabs as RNTabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactNode } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { AuthGuard } from "../AuthGuard";
import { Role } from "../users/UserCard";

import { Icon } from "./Icon";

interface ITabsProps {
  isLoading?: boolean;
  tabs: {
    label: string;
    icon: IconProp;
    level?: Role;
    render: () => ReactNode;
  }[];
}

export function Tabs({ tabs, isLoading }: ITabsProps) {
  const { tab } = useParams<{ tab: string }>();
  const { replace } = useHistory();
  const tabColor = useColorModeValue("gray.800", "gray.300");
  const initialTabIndex = tabs.findIndex(
    (currentTab) =>
      `#${currentTab.label}`.toLowerCase() ===
      `${window.location.hash}`.toLowerCase()
  );

  return (
    <>
      <RNTabs
        colorScheme="brand"
        w="100%"
        onChange={() => {
          replace({
            search: "",
          });
        }}
        defaultIndex={initialTabIndex !== -1 ? initialTabIndex : 0}
      >
        <TabList
          pointerEvents={isLoading ? "none" : "auto"}
          borderBottomColor="transparent"
          boxShadow="rgb(0 0 0 / 11%) 0 1px"
        >
          {tabs.map(({ level, label, icon }) => (
            <AuthGuard role={level}>
              <Tab
                py={3}
                px={5}
                key={label}
                fontSize={14}
                color={tabColor}
                borderTopRadius={6}
                _hover={{ background: "transparent" }}
              >
                <Icon mr={4} size={18} icon={icon} />
                {label}
              </Tab>
            </AuthGuard>
          ))}
        </TabList>
        <TabPanels mt={0} maxW="100%" overflow="hidden" w="100%">
          {tabs.map(({ label, render }) => (
            <TabPanel p={0} mt={6} overflow="hidden" key={label} w="100%">
              {render()}
            </TabPanel>
          ))}
        </TabPanels>
      </RNTabs>
    </>
  );
}
