export const REGISTER_FORM = [
  {
    type: "h-stack",
    children: [
      {
        type: "text-input",
        name: "firstName",
        label: "First name",
        inputType: "text",
        required: true,
        minLength: 1,
        maxLength: 12,
      },
      {
        type: "text-input",
        name: "lastName",
        label: "Last name",
        inputType: "text",
        required: true,
        minLength: 1,
        maxLength: 12,
      },
    ],
  },

  {
    type: "text-input",
    name: "email",
    label: "Email",
    inputType: "email",
    icon: "inbox",
    required: true,
    minLength: 4,
    maxLength: 80,
  },
  {
    type: "text-input",
    name: "password",
    label: "Password",
    inputType: "password",
    autoComplete: "new-password",
    icon: "lock",
    required: true,
    minLength: 8,
    maxLength: 80,
  },

  {
    type: "text-input",
    name: "mobile",
    label: "Mobile number",
    inputType: "text",
    helper: "Used for SMS Notifications and voice calls",
    minLength: 2,
    icon: "phone",
    maxLength: 80,
  },

  {
    type: "text-input",
    name: "business",
    label: "Business",
    inputType: "text",
    minLength: 2,
    icon: "briefcase",
    maxLength: 80,
  },

  {
    type: "recaptcha",
    name: "recaptcha",
  },
  {
    type: "submit",
    label: "Submit",
    name: "submit",
    isLarge: true,
  },
];
