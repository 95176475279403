import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button } from "../elements/Button";

interface SidebarButtonProps {
  href: string;
  icon?: IconProp;
  children?: string;
  disabled?: boolean;
}

export function SidebarButton({
  children,
  disabled,
  href,
  icon,
}: SidebarButtonProps) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(
      `/${location.pathname.substring(1).replace(/[^\w+]*$/, "")}` === href
    );
  }, [location.pathname, href]);

  const isDisabled = disabled;
  return (
    <Link to={href}>
      <Button
        py={4}
        px={0}
        pl={2}
        mb={1}
        icon={icon}
        width="100%"
        iconSize={14}
        borderRadius={5}
        colorScheme="brand"
        backgroundColor={isActive ? "brand.100" : "transparent"}
        disabled={isDisabled}
        justifyContent="flex-start"
        textAlign={["left", "left"]}
        iconProps={{ mr: 2, minWidth: 12 }}
        borderWidth={isActive ? undefined : 0}
        // backgroundColor="red.200"
        color={isActive ? "white" : "gray.800"}
        // colorScheme={isActive ? "brand" : "transparent"}
        labelProps={{
          fontSize: 14,
        }}
        _hover={{
          backgroundColor: isDisabled
            ? "transparent"
            : isActive
            ? "brand.200"
            : "gray.100",
        }}
      >
        {children}
      </Button>
    </Link>
  );
}
