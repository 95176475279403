import { HStack, Stack, VStack, Box } from "@chakra-ui/react";
import {
  IconName,
  IconPrefix,
  IconProp,
} from "@fortawesome/fontawesome-svg-core";

import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Text } from "../elements/Text";

interface NewsProps {
  isLoading?: boolean;
  isEvent?: boolean;
  isActive?: boolean | null;
  isMinimal?: boolean;
  isPreferenceLoading?: boolean;
  label?: string;
  image?: string;
  handleToggle?: () => void;
}

export function ServicePreferenceCard({
  isEvent,
  isActive,
  isPreferenceLoading,
  label,
  isLoading,
  handleToggle,
  isMinimal,
  image,
}: NewsProps) {
  function getIcon(
    isActive?: boolean | null
  ): IconProp | [IconPrefix, IconName] {
    if (isActive) return "check";
    if (!isEvent || isActive === false) return "times";
    return ["far", "circle"];
  }

  function getIconColor(isActive?: boolean | null) {
    if (isActive) return "green.400";
    if (!isEvent || isActive === false) return "red.400";
    return "gray.400";
  }

  return (
    <>
      <Card
        py={4}
        width={isMinimal ? undefined : 300}
        // maxW={"100%"}
        rounded="md"
        w="100%"
        pointerEvents={isLoading || isPreferenceLoading ? "none" : "auto"}
        backgroundColor={isEvent ? "gray.100" : ""}
        // overflow={"hidden"}
        transition="100ms all"
        cursor="pointer"
        onClick={!isLoading ? handleToggle : undefined}
        transform={!isActive ? "scale(0.97)" : undefined}
      >
        <Stack pointerEvents="none" w="100%">
          <HStack
            spacing={2}
            w="100%"
            inline={true}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <HStack width="100%" justifyContent="space-between">
              <HStack w="100%">
                {!isMinimal && (
                  <LoadingBackground
                    width={12}
                    height={12}
                    borderRadius={1000}
                    isLoading={isLoading}
                  >
                    <Box
                      width={12}
                      height={12}
                      position="relative"
                      backgroundSize="cover"
                      backgroundPosition="center"
                      backgroundImage={image}
                    />
                  </LoadingBackground>
                )}
                <LoadingBackground isLoading={isLoading}>
                  <VStack
                    textAlign="left"
                    display="flex"
                    pl={isMinimal ? 0 : 4}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Text color="brand.400" fontSize="md" fontWeight={600}>
                      {label}
                    </Text>
                  </VStack>
                </LoadingBackground>
              </HStack>
              {!isLoading && (
                <Box
                  width={18}
                  pl={isMinimal ? 10 : 0}
                  pr={isMinimal ? 2 : 0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    opacity={isPreferenceLoading ? 0 : 1}
                    transition="opacity 100ms"
                    color={getIconColor(isActive)}
                    icon={getIcon(isActive)}
                    size={20}
                  />
                </Box>
              )}
            </HStack>
          </HStack>
        </Stack>
      </Card>
    </>
  );
}
