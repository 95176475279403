import { SimpleGrid } from "@chakra-ui/react";

import { useActiveSubscription } from "../../../hooks/useActiveSubscription";
import { useSubscriptionProducts } from "../../../hooks/useSubscriptionProducts";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder";
import { SubscriptionCard } from "../../subscriptions/SubscriptionCard";

export function SubscriptionPage() {
  const { data: activeSubscription } = useActiveSubscription();
  const { data: products } = useSubscriptionProducts();

  return (
    <>
      <SimpleGrid columns={[1, 3, 3]} spacing="20px">
        {products?.map((product) => (
          <SubscriptionCard
            product={product}
            activeSubscription={activeSubscription}
          />
        ))}
      </SimpleGrid>
    </>
  );
}
