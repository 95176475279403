import { useToast } from "@chakra-ui/react";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as regularIcons from "@fortawesome/free-regular-svg-icons";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { FormProvider } from "react-templator";
import { UserEntity } from "service/src/users/entities/user.entity";

import {
  AlertContext,
  alertContext,
  IAlertData,
  MeContext,
  meContext,
} from "./appContext";
import { Main } from "./components/Main";
import { AlertModal } from "./components/modals/AlertModal";
import { FORM_ELEMENTS, FORM_LAYOUT_ELEMENTS } from "./constants/form";
import { get } from "./services/api";

import "animate.css/animate.compat.css";
import "./constants/styles.css";

/* eslint-enable max-len */

const iconList = Object.keys(icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  // @ts-ignore
  .map((icon) => icons[icon]);

const iconList2 = Object.keys(regularIcons)
  .filter((key) => key !== "far" && key !== "prefix")
  // @ts-ignore
  .map((icon) => regularIcons[icon]);

// @ts-ignore
library.add(iconList);

// @ts-ignore
library.add(iconList2);

// TODO: Move to the fucking other thing
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

/* eslint-enable max-len */

function urlB64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const queryClient = new QueryClient();
export default function App() {
  const [, setMe] = useState<null | UserEntity>(null);
  const toast = useToast();
  // const { mutate: addUserService } = useUserServiceAdd();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    meContextValue.authorize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const meContextValue: MeContext = useMemo(
    () => ({
      isAuthenticated,
      logout: () => {
        setIsAuthenticated(false);
      },
      authorize: async () => {
        const user = await get("users/account", {});
        if (user.status !== 200) {
          setMe(null);
          setIsAuthenticated(false);
        } else {
          // @ts-ignore
          setMe(user.data);
          setIsAuthenticated(true);
        }
      },
      updatePushNotifications: async () => {
        const notificationResult = await Notification.requestPermission();

        if (notificationResult !== "granted") {
          toast({
            title: "Could not enable push notifications",
            status: "error",
            isClosable: true,
          });
        } else {
          toast({
            title: "Push notifications enabled",
            status: "success",
            isClosable: true,
          });
        }
      },
    }),
    [isAuthenticated, toast]
  );

  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [currentAlertModalData, setCurrentAlertModalData] =
    useState<IAlertData>({});

  const alertContextValue: AlertContext = useMemo(
    () => ({
      alert: (data: IAlertData) => {
        setCurrentAlertModalData(data);
        setIsAlertModalOpen(true);
      },
      closeAlert: () => {
        setIsAlertModalOpen(false);
      },
      setIsLoading: (isLoading: boolean) => {
        setIsAlertLoading(isLoading);
      },

      currentData: currentAlertModalData,
    }),
    [currentAlertModalData]
  );

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <meContext.Provider value={meContextValue}>
          <alertContext.Provider value={alertContextValue}>
            <AlertModal
              onOpen={() => {}}
              isOpen={isAlertModalOpen}
              isLoading={isAlertLoading}
              onClose={() => {
                setCurrentAlertModalData({});
                setIsAlertModalOpen(false);
              }}
              buttonText={currentAlertModalData.confirmText ?? ""}
              onConfirm={() => {
                if (currentAlertModalData.onConfirm) {
                  currentAlertModalData.onConfirm();
                }
              }}
              title={currentAlertModalData.label}
              body={currentAlertModalData.text}
            />
            <Elements stripe={stripePromise}>
              <FormProvider
                elements={FORM_ELEMENTS}
                layoutElements={FORM_LAYOUT_ELEMENTS}
              >
                <BrowserRouter>
                  <Main />
                </BrowserRouter>
              </FormProvider>
            </Elements>
          </alertContext.Provider>
        </meContext.Provider>
      </QueryClientProvider>
    </>
  );
}
