import { useMutation, useQueryClient } from "react-query";

import { remove } from "../services/api";

import { useNotificationPreferences } from "./useNotificationPreferences";

interface DeleteProductNotificationPreferenceRequest {
  preference: string;
}

export function useDeleteEventNotificationPreference(
  productId?: string,
  eventId?: string
) {
  const queryClient = useQueryClient();
  const { data: preferences } = useNotificationPreferences({
    productId,
    eventId,
  });

  return useMutation<boolean, null, DeleteProductNotificationPreferenceRequest>(
    ["notify-preferences", eventId],
    async (input: DeleteProductNotificationPreferenceRequest) => {
      const response = await remove(
        `products/${productId}/event/${eventId}/notification-preferences/${input.preference}`
      );
      return response.status === 200;
    },
    {
      onSuccess: (_data, input) => {
        queryClient.setQueryData(
          ["notify-preferences", eventId],
          preferences?.filter(
            (preference) => preference.preference !== input.preference
          )
        );
      },
    }
  );
}
