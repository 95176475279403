import {
  HStack,
  Tag,
  TagLabel,
  VStack,
  Wrap,
  Box,
  Text,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState } from "react";
import { SubscriptionStatus } from "service/src/subscription/enum/SubscriptionStatus";
import { ISubscription } from "service/src/subscription/interface/ISubscription";
import { ProductInterface } from "service/src/subscription/interface/ProductInterface";

import { PRODUCT_RANKS } from "../../constants/products";
import { useSubscriptionBlocked } from "../../hooks/useSubscriptionBlocked";
import { timeAgo } from "../../utils/time";
import { Button } from "../elements/Button";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { CheckoutModal } from "../modals/CheckoutModal";

import { SubscriptionButton } from "./SubscriptionButton";

export function SubscriptionCard({
  product,
  activeSubscription,
}: {
  product?: ProductInterface;
  activeSubscription?: ISubscription;
  status?: SubscriptionStatus;
}) {
  const { data: isBillingBlocked } = useSubscriptionBlocked();
  const isActive = activeSubscription?.product?.name === product?.name;

  const ends = activeSubscription?.period_end;
  const cancelAtPeriodEnd = activeSubscription?.cancel_at_period_end;

  const isTrialing = activeSubscription?.status === SubscriptionStatus.trialing;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isObsolete = product
    ? PRODUCT_RANKS[activeSubscription?.product?.name || "unknown"] >
      PRODUCT_RANKS[product.name]
    : null;

  const hasStartError = !!product?.startError;

  return (
    <>
      {isModalOpen ? (
        <CheckoutModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}
      <Box maxW="420px">
        <Card
          w="100%"
          p={6}
          bg="white"
          rounded="md"
          overflow="hidden"
          display="block"
        >
          <VStack spacing={6} alignItems="left">
            <Stack textAlign="left" color="grey.800" spacing={2}>
              <HStack justifyContent="space-between" alignItems="center">
                <HStack justifyContent="space-between">
                  <Text color="brand.400" fontSize="lg" fontWeight={600}>
                    {product?.label}
                  </Text>
                </HStack>

                <Box>
                  {!cancelAtPeriodEnd && isActive && isTrialing && ends ? (
                    <Tag size="sm" variant="subtle" colorScheme="green">
                      <Icon size={12} mr={2} icon="check" />
                      <TagLabel>Trial ends {ends && timeAgo(ends)}</TagLabel>
                    </Tag>
                  ) : null}
                  {ends && isActive && cancelAtPeriodEnd ? (
                    <Tag size="sm" variant="subtle" colorScheme="orange">
                      <Icon size={12} mr={2} icon="exclamation-triangle" />
                      <TagLabel>Ends {ends && timeAgo(ends)}</TagLabel>
                    </Tag>
                  ) : null}

                  {hasStartError && (
                    <Tooltip
                      label={product?.startError}
                      isDisabled={false}
                      shouldWrapChildren={true}
                      px={3}
                      py={1}
                      backgroundColor="red.600"
                    >
                      <Tag size="sm" variant="subtle" colorScheme="red">
                        <Icon size={12} mr={2} icon="times" />
                        <TagLabel>Exceeded by current usage</TagLabel>
                      </Tag>
                    </Tooltip>
                  )}

                  {!isTrialing && isActive && !cancelAtPeriodEnd ? (
                    <Tag size="sm" variant="subtle" colorScheme="green">
                      <Icon size={12} mr={2} icon="check" />
                      <TagLabel>Active</TagLabel>
                    </Tag>
                  ) : null}

                  {!hasStartError && isObsolete ? (
                    <Tag size="sm" variant="subtle" colorScheme="gray">
                      <TagLabel>Obsolete</TagLabel>
                    </Tag>
                  ) : null}
                </Box>
              </HStack>
              <HStack direction="row" spacing={2}>
                <Text fontSize="3xl" fontWeight={600}>
                  {product?.price ? `£${product?.price}` : "Free"}
                </Text>
                {product?.price && <Text color="gray.500">/</Text>}
                <Text color="gray.500">
                  {product?.price ? product?.type : "with restrictions"}
                </Text>
              </HStack>
              {!isEmpty(product?.features) && (
                <Box pt={2}>
                  <Wrap>
                    {product?.features?.map((feature) =>
                      feature.features.map((featureName) => (
                        <Tag
                          size="sm"
                          variant="subtle"
                          colorScheme={feature.color}
                          p={2}
                        >
                          <TagLabel>{featureName}</TagLabel>
                        </Tag>
                      ))
                    )}
                  </Wrap>
                </Box>
              )}
            </Stack>
            {isBillingBlocked ? (
              <HStack>
                <Button disabled={true}>Processing...</Button>
              </HStack>
            ) : undefined}
            {!hasStartError && !isBillingBlocked && !isObsolete ? (
              <SubscriptionButton
                activeSubscription={activeSubscription}
                product={product}
              />
            ) : undefined}
          </VStack>
        </Card>
      </Box>
    </>
  );
}
