import { useQuery } from "react-query";
import { NotifyPreferenceEntity } from "service/src/products/entities/notify-preference.entity";

import { get } from "../services/api";

export function useNotificationPreferences({
  productId,
  eventId,
}: {
  productId?: string;
  eventId?: string;
}) {
  return useQuery<NotifyPreferenceEntity[]>(
    ["notify-preferences", eventId ?? productId],
    async () => {
      if (!productId) return [];

      const url = !eventId
        ? `products/${productId}/notification-preferences`
        : `products/${productId}/event/${eventId}/notification-preferences`;

      const response = await get<NotifyPreferenceEntity[]>(url, {});
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
}
