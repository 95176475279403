import {
  HStack,
  Stack,
  useColorModeValue,
  VStack,
  Box,
} from "@chakra-ui/react";
import { ProductsResponse } from "service/src/products/response/products.response";

import { useImagePreload } from "../../hooks/useImagePreload";
import { getImage } from "../../utils/image";
import { Card } from "../elements/card/Card";
import { Icon } from "../elements/Icon";
import { Link } from "../elements/Link";
import { LoadingBackground } from "../elements/LoadingBackground";
import { Text } from "../elements/Text";

interface IProductCardProps {
  item?: Partial<ProductsResponse>;
  isLoading?: boolean;
}

export function ProductCard({
  item = {},
  isLoading: isProductLoading,
}: IProductCardProps) {
  const { id, label, image, eventCount } = item;
  const url = getImage(image);
  const { isLoading: isImageLoading } = useImagePreload([url]);

  const isLoading = isProductLoading;

  return (
    <>
      <Link to={`/products/${id}`} _hover={{ textDecoration: "none" }}>
        <Card
          maxW="100%"
          overflow="hidden"
          transition="200ms all"
          isLoading={isLoading}
          py={4}
          isHoverable={true}
        >
          <Stack>
            <HStack
              spacing={2}
              inline={true}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <HStack width="100%" justifyContent="space-between">
                <HStack w="100%">
                  <LoadingBackground
                    w={[10, 24]}
                    h={[10, 24]}
                    borderRadius="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    isLoading={isLoading || isImageLoading}
                  >
                    <Box
                      width={[14, 24]}
                      height={[14, 24]}
                      position="relative"
                      borderRadius={500}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      filter={useColorModeValue("", "brightness(0.9)")}
                      backgroundImage={!isLoading ? url : undefined}
                    />
                  </LoadingBackground>
                  <VStack
                    textAlign="left"
                    display="flex"
                    pl={4}
                    alignItems="flex-start"
                    w="100%"
                    justifyContent="flex-start"
                  >
                    <LoadingBackground isLoading={isLoading}>
                      <Text
                        color={useColorModeValue("brand.200", "blue.300")}
                        fontSize="md"
                        fontWeight={600}
                      >
                        {label}
                      </Text>
                    </LoadingBackground>

                    <LoadingBackground isLoading={isLoading}>
                      <Text mt="0 !important" color="gray.500">
                        {eventCount === 0 ? "No events yet" : null}

                        {eventCount !== 0
                          ? `${eventCount} event${eventCount === 1 ? "" : "s"}`
                          : null}
                      </Text>
                    </LoadingBackground>
                  </VStack>
                </HStack>
                {!isLoading && <Icon size={20} icon="chevron-right" />}
              </HStack>
            </HStack>
          </Stack>
        </Card>
      </Link>
    </>
  );
}
