import { useMutation, useQueryClient } from "react-query";

import { ServiceType } from "../enums/service-type";
import { UserServiceType } from "../enums/user-service-type";
import { remove } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { useOrganisationServices } from "./useOrganisationServices";

export function useOrganisationServiceDelete() {
  const queryClient = useQueryClient();
  const { data: services } = useOrganisationServices();

  const { currentOrganisation } = useCurrentOrganisation();

  return useMutation<boolean, null, ServiceType | UserServiceType>(
    "organisation/delete",
    async (type: ServiceType | UserServiceType) => {
      const response = await remove(
        `services/${currentOrganisation?.id}/service/${type}`
      );
      return response.status === 200;
    },
    {
      onSuccess: (data, type) => {
        queryClient.removeQueries([
          "organisation",
          currentOrganisation,
          "service",
        ]);

        queryClient.setQueryData(
          ["organisation", currentOrganisation, "services"],
          {
            ...services,
            [type]: false,
          }
        );
        queryClient.refetchQueries([
          "organisation",
          currentOrganisation,
          "services",
        ]);
      },
    }
  );
}
