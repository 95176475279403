import { BoxProps, useColorModeValue, Box, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ICard extends BoxProps {
  label?: string;
  isHoverable?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}

export function Card({
  isHoverable,
  isLoading,
  children,
  label,
  ...props
}: ICard) {
  return (
    <Box
      bg={useColorModeValue("white", "dark.300")}
      width="100%"
      opacity={isLoading ? 0.5 : 1}
      pointerEvents={isLoading ? "none" : undefined}
      // bg="red.200"
      p={6}
      border={!isLoading ? "1px solid rgb(0 0 0 / 0.08)" : undefined}
      borderRadius={5}
      _hover={
        isHoverable && !isLoading
          ? {
              transform: "scale(0.96)",
              boxShadow: "0 2px 0px rgb(0 0 0 / 0.05)",
            }
          : {}
      }
      {...props}
    >
      {label ? (
        <Text color="white.200" mb={3} fontWeight={500} textStyle="h4">
          {label}
        </Text>
      ) : null}
      {children}
    </Box>
  );
}
