import { useQuery } from "react-query";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { ProductStatus } from "../enums/product-status";
import { get } from "../services/api";

export interface ProductResponse {
  id: string;
  name: string;
  bannerImage: string;
  city: string;
  address: string;
  rejectMessage: string;
  postcode: string;
  status: ProductStatus;
  color: string;
}

export function useOrganisationProduct(productId?: string) {
  return useQuery<ProductEntity>(
    ["product", productId],
    async () => {
      const response = await get<ProductEntity>(`products/${productId}`, {});
      return response.data;
    },
    {
      keepPreviousData: true,
      enabled: !!productId,
    }
  );
}
