import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  AlertDialogHeader,
} from "@chakra-ui/react";

import { useCancelSubscription } from "../../../hooks/useCancelSubscription";
import { ModalProps } from "../../../interfaces/ModalProps";
import { Button } from "../../elements/Button";
import { Text } from "../../elements/Text";

interface SubscriptionCancelModalProps extends ModalProps {
  cancelAtPeriodEnd?: boolean;
}

export function SubscriptionCancelModal({
  cancelAtPeriodEnd,
  isOpen,
  onClose,
}: SubscriptionCancelModalProps) {
  const { mutate: subscriptionCancel, isLoading } = useCancelSubscription();

  function handleUpgrade() {
    subscriptionCancel(
      { instant: cancelAtPeriodEnd },
      {
        onSuccess: () => onClose(),
      }
    );
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogOverlay />
        <AlertDialogContent pt={5}>
          <AlertDialogHeader>
            {cancelAtPeriodEnd ? "Delete subscription" : "Disable auto-renew"}
          </AlertDialogHeader>
          {!isLoading ? <AlertDialogCloseButton /> : null}
          <AlertDialogBody>
            <Text type="body" mb={5}>
              {" "}
              {cancelAtPeriodEnd
                ? "This will cancel your subscription immediately and you will loose access to all features immediately. This action is irreversible."
                : "Are you sure you wish to disable auto-renew? You will still be able to use your subscription until the day it expires."}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter mt={3}>
            <Button
              color="gray.800"
              colorScheme="gray"
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              isLoading={isLoading}
              onClick={handleUpgrade}
            >
              {cancelAtPeriodEnd ? "Delete subscription" : "Disable auto-renew"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
