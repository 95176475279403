import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  useToast,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Form } from "react-templator";

import { ORGANISATION_FORM } from "../../forms/auth/organisation-form";
import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { useOrganisationUpdate } from "../../hooks/useOrganisationUpdate";
import { ModalProps } from "../../interfaces/ModalProps";
import { CardHeader } from "../elements/card/CardHeader";
import { Icon } from "../elements/Icon";

type CreateOrganisationModalProps = ModalProps;
export function CreateOrganisationModal({
  isOpen,
  onClose,
}: CreateOrganisationModalProps) {
  const { setCurrentOrganisation } = useCurrentOrganisation();
  const { mutate: createOrganisation, isLoading } = useOrganisationUpdate();
  const { push } = useHistory();

  const toast = useToast();

  function handleOnSubmit(data: Record<string, string>, setErrors: any) {
    createOrganisation(data, {
      onSuccess: (data) => {
        if (data.id) {
          setCurrentOrganisation(data);
          push("/");
          onClose();

          toast({
            title: "Organisation created!",
            status: "success",
            isClosable: true,
          });
        }
      },
      onError: (errors) => setErrors(errors),
    });
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={undefined}
        motionPreset="slideInBottom"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="xl"
        isCentered={true}
      >
        <AlertDialogContent>
          <HStack p={6} pb={3}>
            <IconButton
              size="lg"
              color="black"
              onClick={() => onClose()}
              p={2}
              bg="transparent"
              icon={<Icon icon="arrow-left" />}
              aria-label="Open Menu"
            />
            <CardHeader title="Create an organisation" />
          </HStack>
          <AlertDialogBody pb={6}>
            <Form
              schema={ORGANISATION_FORM}
              dynamicProps={{
                submit: {
                  isLoading,
                },
              }}
              onSubmit={(data, setErrors) => {
                handleOnSubmit(data, setErrors);
              }}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
