import { Box, Center, Container, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { useTheme } from "../../hooks/useTheme";
import { LoginForm } from "../auth/LoginForm";
import { Logo } from "../Logo";

export function LoginPage() {
  const { isDarkMode } = useTheme();
  return (
    <>
      <Helmet>
        <title>Login - Notiversal</title>
      </Helmet>
      <Box
        backgroundImage="img/rose.svg"
        backgroundSize="cover"
        minHeight="100vh"
        minWidth="100vw"
        backgroundAttachment="fixed"
      >
        <Container maxW="container.sm">
          <Center minH="100vh" pt="2rem" pb="2rem ">
            <VStack w="100%">
              <Logo width={250} mb={12} />

              <LoginForm />
            </VStack>
          </Center>
        </Container>
      </Box>
    </>
  );
}
