import { IElementProps } from "react-templator";

import { Input } from "../elements/Input";

export function InputElement({
  ref,
  max,
  min,
  name,
  icon,
  value,
  error,
  label,
  autoComplete,
  helper,
  onChange,
  validate,
  disabled,
  required,
  inputType,
  isTextarea,
}: IElementProps) {
  return (
    <Input
      inputRef={ref}
      label={label}
      required={required}
      disabled={disabled}
      min={min}
      max={max}
      autoComplete={autoComplete}
      name={name}
      isTextarea={isTextarea}
      onBlur={() => validate(false)}
      type={inputType}
      icon={icon}
      value={value}
      error={error}
      onChange={(value: any) => onChange(value)}
      helperText={error || helper}
    />
  );
}
