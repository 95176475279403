import {
  AlertDialogBody,
  AlertDialogCloseButton,
  Box,
  VStack,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { first } from "lodash";
import { useState } from "react";
import { ProductEntity } from "service/src/products/entities/product.entity";

import { ModalProps } from "../../interfaces/ModalProps";
import {
  CodeExample,
  generateApiUrl,
  generateCurlCode,
  generateNodeCode,
  generatePythonCode,
  generateTestCode,
} from "../../utils/code-generation";
import { CardHeader } from "../elements/card/CardHeader";
import { Code } from "../elements/Code";
import { Input } from "../elements/Input";
import { Link } from "../elements/Link";
import { Text } from "../elements/Text";

interface ProductSetupModalProps extends ModalProps {
  product?: ProductEntity;
}

const CODE_EXAMPLES: CodeExample[] = [
  {
    label: "cURL",
    language: "bash",
    code: generateCurlCode,
  },
  {
    label: "Node",
    language: "javascript",
    code: generateNodeCode,
  },

  {
    label: "Python",
    language: "python",
    code: generatePythonCode,
  },
];

export function ProductSetupModal({
  isOpen,
  product,
  onClose,
}: ProductSetupModalProps) {
  const [activeExample, setActiveExample] = useState<CodeExample | undefined>();

  if (!product) return null;
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        scrollBehavior="inside"
        onClose={onClose}
        closeOnEsc={false}
        isOpen={isOpen}
        size="3xl"
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent overflowY="auto">
          <Box p={6} pb={3}>
            <VStack spacing={5}>
              <VStack spacing={2}>
                <CardHeader title={`${product?.label} setup`} />
                <Box>
                  <Text>
                    Simply send us a POST request with your event type and
                    content, and we'll take care of the rest!
                  </Text>
                </Box>
              </VStack>
              <VStack spacing={5} w="100%" alignItems="flex-start">
                <VStack spacing={2} alignItems="flex-start" w="100%">
                  <Text fontSize={15}>URL:</Text>
                  <Input
                    readOnly={true}
                    name="apiKey"
                    label="URL"
                    value={generateApiUrl()}
                  />
                </VStack>
                <VStack spacing={2} alignItems="flex-start" w="100%">
                  <Text fontSize={15}>API key:</Text>
                  <Input
                    readOnly={true}
                    name="apiKey"
                    label="API key"
                    value={product?.apiKey}
                  />
                </VStack>

                <VStack spacing={2} alignItems="flex-start" w="100%">
                  <Text fontSize={15}>Headers:</Text>

                  <Box w="100%">
                    <Code language="bash">
                      {`"Content-Type": "application/json",
"api-key": "${product.apiKey}"`}
                    </Code>
                  </Box>
                </VStack>

                <VStack
                  spacing={2}
                  pt={activeExample ? 5 : 2}
                  alignItems="flex-start"
                  w="100%"
                >
                  <HStack justifyContent="space-between" w="100%">
                    {!activeExample ? (
                      <Text fontSize={15}>Body:</Text>
                    ) : (
                      <HStack>
                        {CODE_EXAMPLES.map((example) => {
                          const isActive =
                            activeExample?.label === example.label;
                          return (
                            <Box
                              p="2px"
                              px={4}
                              fontSize={14}
                              cursor="pointer"
                              borderRadius={100}
                              py={1}
                              color={isActive ? "white" : undefined}
                              onClick={
                                isActive
                                  ? undefined
                                  : () => setActiveExample(example)
                              }
                              backgroundColor={
                                isActive ? "brand.200" : "gray.100"
                              }
                              transition="100ms background-color"
                              _hover={{
                                backgroundColor: isActive
                                  ? undefined
                                  : "gray.200",
                              }}
                            >
                              {example.label}
                            </Box>
                          );
                        })}
                      </HStack>
                    )}{" "}
                    <Link
                      cursor="pointer"
                      onClick={() => {
                        setActiveExample(
                          activeExample ? undefined : first(CODE_EXAMPLES)
                        );
                      }}
                    >
                      {activeExample ? "Hide" : "Show"} examples
                    </Link>
                  </HStack>
                  <Code
                    language={
                      activeExample ? activeExample.language : "javascript"
                    }
                    width="100%"
                    p={5}
                    display="block"
                    whiteSpace="pre"
                  >
                    {activeExample
                      ? activeExample.code({ apiKey: product.apiKey })
                      : generateTestCode()}
                  </Code>
                </VStack>
              </VStack>
            </VStack>
          </Box>
          <AlertDialogCloseButton />
          <AlertDialogBody pb={6} />
        </ModalContent>
      </Modal>
    </>
  );
}
