import { useQuery } from "react-query";

import { get } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";
import { Invoice } from "./useInvoice";

export type InvoicesResponse = PaginatedResponse<Invoice>;
export function useInvoices(page: number) {
  const { currentOrganisation } = useCurrentOrganisation();

  return useQuery<InvoicesResponse>(
    ["invoices", currentOrganisation?.id, page],
    async () => {
      const response = await get<InvoicesResponse>(
        `subscriptions/invoices/${currentOrganisation?.id}`,
        { page }
      );
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
}

export interface PaginatedResponseMeta {
  currentPage: 1;
  itemCount: number;
  totalItems: number;
  totalPages: number;
  itemsPerPage: number;
}

export interface PaginatedResponse<T> {
  items: T[];
  meta: PaginatedResponseMeta;
}
