import { useMutation, useQueryClient } from "react-query";

import { post } from "../services/api";

import { useCurrentOrganisation } from "./useCurrentOrganisation";

export function useInviteUser() {
  const { currentOrganisation } = useCurrentOrganisation();
  const queryClient = useQueryClient();

  return useMutation<boolean, null, Record<string, string>>(
    ["organisation/invite", currentOrganisation?.id],
    async (data) => {
      const response = await post(
        `organisations/${currentOrganisation?.id}/invite`,
        data
      );
      if (response.status !== 201)
        return Promise.reject("Could not invite user");

      return true;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "organisation",
          currentOrganisation?.id,
          "users",
        ]);
      },
    }
  );
}
