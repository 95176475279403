import { Box, BoxProps } from "@chakra-ui/react";
import RNAvatar from "boring-avatars";

import { useImagePreload } from "../../hooks/useImagePreload";
import { getImage } from "../../utils/image";
import { Logo } from "../Logo";

import { LoadingBackground } from "./LoadingBackground";

interface IAvatarProps extends BoxProps {
  id: string;
  isLoading?: boolean;
  size?: BoxProps["width"];
  isInternal?: boolean;
  image?: string;
  variant?: "beam" | "bauhaus" | "ring" | "marble";
}

export function Avatar({
  id,
  image,
  variant,
  isInternal,
  isLoading: isAvatarLoading,
  size = 10,
  ...props
}: IAvatarProps) {
  const url = image ? getImage(image) : undefined;
  const { isLoading: isImageLoading, isError } = useImagePreload([url]);

  const isLoading = isAvatarLoading || isImageLoading;

  return (
    <LoadingBackground
      w={size}
      h={size}
      borderRadius="full"
      isLoading={isLoading}
    >
      <Box width={size} height={size} {...props}>
        {isInternal ? (
          <Box
            width={size}
            height={size}
            borderRadius="full"
            backgroundColor="brand.200"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="2"
          >
            <Logo top="-3%" position="relative" />
          </Box>
        ) : (
          <>
            {image && !isError ? (
              <Box
                backgroundImage={url || "/img/logo-white.svg"}
                backgroundSize="cover"
                backgroundColor="white"
                width={size}
                height={size}
                boxShadow="sm"
                borderRadius={500}
              />
            ) : (
              <RNAvatar
                size="100%"
                name={id}
                variant={variant}
                colors={["#b20b8d", "#DE4D86", "#FFC759", "#8ED081", "#218380"]}
              />
            )}
          </>
        )}
      </Box>
    </LoadingBackground>
  );
}
