import { useQuery } from "react-query";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { get } from "../services/api";

import { useAuth } from "./useAuth";

export function useOrganisations() {
  const { isAuthenticated } = useAuth();
  return useQuery<OrganisationEntity[]>(
    "organisations",
    async () => {
      const response = await get<OrganisationEntity[]>(
        "users/organisations",
        {}
      );
      if (response.status !== 200) {
        return Promise.reject("Could not fetch organisations");
      }

      return response.data;
    },
    {
      enabled: !!isAuthenticated,
    }
  );
}
