import { ReactNode } from "react";
import { Redirect, Route as RNRoute } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

export function Route({
  component: Component,
  isRestricted,
  requiresPlan,
  ...props
}: {
  component: any;
  [data: string]: any;
}) {
  const { isAuthenticated } = useAuth();

  function renderAuthenticated(): ReactNode {
    if (isAuthenticated === false && isRestricted === true) {
      return <Redirect to="/login" />;
    }
    if (isAuthenticated === true && isRestricted === false) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  }

  return (
    <RNRoute
      {...props}
      render={() =>
        isRestricted !== undefined ? (
          renderAuthenticated()
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
