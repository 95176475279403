import { Box, HStack } from "@chakra-ui/react";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { useCurrentOrganisation } from "../../hooks/useCurrentOrganisation";
import { Avatar } from "../elements/Avatar";
import { Text } from "../elements/Text";

interface OrganisationSelectCardProps {
  organisation: OrganisationEntity;
  disabled?: boolean;
}

export function OrganisationSelectCard({
  organisation,
  disabled,
}: OrganisationSelectCardProps) {
  const { setCurrentOrganisation } = useCurrentOrganisation();

  return (
    <>
      <Box
        display="flex"
        p={2}
        borderRadius={5}
        onClick={() => setCurrentOrganisation(organisation)}
        cursor="pointer"
        pointerEvents={disabled ? "none" : undefined}
        _hover={{
          transform: "scale(0.98)",
          boxShadow: "0 2px 0px rgb(0 0 0 / 0.05)",
        }}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        backgroundColor="gray.100"
      >
        <HStack spacing={4}>
          <Avatar
            image={organisation.avatar}
            id={`${organisation.id}`}
            size={45}
            variant="marble"
          />
          <Text fontWeight={500} display="flex" fontSize={14}>
            {organisation.name}
          </Text>
        </HStack>
      </Box>
    </>
  );
}
