import { Box, useColorModeValue } from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { OrganisationEntity } from "service/src/organisations/entities/organisation.entity";

import { OrganisationContext, organisationContext } from "../appContext";
import { useAuth } from "../hooks/useAuth";
import { useOrganisations } from "../hooks/useOrganisations";

import { LoadingCover } from "./elements/LoadingCover";
import Footer from "./Footer";
import { Header } from "./layout/Header";
import { Routes } from "./layout/Routes";
import { SelectOrganisationModal } from "./modals/SelectOrganisationModal";

const PRELOAD_IMAGES = [
  "/img/rose.svg",
  "/img/rose-2.svg",
  "/img/loading.svg",
  "/img/logo.png",
  "/img/logo-white.png",
  "img/product-background.svg",
  "/img/.svg",
  "/img/services/slack.png",
];

export function Main() {
  const { isAuthenticated } = useAuth();
  const {
    data: organisations,
    isLoading: isLoadingOrganisations,
    isError: organisationError,
  } = useOrganisations();
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(true);

  const { pathname } = useLocation();
  const [currentOrganisation, setCurrentOrganisation] =
    useState<OrganisationEntity | null>(null);

  useEffect(() => {
    (async () => {
      await Promise.all(
        PRELOAD_IMAGES.map(
          (rnImage: string) =>
            new Promise<void>((res) => {
              const image = new Image();
              image.onload = () => res();
              image.onerror = () => res();

              image.src = rnImage;
            })
        )
      );

      setIsLoadingImages(false);
    })();
  }, []);

  const organisationContextValue: OrganisationContext = useMemo(
    () => ({
      currentOrganisation,
      setCurrentOrganisation: (organisation: OrganisationEntity | null) => {
        setCurrentOrganisation(organisation);
        if (!organisation) {
          localStorage.removeItem("lastOrganisation");
          return;
        }
        localStorage.setItem("lastOrganisation", organisation?.id);
      },
    }),
    [currentOrganisation]
  );

  useEffect(() => {
    if (currentOrganisation) return;

    const previousOrganisationId = localStorage.getItem("lastOrganisation");
    if (previousOrganisationId) {
      const previousOrganisation = organisations?.find(
        (organisation) => organisation.id === previousOrganisationId
      );
      if (previousOrganisation) setCurrentOrganisation(previousOrganisation);
    }
  }, [organisations, currentOrganisation]);
  const isLoading =
    isLoadingImages ||
    isAuthenticated === undefined ||
    (isLoadingOrganisations && !organisationError);

  const isJoinRoute = pathname.startsWith("/join/");

  return (
    <>
      <LoadingCover isLoading={isLoading} />

      <Box
        minH="100vh"
        maxH="100vh"
        w="100%"
        overflowY="scroll"
        overflowX="hidden"
        bg={useColorModeValue(isLoading ? "brand.200" : "gray.100", "dark.200")}
      >
        {isAuthenticated && !isJoinRoute ? (
          <>
            <organisationContext.Provider value={organisationContextValue}>
              <>
                {!isLoadingOrganisations && (
                  <SelectOrganisationModal
                    onClose={() => {}}
                    onOpen={() => {}}
                    isOpen={!currentOrganisation?.id}
                  />
                )}
                {currentOrganisation?.id ? (
                  <>
                    <Box minHeight="100vh">
                      <Header />
                      <Routes />
                    </Box>
                    <Footer />
                  </>
                ) : null}
              </>
            </organisationContext.Provider>
          </>
        ) : (
          <Routes />
        )}
      </Box>
    </>
  );
}
