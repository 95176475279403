import { Badge, IconButton, Link } from "@chakra-ui/react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";

import { Invoice } from "../../../hooks/useInvoice";
import { useInvoices } from "../../../hooks/useInvoices";
import { getInvoiceStatusColor } from "../../../utils/invoice";
import { Icon } from "../../elements/Icon";
import { Money } from "../../elements/Money";
import { NonIdealState } from "../../elements/NonIdealState";
import { Table } from "../../Table";

export function InvoicesPage() {
  const [page, setPage] = useState<number>(1);
  const { data: invoices, isLoading } = useInvoices(page);

  if (!isLoading && isEmpty(invoices?.items)) {
    return <NonIdealState mt=".5rem" label="No invoices found" />;
  }

  return (
    <>
      <Table<Invoice>
        keyExtractor={(invoice) => invoice.id}
        onPageChange={(newPage: number) => {
          setPage(newPage);
        }}
        columns={[
          {
            label: "ID",
            key: "id",
            renderColumn: (invoice) => (
              <Link to={`/billing/invoices/${invoice.id}`}>
                {invoice.stripeId}
              </Link>
            ),
          },

          {
            label: "Type",
            key: "type",
            renderColumn: (invoice) =>
              invoice.type === "subscription" ? "Subscription" : "Payment",
          },
          {
            label: "Total",
            key: "total",
            renderColumn: (invoice) => <Money>{invoice.total / 100}</Money>,
          },
          {
            label: "Status",
            key: "status",
            renderColumn: ({ status }) => (
              <Badge
                fontSize={12}
                py={1}
                px={4}
                borderRadius={40}
                colorScheme={getInvoiceStatusColor(status)}
              >
                {status}
              </Badge>
            ),
          },
          {
            label: "Created at",
            key: "created_at",
            renderColumn: (invoice) =>
              dayjs(invoice.createdAt).format("DD/MM/YYYY HH:mm"),
          },

          {
            label: "",
            key: "open_invoice",
            align: "right",
            renderColumn: (invoice) => (
              <a target="_blank" href={invoice.invoice_url} rel="noreferrer">
                <IconButton
                  opacity={invoice.invoice_url ? 1 : 0}
                  size="lg"
                  color="black"
                  bg="transparent"
                  icon={<Icon icon="arrow-right" />}
                  aria-label="Open Menu"
                />
              </a>
            ),
          },
        ]}
        data={invoices?.items}
        meta={invoices?.meta}
      />
    </>
  );
}
