import { useToast, Wrap, WrapItem } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Form } from "react-templator";

import { PRODUCT_FORM } from "../../../forms/auth/product-form";
import { PRODUCT_UPDATE_FORM } from "../../../forms/auth/product-update-form";
import { useOrganisationProduct } from "../../../hooks/useOrganisationProduct";
import { useProductUpdate } from "../../../hooks/useProductUpdate";
import { Card } from "../../elements/card/Card";
import { CardHeader } from "../../elements/card/CardHeader";

export enum CustomerLevel {
  user = "user",
  staff = "staff",
  admin = "admin",
}

export function ProductSettingsPage() {
  const { mutate: updateProduct, isLoading } = useProductUpdate();
  const toast = useToast();

  const { productId } = useParams<{ productId: string }>();
  const { data: product } = useOrganisationProduct(productId);

  function handleOnSubmit(data: Record<string, string>) {
    updateProduct(data, {
      onSuccess: () => {
        toast({
          title: "Product updated",
          status: "success",
          isClosable: true,
        });
      },
    });
  }

  if (!product) return null;

  return (
    <Wrap minChildWidth="120px" spacing="15px">
      <WrapItem>
        <Card pb={6} maxW="100%" w={600}>
          <CardHeader
            title="Settings"
            label={`Modify the settings for ${product.label}.`}
          />

          <Form
            defaultValues={product || undefined}
            schema={PRODUCT_UPDATE_FORM}
            dynamicProps={{
              newImage: {
                hasFile: !!product?.image,
              },
              submit: {
                isLoading,
              },
            }}
            onSubmit={handleOnSubmit}
          />
        </Card>
      </WrapItem>
    </Wrap>
  );
}
