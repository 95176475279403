import { AuthGuard } from "../AuthGuard";
import { Route } from "../elements/Route";
import { AcceptInvitePage } from "../pages/AcceptInvitePage";
import { ConfirmEmailPage } from "../pages/ConfirmEmailPage";
import { DashboardPage } from "../pages/DashboardPage";
import { LoginPage } from "../pages/LoginPage";
import { OrganisationPage } from "../pages/OrganisationPage";
import { ProductPage } from "../pages/products/ProductPage";
import { ProductsPage } from "../pages/ProductsPage";
import { RegisterPage } from "../pages/RegisterPage";
import { ServicesPage } from "../pages/ServicesPage";
import { UsersPage } from "../pages/UsersPage";
import { Role } from "../users/UserCard";

export function Routes() {
  return (
    <>
      <Route
        path="/"
        exact={true}
        component={DashboardPage}
        isRestricted={true}
      />
      <Route
        path="/login"
        exact={true}
        component={LoginPage}
        isRestricted={false}
      />
      <Route
        path="/register"
        exact={true}
        component={RegisterPage}
        isRestricted={false}
      />

      <Route
        path="/join/:inviteCode"
        exact={true}
        component={AcceptInvitePage}
      />

      <Route
        path="/confirm-email/:code"
        exact={true}
        component={ConfirmEmailPage}
      />

      <Route
        path="/products"
        exact={true}
        component={ProductsPage}
        isRestricted={true}
      />

      <Route
        path="/products/:productId"
        exact={true}
        component={ProductPage}
        isRestricted={true}
      />

      <Route
        path="/services"
        exact={true}
        component={ServicesPage}
        isRestricted={true}
      />

      <Route
        path="/users"
        exact={true}
        component={UsersPage}
        isRestricted={true}
      />
      <AuthGuard role={Role.Admin}>
        <Route
          path="/organisation"
          exact={true}
          component={OrganisationPage}
          isRestricted={true}
        />
      </AuthGuard>
    </>
  );
}
