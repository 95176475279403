import { Box, Link } from "@chakra-ui/react";
import { Form } from "react-templator";

import { ServiceType } from "../../../enums/service-type";
import { TWILIO_FORM } from "../../../forms/services/twilio-form";
import { useServiceUpdate } from "../../../hooks/useServiceUpdate";
import { Text } from "../../elements/Text";
import { ServiceConfigurationProps } from "../ServiceConfigurationModal";

export function TwilioConfiguration({
  onClose,
  data,
}: ServiceConfigurationProps) {
  const { mutate: updateService, isLoading } = useServiceUpdate();

  async function handleSubmit(data: Record<string, any>, setErrors: any) {
    if (!data.enableSms && !data.enableVoice) {
      return setErrors({
        accountSid: "You must enable at least SMS or voice",
      });
    }

    updateService(
      { type: ServiceType.twilio, data },
      {
        onSuccess: () => onClose(),
      }
    );
  }
  return (
    <>
      <Box mb={8} mt={-2}>
        <Text>
          Please input your account SID and auth token from Twilio. To get
          these, please follow{" "}
          <Link
            color="brand.200"
            target="_blank"
            href="https://support.twilio.com/hc/en-us/articles/223136027-Auth-Tokens-and-How-to-Change-Them"
          >
            this guide
          </Link>
          .
        </Text>
      </Box>
      <Form
        schema={TWILIO_FORM}
        defaultValues={data}
        dynamicProps={{
          submit: {
            isLoading,
          },
        }}
        onSubmit={handleSubmit}
      />
    </>
  );
}
